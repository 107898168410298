import React, {useContext} from "react";
import { DatasourceStateContext } from "../contexts/datasource";
import { Placeholder } from "@aws-amplify/ui-react";
import { CommonStateContext } from "../contexts/common";
import { useNavigate } from "react-router-dom";
import { ShareButton } from "./Buttons";


export const ReviewOrderButton = () => {
    // @ts-ignore
    const { messages } = useContext(CommonStateContext)
    // @ts-ignore
    const {isLoaded} = useContext(DatasourceStateContext);
    // @ts-ignore
    const navigate = useNavigate();

    const showSummary = () => {
        navigate('/confirm');
    }

    if (isLoaded) {
        // Disable submit if any ERROR notices
        let enabled = messages.filter((notice: any) => notice.severity === 'Failure').length > 0
        return (
            <ShareButton enabled={enabled} action={() => showSummary()} label={'Review and place order'}/>
        )
    } else {
        return (
            <Placeholder/>
        )
    }


}
