/**
 * This module contains several components related to the order details:
 *
 * 1. `OrderMeasureSelection`: This component allows users to select a measure from the provided data source.
 *    Once a measure is selected, it updates the order details accordingly.
 *
 * 2. `OrderYearsSelection`: Users can select academic years using this component. The selected years are
 *    subsequently updated in the order details.
 *
 * 3. `OrderDetailsForm`: This comprehensive form component facilitates users to provide or edit various order
 *    related details such as order reference, customer type, data use, population coverage, and data sharing preferences.
 *
 * All components are context-aware and interact with both the 'Cart' and 'Datasource' contexts to fetch and update data.
 */


import React, { useContext } from "react";
import { CartStateContext, CartDispatchContext, updateOrderDetails } from "../contexts/cart";
import {
    Placeholder,
    Card,
    Flex,
    Text,
    TextField,
    Radio,
    RadioGroupField,
    SwitchField,
    SelectField
} from '@aws-amplify/ui-react';
import { DatasourceStateContext } from "../contexts/datasource";


//
// Measure
//
export const OrderMeasureSelection = () => {
    // @ts-ignore
    const { datasource, isLoaded } = useContext(DatasourceStateContext)
    // @ts-ignore
    const { details } = useContext(CartStateContext)
    const dispatch = useContext(CartDispatchContext)
    const updateDetails = (orderDetails:any) => {
        updateOrderDetails(dispatch, orderDetails)
    }
    const selectMeasure = (event:any) => {
        updateDetails({
            ...details,
            measure: event.target.value
        })
    }

    if (isLoaded){
        return (
            <Card>
                <RadioGroupField
                    onChange={selectMeasure}
                    label="Measure"
                    name="measure"
                    value={details.measure}
                    size={"small"}>
                    { datasource.measures.map((option:string) => {
                        return (<Radio key={option} value={option}>{option}</Radio>)
                    })
                    }
                </RadioGroupField>
            </Card>
        )
    } else {
        return (
            <Placeholder/>
        )
    }
}

//
// Years
//
export const OrderYearsSelection = () => {
    // @ts-ignore
    const { datasource, isLoaded } = useContext(DatasourceStateContext)
    // @ts-ignore
    const { details } = useContext(CartStateContext)
    const dispatch = useContext(CartDispatchContext)
    const updateDetails = (orderDetails:any) => {
        updateOrderDetails(dispatch, orderDetails)
    }

    const selected = (year:string) => {
        return details.years.includes(year)
    }

    const selectYear = (event:any) => {
        let years =[...details.years]
        if (!years.includes(event.target.value)){
            years.push(event.target.value)
        } else {
            years = years.filter((obj: any) => obj !== event.target.value);
        }
        updateDetails({
            ...details,
            years: years
        })
    }

    if (isLoaded) {
        return (
            <Card width={"8rem"}>
                <Text fontSize={"small"}>Academic years</Text>
               <Flex direction={"column"} alignItems={"flex-start"} gap={"0.1rem"}>
                   {datasource.years.map((year:string) => {
                       return (
                       <SwitchField
                           onChange = {selectYear}
                           labelPosition={"end"}
                           defaultChecked={selected(year) || false}
                           checked = {selected(year) || false}
                           key={year}
                           value={year}
                           label={year}
                           size={"small"}
                       />
                       )
                   })
                   }
               </Flex>
            </Card>
        )
    } else {
        return (
            <Placeholder/>
        )
    }
}

export const OrderDetailsForm = () => {

    // @ts-ignore
    const { details } = useContext(CartStateContext)
    const dispatch = useContext(CartDispatchContext)
    const updateDetails = (orderDetails:any) => {
        updateOrderDetails(dispatch, orderDetails)
    }

    const updateOrderReference = (event:any) => {
        updateDetails({
            ...details,
            orderRef: event.target.value
        })
    }

    const updateCustomerReference = (event:any) => {
        updateDetails({
            ...details,
            customerRef: event.target.value,
        })
    }

    const updateItemNumber = (event:any) => {
        updateDetails({
            ...details,
            itemNo: event.target.value,
        })
    }

    const updateCustomerType = (event:any) => {
        updateDetails({
            ...details,
            customerType: event.target.value,
        })
    }

    const updateDataUse = (event:any) => {
        updateDetails({
            ...details,
            dataUse: event.target.value,
        })

    }

    const updatePopulationcoverageOther = (event:any) => {
        updateDetails({
            ...details,
            populationcoverageOther: event.target.value,
        })

    }

    const updatePopulationcoverageLevel = (event:any) => {
        updateDetails({
            ...details,
            populationcoverageLevel: event.target.value,
        })

    }

    const updateSharingrawData = (event:any) => {
        updateDetails({
            ...details,
            sharingrawData: event.target.value,
        })

    }


    return (
        <>
        <Card width={"20rem"}>
            <TextField onChange={updateOrderReference} label={"Order reference (Case Number)"} variation={"quiet"} size={"small"} value={details.orderRef}/>
            <TextField onChange={updateCustomerReference}  label={"Customer reference (Account Name)"} variation={"quiet"} size={"small"} value={details.customerRef}/>
            <TextField onChange={updateItemNumber}  label={"Item number"} variation={"quiet"} size={"small"} value={details.itemNo}/>
        </Card>
        <Card>
            <SelectField
                label={'Customer type'}
                size={"small"}
                value={details.customerType}
                onChange={updateCustomerType}
            >
                <option>Student</option>
                <option>Academic research</option>
                <option>Small HE Jisc member</option>
                <option>Medium HE Jisc member</option>
                <option>Large HE Jisc member</option>
                <option>Other Jisc member</option>
                <option>Statutory Customer</option>
                <option>Sector body</option>
                <option>Not for profit</option>
                <option>Commercial - Small</option>
                <option>Commercial - Medium</option>
                <option>Commercial - Large</option>
                <option>Commercial - Extra large</option>
            </SelectField>
            <SelectField
                label={'Data use'}
                size={"small"}
                value={details.dataUse}
                onChange={updateDataUse}
                options={["Internal","Publishing","Consultancy (1 customer)","Consultancy (2 customers)","Consultancy (3 customers)","Consultancy (4 customers)","Consultancy (5 customers)","Consultancy (6 customers)","Consultancy (7 customers)","Consultancy (8 customers)","Consultancy (9 customers)","Consultancy (10 customers)","Consultancy (11 customers)","Consultancy (12 customers)","Consultancy (13 customers)","Consultancy (14 customers)","Consultancy (15 customers)","Consultancy (16 customers)","Consultancy (17 customers)","Consultancy (18 customers)","Consultancy (19 customers)","Consultancy (20 customers)","Consultancy (21 customers)","Consultancy (22 customers)","Consultancy (23 customers)","Consultancy (24 customers)","Consultancy (25 customers)","Consultancy (26 customers)","Consultancy (27 customers)","Consultancy (28 customers)","Consultancy (29 customers)","Consultancy (30 customers)","Consultancy (31 customers)","Consultancy (32 customers)","Consultancy (33 customers)","Consultancy (34 customers)","Consultancy (35 customers)","Consultancy (36 customers)","Consultancy (37 customers)","Consultancy (38 customers)","Consultancy (39 customers)","Consultancy (40 customers)","Consultancy (41 customers)","Consultancy (42 customers)","Consultancy (43 customers)","Consultancy (44 customers)","Consultancy (45 customers)","Consultancy (46 customers)","Consultancy (47 customers)","Consultancy (48 customers)","Consultancy (49 customers)","Consultancy (50 customers)","Unlimited consultancy"]}
                />
            <SelectField
                label={'Population Coverage - Level'}
                size={"small"}
                value={details.populationcoverageLevel}
                onChange={updatePopulationcoverageLevel}
                options={["Complete",
                    "PG",
                    "UG",
                    "FD",
                    "PG research",
                    "PG taught",
                    "Masters",
                    "Doctorate"]}
                >

            </SelectField>
            <SelectField
                label={'Population Coverage - Other'}
                size={"small"}
                value={details.populationcoverageOther}
                onChange={updatePopulationcoverageOther}
                options={["Complete",
                    "1 subject",
                    "2 subjects",
                    "3 subjects",
                    "4 subjects",
                    "5 subjects",
                    "6 subjects",
                    "7 subjects",
                    "8 subjects",
                    "9 subjects",
                    "10 subjects",
                    "11 subjects",
                    "12 subjects",
                    "13 subjects",
                    "14 subjects",
                    "15 subjects",
                    "16 subjects",
                    "17 subjects",
                    "18 subjects",
                    "19 subjects",
                    "Part-time",
                    "Full-time"]}

            />

            <SelectField
                label={'Sharing raw data (for 3rd party gain)'}
                size={"small"}
                value={details.sharingrawData}
                onChange={updateSharingrawData}
                options={["No", "Yes"]}

            />
        </Card>
       </>
    )




}