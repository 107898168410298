import {Flex} from "@aws-amplify/ui-react";

// @ts-ignore
export const Main = ({children}) => {

    return (
        <Flex style={{"flexGrow": 1, "margin": "auto", "width": "80%", "padding": "20px 0 0 0"}}>
            {children}
        </Flex>
    );
}