import React, {useContext} from "react";
import { DatasourceStateContext } from "../contexts/datasource";
import { CartStateContext } from "../contexts/cart";
import { CommonStateContext } from "../contexts/common";
import {View, Card, Heading, Divider, Flex, Placeholder, Text} from '@aws-amplify/ui-react';
import { Order } from "../components/Order"
import { Search } from "../components/Search"
import { DataSource, FieldList } from "../components/Metadata";
import { RuleNotices } from "../components/RuleNotices"
import { OrderDetailsForm, OrderYearsSelection } from "../components/OrderDetails";
import { ReviewOrderButton } from "../components/ReviewOrderControl";
import { Header } from "../components/Header";
import { filteredFieldList } from "../helpers/fields";
import { OnwardUseControl } from "../components/OnwardUseControl";
import { SensitivityIndicator, OrderItemsTotalIndicator } from "../components/Indicators";
import {SaveOrderButton} from "../components/SaveOrderButton";
import {Main} from "../components/Main";

const CreateOrder = () => {
    // @ts-ignore
    const { datasource, isLoaded } = useContext(DatasourceStateContext);
    // @ts-ignore
    const { searchKeyword } = useContext(CommonStateContext);
    // @ts-ignore
    const { items, price } = useContext(CartStateContext)

    if (!isLoaded) {
        return (
            <Placeholder/>
        );
    }
    // The list of fields to show
    const fieldList = filteredFieldList(datasource, items, searchKeyword)

    return (
        <View>
            <Header/>
            <Main>
                <View width={"100%"}>
                    <DataSource datasource={datasource}/>
                    <Divider/>
                    <Flex direction={"row"} width={"100%"} justifyContent="space-between" wrap={"wrap"}>
                        <OrderYearsSelection/>
                        <OnwardUseControl/>
                        <OrderDetailsForm/>
                    </Flex>
                    <Divider/>
                    <Flex
                        direction={{ base: 'column', medium: 'row' }}
                        width="100%" alignItems={"stretch"} wrap={"wrap"} justifyContent={"space-evenly"}>
                        <View width={{ base: '100%', medium: '45%' }}>
                            <Card>
                                <Heading>Available fields</Heading>
                            </Card>
                            <Flex><Card width={"100%"}><Search/></Card></Flex>
                            <FieldList fieldList={fieldList}/>
                        </View>
                        <View width={{ base: '100%', medium: '45%' }}>

                            <Flex width={"100%"} justifyContent={"space-between"}>
                                <Card>
                                    <Heading>Your order</Heading>
                                </Card>
                                <Card>
                                    <Flex direction={"row"}>
                                        <OrderItemsTotalIndicator/>
                                        <SensitivityIndicator/>
                                        <Text>£ {price}</Text>
                                    </Flex>
                                </Card>
                            </Flex>
                            <RuleNotices/><p/>
                            <Order/><p/>
                            <SaveOrderButton/><p/>
                            <ReviewOrderButton/><p/>
                        </View>
                    </Flex>
                </View>
            </Main>
        </View>
    );
};

export default CreateOrder;