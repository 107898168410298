import React, {useContext} from "react";
import {Flex} from "@aws-amplify/ui-react";
import {Notice} from "./Notice";
import {CommonStateContext} from "../contexts/common";

export const RuleNotices = () => {
    // @ts-ignore
    const {messages} = useContext(CommonStateContext)

        const level = (severity: string) => {

            if (severity === 'Failure') {
                return 'error'
            }
            else if (severity === 'Info') {
                return 'info'
            }
            else {
                return 'warning'
            }

        }

        return (
            // @ts-ignore
            <Flex direction={"column"}>
                {messages &&

                    messages.map((notice: any) => {
                        return (
                            <React.Fragment key={notice.id}>
                                <Notice level={level(notice.severity)}>{notice.notice}</Notice>
                            </React.Fragment>
                        )
                    })}
            </Flex>
        )
    }





