import React, {useContext, useState} from "react";
import {
    Collection,
    View,
    Text,
    Flex,
    Button,
    Expander,
    ExpanderItem,
    SwitchField,
    Divider,
    Loader
} from '@aws-amplify/ui-react';
import useS3 from "../hooks/useS3";
import useLocalStorage from "../hooks/useLocalStorage";
import {MdRefresh} from "react-icons/md";
import {useProfile} from "../hooks/useProfile";
import {CartDispatchContext, replaceCart} from "../contexts/cart";
import {useNavigate} from "react-router-dom";
import useConfirm from "../hooks/useConfirm";
import {OrderItem} from "./OrderItem";
import {CopyButton, EditButton} from "./Buttons";


export const PreviousOrders = (props: any) => {

    const type = props['type'] || 'orderHistory'

    const [isChecked, setIsChecked] = useState(true);
    const [profile] = useProfile()
    const orderDispatchContext = useContext(CartDispatchContext)
    // @ts-ignore
    const navigate = useNavigate();
    const {confirm} = useConfirm();

    const [history, setHistory] = useLocalStorage(type, null)

    // @ts-ignore
    const [items, setItems] = useState(history)
    const {orders, loadPreviousOrders, isLoading} = useS3()
    const filter = (order: any, keyword: any) => order.orderRef.includes(keyword)

    React.useEffect(() => {
        if (orders) {
            setItems(orders)
            setHistory(orders);
        }
    }, [orders]);

    React.useEffect(() => {
        if ((isChecked && profile['mail']) || !isChecked) {
            loadPreviousOrders(!isChecked, type)
        }
    }, [isChecked])

    const sort = () => {
        // @ts-ignore
        return items.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            return new Date(b['jobCompleted']) - new Date(a['jobCompleted']);
        });
    }

    const handleRefresh = () => {
        loadPreviousOrders(!isChecked, type)
    };

    const handleRepeatOrder = (order: any) => {
        const newOrder = {
            details: {
                orderRef: order.orderRef + '_COPY',
                datasource: order.datasource,
                customerType: order.customerType,
                dataUse: order.dataUse,
                populationcoverageLevel: order.populationcoverageLevel,
                populationcoverageOther: order.populationcoverageOther,
                sharingrawData: order.sharingrawData,
                years: order.years,
                measure: order.measure
            },
            items: order.items
        }
        replaceCart(orderDispatchContext, newOrder)
        navigate('/order');
    }

    const handleOpenOrder = (order: any) => {
        order.details = {}
        order.details.orderRef = order.orderRef
        order.details.customerRef = order.customerRef
        order.details.customerType = order.customerType
        order.details.dataUse = order.dataUse
        order.details.populationcoverageLevel = order.populationcoverageLevel
        order.details.populationcoverageOther = order.populationcoverageOther
        order.details.sharingrawData = order.sharingrawData
        order.details.years = order.years
        order.details.onwardUseCategory = order.onwardUseCategory
        order.details.datasource = order.datasource
        order.details.measure = order.measure
        replaceCart(orderDispatchContext, order)
        navigate('/order');
    }

    const confirmOpenOrder = async (order: any) => {
        const isConfirmed = await confirm('Are you sure you want to open this order? Your current order will be replaced.');
        if (isConfirmed) {
            handleOpenOrder(order)
        }
    }

    const confirmRepeatOrder = async (order: any) => {
        const isConfirmed = await confirm('Are you sure you want to create a new order based on this one? Your current order will be replaced.');

        if (isConfirmed) {
            handleRepeatOrder(order)
        }
    }

    const orderTitle = (order: any) => {
        if ('jobCompleted' in order) {
            return new Date(Date.parse(order['jobCompleted'])).toDateString() + ' Ref: ' + order['orderRef']
        } else {
            return order['client'] + ' Ref: ' + order['orderRef']
        }
    }

    return (
        <View width={'100%'}>

            <Flex direction={'row'} justifyContent={'space-between'}>
                <Text>{props.title || "Previous orders"}</Text>
                <Button variation={"link"} size={'small'} onClick={handleRefresh} isDisabled={isLoading}>
                    <Flex>
                        <Text fontSize={"1.2em"}>
                            {
                                isLoading && <Loader size={'large'}/>
                            }
                            {
                                !isLoading && <MdRefresh/>
                            }
                        </Text>
                        <Text>Refresh</Text>
                    </Flex>
                </Button>
            </Flex>

            <Divider/>
            <SwitchField
                label="Show my orders only"
                labelPosition={"end"}
                isChecked={isChecked}
                onChange={(e) => {
                    setIsChecked(e.target.checked);
                }}
            />
            <p/>
            {history &&
            <Collection
                type={"list"}
                items={sort()}
                isPaginated
                itemsPerPage={5}
                isSearchable
                searchFilter={filter}
                searchPlaceholder="Type to filter..."
            >
                {(order) => (
                    <Expander key={
                        // @ts-ignore
                        order['orderRef']
                    } isCollapsible={true}>
                        <ExpanderItem
                            value={
                                // @ts-ignore
                                order['orderRef']
                            }
                            title={orderTitle(order)}
                        >
                            <OrderItem order={order}/>
                            {type === 'savedOrders' &&
                                <EditButton action={ () => confirmOpenOrder(order) } label="Open this request"/>
                            }
                            <p/>
                            <CopyButton action = { () => confirmRepeatOrder(order) } label = "Create a new order based on this one"/>
                        </ExpanderItem>
                    </Expander>
                )}
            </Collection>
            }
            <p/>
        </View>
    )
}