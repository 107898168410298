import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'rc-tooltip/assets/bootstrap_white.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

export const theme = {
    name: 'jisc-innov-theme',
    tokens: {
        colors: {
                background: {
                    warning: {value: 'gold'},
                    error: {value: 'orange'},
                },
                brand: {
                    secondary: {
                        10: { value: 'gold' },
                        20: { value: 'gold' },
                        30: { value: 'gold' },
                        40: { value: 'gold' },
                        50: { value: 'gold' },
                        60: { value: 'gold' },
                        70: { value: 'orange' },
                        80: { value: 'orange' },
                        90: { value: 'orange' },
                        100: { value: 'orange' },
                    },
                    primary: {
                        10: { value: '#E5D0E1' },
                        20: { value: '#C9A6C9' },
                        30: { value: '#A879AC' },
                        40: { value: '#A879AC' },
                        50: { value: '#8B4F91' },
                        60: { value: '#8B4F91' },
                        70: { value: '#6D2077' },
                        80: { value: '#6D2077' },
                        90: { value: '#6D2077' },
                        100: { value: '#6D2077' },
                    }
                }
            }
        },
};

ReactDOM.render(
    // @ts-ignore
    <AmplifyProvider theme={theme}>
        <App/>
    </AmplifyProvider>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
