import React, { useReducer, createContext } from "react";

export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

export const initialState = {
    show: false,
    text: ''
};

// @ts-ignore
export const ConfirmContext = createContext(initialState);

export const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case SHOW_CONFIRM:
            return {
                show: true,
                text: action.payload.text
            };
        case HIDE_CONFIRM:
            return initialState;
        default:
            return initialState;
    }
};

// @ts-ignore
export const ConfirmContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        // @ts-ignore
        <ConfirmContext.Provider value={[state, dispatch]}>
            {children}
        </ConfirmContext.Provider>
    );
};