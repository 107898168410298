/**
 * The ConstraintEditor component provides a UI for the user to apply filters on specific fields.
 * Filters can be applied by manually selecting values, using a search functionality, or by pasting
 * a string which can then be used to auto-select filter values.
 */

import React, {useState, useContext, useEffect} from "react";
import {
    Expander, ExpanderItem,
    Flex,
    Text,
    SwitchField, SearchField, TextAreaField, Button, Alert
} from '@aws-amplify/ui-react';
import {CartDispatchContext, addToCart} from "../contexts/cart";
import useConfirm from "../hooks/useConfirm";
import {AddButton} from "./Buttons";
import {hecosMapping, cah1Mapping, cah2Mapping, cah3Mapping, heouMapping, henoouMapping} from "../helpers/domainMapping";


// @ts-ignore
export const ConstraintEditor = ({field}) => {
    const {id, domain} = field
    const dispatch = useContext(CartDispatchContext)
    const {confirm} = useConfirm();
    const [warning, setWarning] = useState(""); // Add a warning state
    const [pastedValues, setPastedValues] = useState("");//Add a state var to hold the text pasted from the clipboard
    const [success, setSuccess] = useState("");
    const [info, setInfo] = useState<string | null>(null);


    type DomainMappingType = {
        [key: string]: string;
    };

    type MappingLookupType = {
        [key: string]: DomainMappingType;
    };

    const mappingLookup: MappingLookupType = {
        "Subject of study (CAH1)": cah1Mapping,
        "Subject of study (CAH2)": cah2Mapping,
        "Subject of study (CAH3)": cah3Mapping,
        "Subject of study (HECoS)": hecosMapping,
        "HE Provider (OU combined)": heouMapping,
        "HE Provider (OU split out)": henoouMapping
    };


    let initialState = {}
    for (const item of domain) {
        // @ts-ignore
        initialState[item] = false
    }

    const [allowedValues, setAllowedValues] = useState(initialState)
    const [search, setSearch] = useState('')
    const [allowedValuesSelected, setAllowedValuesSelected] = useState(false)

    /**
     * Whenever allowed values changes, set the 'is anything selected?' flag
     */
    const handleUpdate = () => {
        setAllowedValuesSelected(Object.values(allowedValues).some(Boolean))
    }

    useEffect(
        handleUpdate,
        [allowedValues]
    )

    /**
     * Handle user selecting a domain value
     * @param event
     */
    const handleChange = (event: any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        // @ts-ignore
        setAllowedValues({
                ...allowedValues,
                [event.target.name]: value
            }
        )
    }

    /**
     * Add the constraint to the order
     */
    const handleAddConstraint = () => {
        //
        // Reduce the allowedValues to a list
        //
        let values = []
        for (const item in allowedValues) {
            // @ts-ignore
            if (item === id) {
                // @ts-ignore
                values.push(allowedValues[item])
            } else {
                // @ts-ignore
                if (allowedValues[item] === true) {
                    values.push(item)
                }
            }
        }

        //
        // Create the order item
        //
        let constraint = {
            ...field,
            allowedValues: values
        }
        constraint.id = 'constraint__' + constraint.id

        //
        // Add the order item
        //
        addToCart(dispatch, constraint)
    }

    const confirmClose = async () => {
        const isConfirmed = await confirm('You selected one or more values but haven\'t applied a filter. Do you want to apply the filter now?');

        if (isConfirmed) {
            handleAddConstraint()
        }
    }
    const handleOnClose = (event:any) => {
        if (event === '' && allowedValuesSelected){
            confirmClose()
        }
    }

    const showSearch = () => {
        return domain && domain.length > 10
    }

    const filteredDomain = () => {
        return domain.filter((value:string) => { return !search || value.toLowerCase().indexOf(search) !== -1 || search === ''} )
    }

    // Paste functionality

    const handlePaste = (clipboardText: string) => {
        setPastedValues(clipboardText);
        const values = clipboardText.split(/[,\n]/).map(value => value.trim()).filter(value => value.length > 0);
        const newAllowedValues: { [key: string]: boolean } = { ...allowedValues };
        const unmatchedValues: string[] = [];

        const currentMapping = mappingLookup[field.fieldName] || {};  // Determine which mapping to use based on field's name

        if (values.length === 0) {
            setInfo("Please paste or type values into the box");
            setSuccess(""); // Clear any previous success message
            setWarning(""); // Clear any previous warning
            return;  // Exit the function early
        }

        values.forEach((value) => {
            let found = false;

            // Check if the value is a key in the currentMapping
            if (currentMapping.hasOwnProperty(value)) {
                const mappedValue = currentMapping[value];
                if (newAllowedValues.hasOwnProperty(mappedValue)) {
                    newAllowedValues[mappedValue] = true;
                    found = true;
                }
            }

            // Check if the exact string exists in newAllowedValues
            if (newAllowedValues.hasOwnProperty(value)) {
                newAllowedValues[value] = true;
                found = true;
            }

            // If neither is found, add to unmatchedValues
            if (!found) {
                unmatchedValues.push(value);
            }
        });

        setAllowedValues(newAllowedValues);

        // Handle warnings or success based on whether unmatched values were found
        const successfulMatches = values.length - unmatchedValues.length;

        if (unmatchedValues.length > 0) {
            setWarning(`Unmatched values found (${unmatchedValues.length} unmatched, ${successfulMatches} matched): ${unmatchedValues.join(", ")}`);
            setSuccess(""); // Clear the success message
            setInfo("");    // Clear the info message
        } else {
            setSuccess(`All values matched successfully! (${successfulMatches} matched)`);
            setWarning(""); // Clear the warning
            setInfo("");    // Clear the info message
        }
    };

    // Added this to control for which fields we want the textarea to be available for
    // Currently only the Subject fields

    const shouldAllowPaste = () => {
        const fieldsToAllow = ["Subject of study (CAH1)", "Subject of study (HECoS)", "Subject of study (CAH2)", "Subject of study (CAH3)", "HE Provider (OU combined)", "HE Provider (OU split out)"];
        return fieldsToAllow.includes(field.fieldName);
    };

    const handleClear = () => {
        setPastedValues("");
        setSuccess("");
        setWarning("");
        setInfo(null);
    };

    return (
        <Expander type="single" isCollapsible={true} onChange={handleOnClose}>
            <ExpanderItem title={"values"} value="constraint_editor">
                    <Flex direction={"column"} alignItems={"flex-start"} gap={"0.1rem"}>
                        <Text fontSize={"small"}>
                            To create a filter using this field, select one or more values to include, and then click
                            the <strong>Add filter to order</strong> button. Only data matching the
                            filter will be included in the order.
                        </Text>
                        {showSearch() &&
                            <SearchField label={"Search"} size={"small"} onSubmit={setSearch} onClear={() => setSearch('')}/>
                        }
                        {shouldAllowPaste() && (
                            <>
                        <TextAreaField
                            label="To create a filter based on a pasted string (either comma separated or new line) paste your string in here and
                            click 'Submit Pasted Values', it will auto-select the filters you require. You can then click on 'Add filter to order' You can copy and paste an Excel column of values for example."
                            value={pastedValues}
                            onChange={(e) => setPastedValues(e.target.value)} fontSize={"small"}
                        />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button size="small" onClick={() => handlePaste(pastedValues)}>
                                        Submit Pasted Values
                                    </Button>
                                    <Button size="small" style={{ backgroundColor: 'lightgray', color: 'black' }} onClick={handleClear}>
                                        Clear
                                    </Button>
                                </div>

                                {warning && <Alert fontSize={"small"} variation="warning">{warning}</Alert>}
                                {success && <Alert fontSize={"small"} variation="success">{success}</Alert>}
                                {info && <Alert fontSize={"small"} variation="info">{info}</Alert>}

                            </>

                        )}

                        {filteredDomain().map((value: string) => {
                            return (
                                <SwitchField
                                    key={value}
                                    name={value}
                                    size={"small"}
                                    label={value}
                                    onChange={handleChange}
                                    // @ts-ignore
                                    isChecked={allowedValues[value]}
                                    labelPosition={'end'}
                                />
                            )
                        })}
                    </Flex>
                <AddButton enabled={!allowedValuesSelected} action={() => handleAddConstraint()} label={'Add filter to order'} />



            </ExpanderItem>
        </Expander>
    )
}