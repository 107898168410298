export const dataSourceLabels = {
    "Student full-person equivalent (FPE)": "Student Record",
    "Student IVES full-person equivalent (FPE)": "Student Record (including IVES)",
    "Students and qualifiers full-person equivalent (FPE)": "Student and Qualifiers Record",
    "Students and qualifiers IVES full-person equivalent (FPE)": "Student and Qualifiers Record (including IVES)",
    "Qualifiers full-person equivalent (FPE)": "Qualifiers Record",
    "Qualifiers IVES full-person equivalent (FPE)": "Qualifiers Record (including IVES)",
    "Graduate Outcome full-person equivalent (FPE)": "Graduate Outcomes and Student Record"
};

export function getDataSourceLabel(dataSource: string | number): string {
    const key = String(dataSource); // Convert to string
    return (dataSourceLabels as Record<string, string>)[key] || key; // Fallback to dataSource value if no label is found
}