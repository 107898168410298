import React, { useContext } from "react";
import { CartDispatchContext, addToCart } from "../contexts/cart";
import { Card, View, Heading, Text, useTheme, Collection} from '@aws-amplify/ui-react';
import {ConstraintEditor} from "./Constraints";
import {CompactGenericFieldCard, GenericFieldCard} from "./FieldCard";
import {CommonStateContext} from "../contexts/common";
import {AddButtonSmall} from "./Buttons";
import {getDataSourceLabel} from '../helpers/dataSourceLabels';


/**
 * Displays the name and description of a data source
 * @param datasource
 * @constructor
 */
// @ts-ignore
export const DataSource = ({ datasource }) => {
    const { name, description } = datasource;
    const displayName = getDataSourceLabel(name); // Use function to get the label

    return (
        <Card>
            <Heading level={5}>{displayName}</Heading>
            <Text variation={"secondary"}>{description}</Text>
        </Card>
    );
};

/**
 * Displays a list of fields as a collection of Field Cards
 * @param fieldList
 * @constructor
 */
// @ts-ignore
export const FieldList = ({ fieldList }) => {
    const { tokens } = useTheme();
    // @ts-ignore
    const { compactView } = useContext(CommonStateContext);

    let padding:any;
    if (compactView){
        padding = "0px"
    } else {
        padding = tokens.space.medium
    }

    return (
        <View
            backgroundColor={ tokens.colors.neutral["20"] }
            padding={ tokens.space.medium }
        >
            <Collection
                type={"list"}
                items={fieldList}
                gap={padding}
            >
                {(field) => (
                    // @ts-ignore
                    <FieldCard key={field.id} field={field}/>
                )}
            </Collection>
        </View>
    )
}

/**
 * Display a card for a single field
 * @param field
 * @constructor
 */
// @ts-ignore
export const FieldCard = ({ field }) => {
    const dispatch = useContext(CartDispatchContext);
    // @ts-ignore
    const { compactView } = useContext(CommonStateContext);

    let FieldCard = GenericFieldCard
    if (compactView){
        FieldCard = CompactGenericFieldCard
    }

    const handleAddToCart = () => {
        const product = { ...field, quantity: 1 };
        addToCart(dispatch, product);
    };

    return (
        <View>
            <FieldCard field={field}>
                <AddButtonSmall action={() => handleAddToCart()}/>
            </FieldCard>
            {!compactView && field.domain && field.domain.length > 0 &&
                <ConstraintEditor field={field}/>
            }
        </View>
    )
}