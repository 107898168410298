import React from 'react';
import { createPortal } from 'react-dom';
import useConfirm from '../hooks/useConfirm';
import { Flex, Button, Card } from "@aws-amplify/ui-react";

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    const portalElement = document.getElementById('portal');
    const component = confirmState.show ? (
        <div className="portal-overlay">
            <Card className="confirm-dialog" variation={"elevated"}>
                <p>{confirmState?.text && confirmState.text}</p>
                <Flex justifyContent={"center"}>
                    <Button variation="primary" onClick={onConfirm}>
                        Yes
                    </Button>
                    <Button onClick={onCancel}>
                        No
                    </Button>
                </Flex>
            </Card>
        </div>
    ) : null;

    // @ts-ignore
    return createPortal(component, portalElement);
};
export default ConfirmDialog;