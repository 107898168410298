import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './pages/createOrder';
import OrderPage from './pages/confirmOrder';
import StartPage from './pages/start';
import CommonProvider from "./contexts/common";
import ProductsProvider from "./contexts/datasource";
import CartProvider from "./contexts/cart";
import { useIsSignedIn } from './hooks/useIsSignedIn'
import { Alert, Flex, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Header } from "./components/Header";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import {Providers} from '@microsoft/mgt-react'
import {Msal2Provider} from "@microsoft/mgt-msal2-provider";
import {ConfirmContextProvider} from "./contexts/confirm";
import ConfirmDialog from "./components/ConfirmDialog";

/**
 * AWS Amplify setup for calling APIs
 */

Amplify.configure(awsconfig);

/**
 * Azure Active Directory Setup for SSO
 */
Providers.globalProvider = new Msal2Provider({
    clientId: '74ff3b2e-fbe0-441f-af67-e68a5e89dfa8',
    authority: 'https://login.microsoftonline.com/48f9394d-8a14-4d27-82a6-f35f12361205',
    scopes: ["74ff3b2e-fbe0-441f-af67-e68a5e89dfa8/.default", "email", "profile"]
})

function App() {
    const [isSignedIn] = useIsSignedIn();
    const {tokens} = useTheme();

    return (
        <CommonProvider>
            <ConfirmContextProvider>
                <CartProvider>
                    <ProductsProvider>
                        <ConfirmDialog/>
                        <Router>
                            { isSignedIn &&
                                <Routes>
                                    <Route path="/" element={<StartPage/>}/>
                                    <Route path="/order" element={<HomePage/>}/>
                                    <Route path="/confirm" element={<OrderPage/>}/>
                                </Routes>
                            }
                            { !isSignedIn &&
                                <Flex
                                    minHeight={"100vh"}
                                    direction={"column"}
                                    gap={"0em"}
                                >
                                    <Header/>
                                    <Flex style={{"flexGrow": 1}}>
                                        <Flex direction={"column"} gap={tokens.space.small} width={'100%'}>
                                            <Alert>This app is a work in progress by the DA Innovation team,
                                                and as such features and layouts may change unexpectedly and without warning.
                                                Saved data may also not be preserved.
                                            </Alert>
                                            <Alert>You need to sign in to continue using this app</Alert>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            }
                        </Router>
                    </ProductsProvider>
                </CartProvider>
            </ConfirmContextProvider>
        </CommonProvider>
    );
}

export default App;
