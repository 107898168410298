// This is the domain mapping for the subject field to allow
// a user to type just the code, or the full string
// If there are changes to the HECOS and CAH codes this will need updating
// There is Python code in the metadata library to generate these below so
// could be connected up to update alongside the data potentially

type DomainMappingType = {
    [key: string]: string;
};
export const hecosMapping: DomainMappingType = {
    "(100003) ceramics": "(100003) ceramics",
    "(100005) clinical engineering": "(100005) clinical engineering",
    "(100033) metallurgy": "(100033) metallurgy",
    "(100035) musical theatre": "(100035) musical theatre",
    "(100036) optometry": "(100036) optometry",
    "(100037) orthoptics": "(100037) orthoptics",
    "(100038) pathobiology": "(100038) pathobiology",
    "(100046) creative writing": "(100046) creative writing",
    "(100048) design": "(100048) design",
    "(100050) product design": "(100050) product design",
    "(100051) textile design": "(100051) textile design",
    "(100052) ergonomics": "(100052) ergonomics",
    "(100054) fashion": "(100054) fashion",
    "(100055) fashion design": "(100055) fashion design",
    "(100057) animation": "(100057) animation",
    "(100058) film studies": "(100058) film studies",
    "(100059) fine art": "(100059) fine art",
    "(100060) graphic arts": "(100060) graphic arts",
    "(100061) graphic design": "(100061) graphic design",
    "(100062) illustration": "(100062) illustration",
    "(100063) photography": "(100063) photography",
    "(100065) liberal arts": "(100065) liberal arts",
    "(100067) acting": "(100067) acting",
    "(100068) dance": "(100068) dance",
    "(100069) drama": "(100069) drama",
    "(100070) music": "(100070) music",
    "(100071) performing arts": "(100071) performing arts",
    "(100074) advertising": "(100074) advertising",
    "(100075) marketing": "(100075) marketing",
    "(100076) public relations": "(100076) public relations",
    "(100078) business and management": "(100078) business and management",
    "(100079) business studies": "(100079) business studies",
    "(100080) international business": "(100080) international business",
    "(100083) event management": "(100083) event management",
    "(100084) hospitality management": "(100084) hospitality management",
    "(100085) human resource management": "(100085) human resource management",
    "(100087) international hospitality management": "(100087) international hospitality management",
    "(100088) leadership": "(100088) leadership",
    "(100089) management studies": "(100089) management studies",
    "(100090) public administration": "(100090) public administration",
    "(100091) public services": "(100091) public services",
    "(100092) retail management": "(100092) retail management",
    "(100093) logistics": "(100093) logistics",
    "(100095) sports coaching": "(100095) sports coaching",
    "(100096) sports development": "(100096) sports development",
    "(100097) sports management": "(100097) sports management",
    "(100098) sports studies": "(100098) sports studies",
    "(100100) tourism management": "(100100) tourism management",
    "(100101) travel and tourism": "(100101) travel and tourism",
    "(100102) travel management": "(100102) travel management",
    "(100104) accountancy": "(100104) accountancy",
    "(100105) accounting": "(100105) accounting",
    "(100106) actuarial science": "(100106) actuarial science",
    "(100107) finance": "(100107) finance",
    "(100109) clothing production": "(100109) clothing production",
    "(100110) footwear production": "(100110) footwear production",
    "(100114) aeronautical engineering": "(100114) aeronautical engineering",
    "(100115) aerospace engineering": "(100115) aerospace engineering",
    "(100116) space technology": "(100116) space technology",
    "(100117) avionics": "(100117) avionics",
    "(100118) satellite engineering": "(100118) satellite engineering",
    "(100120) architectural engineering": "(100120) architectural engineering",
    "(100121) architectural technology": "(100121) architectural technology",
    "(100122) architecture": "(100122) architecture",
    "(100124) landscape architecture and design": "(100124) landscape architecture and design",
    "(100126) biomechanics": "(100126) biomechanics",
    "(100127) biomedical engineering": "(100127) biomedical engineering",
    "(100128) dental technology": "(100128) dental technology",
    "(100129) diagnostic imaging": "(100129) diagnostic imaging",
    "(100130) prosthetics and orthotics": "(100130) prosthetics and orthotics",
    "(100131) radiology": "(100131) radiology",
    "(100132) therapeutic imaging": "(100132) therapeutic imaging",
    "(100134) biotechnology": "(100134) biotechnology",
    "(100135) bioprocessing": "(100135) bioprocessing",
    "(100136) environmental biotechnology": "(100136) environmental biotechnology",
    "(100137) industrial biotechnology": "(100137) industrial biotechnology",
    "(100138) medical biotechnology": "(100138) medical biotechnology",
    "(100139) plant biotechnology": "(100139) plant biotechnology",
    "(100141) biochemical engineering": "(100141) biochemical engineering",
    "(100143) chemical engineering": "(100143) chemical engineering",
    "(100144) pharmaceutical engineering": "(100144) pharmaceutical engineering",
    "(100145) polymer science and technology": "(100145) polymer science and technology",
    "(100147) building services engineering": "(100147) building services engineering",
    "(100148) civil engineering": "(100148) civil engineering",
    "(100149) construction": "(100149) construction",
    "(100150) construction and the built environment": "(100150) construction and the built environment",
    "(100151) construction management": "(100151) construction management",
    "(100152) offshore engineering": "(100152) offshore engineering",
    "(100153) structural engineering": "(100153) structural engineering",
    "(100154) transport engineering": "(100154) transport engineering",
    "(100155) minerals technology": "(100155) minerals technology",
    "(100156) highways engineering": "(100156) highways engineering",
    "(100157) railway engineering": "(100157) railway engineering",
    "(100159) telecommunications engineering": "(100159) telecommunications engineering",
    "(100160) computer aided engineering": "(100160) computer aided engineering",
    "(100162) computer systems engineering": "(100162) computer systems engineering",
    "(100163) electrical and electronic engineering": "(100163) electrical and electronic engineering",
    "(100164) electrical engineering": "(100164) electrical engineering",
    "(100165) electronic engineering": "(100165) electronic engineering",
    "(100166) control systems": "(100166) control systems",
    "(100168) microelectronic engineering": "(100168) microelectronic engineering",
    "(100169) optoelectronic engineering": "(100169) optoelectronic engineering",
    "(100170) mechatronics and robotics": "(100170) mechatronics and robotics",
    "(100172) nuclear engineering": "(100172) nuclear engineering",
    "(100175) energy engineering": "(100175) energy engineering",
    "(100176) gas engineering": "(100176) gas engineering",
    "(100177) microwave engineering": "(100177) microwave engineering",
    "(100178) petroleum engineering": "(100178) petroleum engineering",
    "(100180) environmental engineering": "(100180) environmental engineering",
    "(100182) engineering design": "(100182) engineering design",
    "(100183) fire safety engineering": "(100183) fire safety engineering",
    "(100184) general or integrated engineering": "(100184) general or integrated engineering",
    "(100185) safety engineering": "(100185) safety engineering",
    "(100186) emergency and disaster technologies": "(100186) emergency and disaster technologies",
    "(100188) systems engineering": "(100188) systems engineering",
    "(100190) mechanical engineering": "(100190) mechanical engineering",
    "(100192) electromechanical engineering": "(100192) electromechanical engineering",
    "(100193) maintenance engineering": "(100193) maintenance engineering",
    "(100194) marine technology": "(100194) marine technology",
    "(100196) housing": "(100196) housing",
    "(100197) planning": "(100197) planning",
    "(100198) transport planning": "(100198) transport planning",
    "(100199) urban and regional planning": "(100199) urban and regional planning",
    "(100201) automotive engineering": "(100201) automotive engineering",
    "(100202) manufacturing engineering": "(100202) manufacturing engineering",
    "(100203) materials engineering": "(100203) materials engineering",
    "(100204) mining engineering": "(100204) mining engineering",
    "(100205) motorcycle engineering": "(100205) motorcycle engineering",
    "(100206) motorsport engineering": "(100206) motorsport engineering",
    "(100207) naval architecture": "(100207) naval architecture",
    "(100209) production and manufacturing engineering": "(100209) production and manufacturing engineering",
    "(100210) leather technology": "(100210) leather technology",
    "(100211) fabrication": "(100211) fabrication",
    "(100213) quality management": "(100213) quality management",
    "(100214) textiles technology": "(100214) textiles technology",
    "(100216) building surveying": "(100216) building surveying",
    "(100217) quantity surveying": "(100217) quantity surveying",
    "(100218) real estate": "(100218) real estate",
    "(100219) surveying": "(100219) surveying",
    "(100221) music technology": "(100221) music technology",
    "(100222) audio technology": "(100222) audio technology",
    "(100223) music production": "(100223) music production",
    "(100225) materials science": "(100225) materials science",
    "(100229) aviation studies": "(100229) aviation studies",
    "(100230) navigation": "(100230) navigation",
    "(100233) acupuncture": "(100233) acupuncture",
    "(100234) alternative medicines and therapies": "(100234) alternative medicines and therapies",
    "(100235) aromatherapy": "(100235) aromatherapy",
    "(100236) Chinese medical techniques": "(100236) Chinese medical techniques",
    "(100237) herbal medicine": "(100237) herbal medicine",
    "(100239) reflexology": "(100239) reflexology",
    "(100241) chiropractic": "(100241) chiropractic",
    "(100242) complementary medicines and therapies": "(100242) complementary medicines and therapies",
    "(100243) osteopathy": "(100243) osteopathy",
    "(100246) health sciences": "(100246) health sciences",
    "(100247) nutrition": "(100247) nutrition",
    "(100248) occupational health": "(100248) occupational health",
    "(100249) occupational therapy": "(100249) occupational therapy",
    "(100250) pharmacology": "(100250) pharmacology",
    "(100251) pharmacy": "(100251) pharmacy",
    "(100252) physiotherapy": "(100252) physiotherapy",
    "(100253) podiatry": "(100253) podiatry",
    "(100254) psychotherapy": "(100254) psychotherapy",
    "(100255) speech and language therapy": "(100255) speech and language therapy",
    "(100257) audiology": "(100257) audiology",
    "(100258) clinical physiology": "(100258) clinical physiology",
    "(100259) genetics": "(100259) genetics",
    "(100260) healthcare science": "(100260) healthcare science",
    "(100261) ophthalmology": "(100261) ophthalmology",
    "(100262) physiology": "(100262) physiology",
    "(100264) anatomy": "(100264) anatomy",
    "(100265) biomedical sciences": "(100265) biomedical sciences",
    "(100266) clinical dentistry": "(100266) clinical dentistry",
    "(100267) clinical medicine": "(100267) clinical medicine",
    "(100268) dentistry": "(100268) dentistry",
    "(100270) medical sciences": "(100270) medical sciences",
    "(100271) medicine": "(100271) medicine",
    "(100272) neuroscience": "(100272) neuroscience",
    "(100273) operating department practice": "(100273) operating department practice",
    "(100274) pathology": "(100274) pathology",
    "(100275) pre-clinical dentistry": "(100275) pre-clinical dentistry",
    "(100276) pre-clinical medicine": "(100276) pre-clinical medicine",
    "(100277) toxicology": "(100277) toxicology",
    "(100279) adult nursing": "(100279) adult nursing",
    "(100280) children's nursing": "(100280) children's nursing",
    "(100281) community nursing": "(100281) community nursing",
    "(100282) critical care nursing": "(100282) critical care nursing",
    "(100283) dental nursing": "(100283) dental nursing",
    "(100284) emergency nursing": "(100284) emergency nursing",
    "(100285) general practice nursing": "(100285) general practice nursing",
    "(100286) learning disabilities nursing": "(100286) learning disabilities nursing",
    "(100287) mental health nursing": "(100287) mental health nursing",
    "(100288) midwifery": "(100288) midwifery",
    "(100289) neonatal nursing": "(100289) neonatal nursing",
    "(100290) nursing": "(100290) nursing",
    "(100291) older people nursing": "(100291) older people nursing",
    "(100292) palliative care nursing": "(100292) palliative care nursing",
    "(100293) school nursing": "(100293) school nursing",
    "(100294) theatre nursing": "(100294) theatre nursing",
    "(100295) health visiting": "(100295) health visiting",
    "(100298) ancient history": "(100298) ancient history",
    "(100299) archaeology": "(100299) archaeology",
    "(100300) classical studies": "(100300) classical studies",
    "(100301) economic history": "(100301) economic history",
    "(100302) history": "(100302) history",
    "(100306) history of art": "(100306) history of art",
    "(100307) history of science": "(100307) history of science",
    "(100308) local history": "(100308) local history",
    "(100309) medieval history": "(100309) medieval history",
    "(100310) modern history": "(100310) modern history",
    "(100311) Scottish history": "(100311) Scottish history",
    "(100312) social history": "(100312) social history",
    "(100314) humanities": "(100314) humanities",
    "(100316) American studies": "(100316) American studies",
    "(100317) British Sign Language studies": "(100317) British Sign Language studies",
    "(100318) English language": "(100318) English language",
    "(100319) English literature": "(100319) English literature",
    "(100320) English studies": "(100320) English studies",
    "(100321) French language": "(100321) French language",
    "(100322) French studies": "(100322) French studies",
    "(100323) German language": "(100323) German language",
    "(100324) German studies": "(100324) German studies",
    "(100325) Spanish studies": "(100325) Spanish studies",
    "(100326) Italian language": "(100326) Italian language",
    "(100327) Italian studies": "(100327) Italian studies",
    "(100328) linguistics": "(100328) linguistics",
    "(100329) modern languages": "(100329) modern languages",
    "(100330) Russian languages": "(100330) Russian languages",
    "(100331) Russian and East European studies": "(100331) Russian and East European studies",
    "(100332) Spanish language": "(100332) Spanish language",
    "(100333) Welsh language": "(100333) Welsh language",
    "(100335) Welsh studies": "(100335) Welsh studies",
    "(100337) philosophy": "(100337) philosophy",
    "(100338) philosophy of science": "(100338) philosophy of science",
    "(100339) religious studies": "(100339) religious studies",
    "(100340) theology": "(100340) theology",
    "(100343) applied biology": "(100343) applied biology",
    "(100344) biochemistry": "(100344) biochemistry",
    "(100345) biological sciences": "(100345) biological sciences",
    "(100346) biology": "(100346) biology",
    "(100347) ecology": "(100347) ecology",
    "(100348) environmental biology": "(100348) environmental biology",
    "(100350) human biology": "(100350) human biology",
    "(100351) marine biology": "(100351) marine biology",
    "(100352) medical biochemistry": "(100352) medical biochemistry",
    "(100353) microbiology": "(100353) microbiology",
    "(100354) molecular biology": "(100354) molecular biology",
    "(100355) plant sciences": "(100355) plant sciences",
    "(100356) zoology": "(100356) zoology",
    "(100358) applied computing": "(100358) applied computing",
    "(100359) artificial intelligence": "(100359) artificial intelligence",
    "(100360) business computing": "(100360) business computing",
    "(100361) business information systems": "(100361) business information systems",
    "(100362) business information technology": "(100362) business information technology",
    "(100363) computer animation and visual effects": "(100363) computer animation and visual effects",
    "(100365) computer networks": "(100365) computer networks",
    "(100366) computer science": "(100366) computer science",
    "(100367) computing and information technology": "(100367) computing and information technology",
    "(100368) creative computing": "(100368) creative computing",
    "(100369) geographical information systems": "(100369) geographical information systems",
    "(100370) information management": "(100370) information management",
    "(100371) information systems": "(100371) information systems",
    "(100372) information technology": "(100372) information technology",
    "(100373) internet technologies": "(100373) internet technologies",
    "(100374) software engineering": "(100374) software engineering",
    "(100375) web and multimedia design": "(100375) web and multimedia design",
    "(100376) computer and information security": "(100376) computer and information security",
    "(100379) climate science": "(100379) climate science",
    "(100380) environmental geoscience": "(100380) environmental geoscience",
    "(100381) environmental sciences": "(100381) environmental sciences",
    "(100382) meteorology": "(100382) meteorology",
    "(100384) archaeological sciences": "(100384) archaeological sciences",
    "(100385) computer forensics": "(100385) computer forensics",
    "(100386) forensic biology": "(100386) forensic biology",
    "(100387) forensic psychology": "(100387) forensic psychology",
    "(100388) forensic science": "(100388) forensic science",
    "(100390) general science": "(100390) general science",
    "(100391) natural sciences": "(100391) natural sciences",
    "(100392) applied science": "(100392) applied science",
    "(100394) earth sciences": "(100394) earth sciences",
    "(100395) geology": "(100395) geology",
    "(100396) geophysics": "(100396) geophysics",
    "(100398) palaeontology": "(100398) palaeontology",
    "(100400) applied mathematics": "(100400) applied mathematics",
    "(100401) financial mathematics": "(100401) financial mathematics",
    "(100402) mathematical modelling": "(100402) mathematical modelling",
    "(100403) mathematics": "(100403) mathematics",
    "(100404) operational research": "(100404) operational research",
    "(100405) pure mathematics": "(100405) pure mathematics",
    "(100406) statistics": "(100406) statistics",
    "(100408) environmental geography": "(100408) environmental geography",
    "(100409) geography": "(100409) geography",
    "(100410) physical geography": "(100410) physical geography",
    "(100413) analytical chemistry": "(100413) analytical chemistry",
    "(100414) astronomy": "(100414) astronomy",
    "(100415) astrophysics": "(100415) astrophysics",
    "(100416) chemical physics": "(100416) chemical physics",
    "(100417) chemistry": "(100417) chemistry",
    "(100418) marine sciences": "(100418) marine sciences",
    "(100419) medical physics": "(100419) medical physics",
    "(100420) medicinal chemistry": "(100420) medicinal chemistry",
    "(100421) ocean sciences": "(100421) ocean sciences",
    "(100422) organic chemistry": "(100422) organic chemistry",
    "(100423) pharmaceutical chemistry": "(100423) pharmaceutical chemistry",
    "(100424) physical sciences": "(100424) physical sciences",
    "(100425) physics": "(100425) physics",
    "(100426) theoretical physics": "(100426) theoretical physics",
    "(100427) acoustics": "(100427) acoustics",
    "(100428) aerodynamics": "(100428) aerodynamics",
    "(100429) dynamics": "(100429) dynamics",
    "(100430) mechanics": "(100430) mechanics",
    "(100431) thermodynamics": "(100431) thermodynamics",
    "(100433) sport and exercise sciences": "(100433) sport and exercise sciences",
    "(100436) anthropology": "(100436) anthropology",
    "(100437) social anthropology": "(100437) social anthropology",
    "(100439) broadcast journalism": "(100439) broadcast journalism",
    "(100440) digital media": "(100440) digital media",
    "(100441) film production": "(100441) film production",
    "(100442) journalism": "(100442) journalism",
    "(100443) media production": "(100443) media production",
    "(100444) media and communication studies": "(100444) media and communication studies",
    "(100445) multimedia journalism": "(100445) multimedia journalism",
    "(100449) business economics": "(100449) business economics",
    "(100450) economics": "(100450) economics",
    "(100451) financial economics": "(100451) financial economics",
    "(100452) international economics": "(100452) international economics",
    "(100454) adult education": "(100454) adult education",
    "(100455) childhood and youth studies": "(100455) childhood and youth studies",
    "(100456) childhood studies": "(100456) childhood studies",
    "(100457) early childhood studies": "(100457) early childhood studies",
    "(100459) education studies": "(100459) education studies",
    "(100460) further education": "(100460) further education",
    "(100461) higher education": "(100461) higher education",
    "(100462) learning support": "(100462) learning support",
    "(100463) early years education": "(100463) early years education",
    "(100464) primary education": "(100464) primary education",
    "(100465) secondary education": "(100465) secondary education",
    "(100466) youth and community work": "(100466) youth and community work",
    "(100468) countryside management": "(100468) countryside management",
    "(100469) environmental management": "(100469) environmental management",
    "(100471) social sciences": "(100471) social sciences",
    "(100473) health studies": "(100473) health studies",
    "(100475) sports therapy": "(100475) sports therapy",
    "(100476) health and social care": "(100476) health and social care",
    "(100478) human geography": "(100478) human geography",
    "(100482) business law": "(100482) business law",
    "(100483) criminal justice": "(100483) criminal justice",
    "(100484) criminology": "(100484) criminology",
    "(100485) law": "(100485) law",
    "(100486) policing": "(100486) policing",
    "(100488) international development": "(100488) international development",
    "(100489) international politics": "(100489) international politics",
    "(100490) international relations": "(100490) international relations",
    "(100491) politics": "(100491) politics",
    "(100493) applied psychology": "(100493) applied psychology",
    "(100494) clinical psychology": "(100494) clinical psychology",
    "(100495) counselling": "(100495) counselling",
    "(100496) educational psychology": "(100496) educational psychology",
    "(100497) psychology": "(100497) psychology",
    "(100498) social psychology": "(100498) social psychology",
    "(100499) sport and exercise psychology": "(100499) sport and exercise psychology",
    "(100501) social care": "(100501) social care",
    "(100502) social policy": "(100502) social policy",
    "(100503) social work": "(100503) social work",
    "(100505) sociology": "(100505) sociology",
    "(100507) adult education teaching": "(100507) adult education teaching",
    "(100508) post compulsory education and training": "(100508) post compulsory education and training",
    "(100509) higher education teaching": "(100509) higher education teaching",
    "(100510) early years teaching": "(100510) early years teaching",
    "(100511) primary teaching": "(100511) primary teaching",
    "(100512) secondary teaching": "(100512) secondary teaching",
    "(100513) teaching English as a foreign language": "(100513) teaching English as a foreign language",
    "(100516) agricultural sciences": "(100516) agricultural sciences",
    "(100517) agriculture": "(100517) agriculture",
    "(100518) animal management": "(100518) animal management",
    "(100519) equine studies": "(100519) equine studies",
    "(100520) forestry and arboriculture": "(100520) forestry and arboriculture",
    "(100522) animal behaviour": "(100522) animal behaviour",
    "(100523) animal science": "(100523) animal science",
    "(100526) food and beverage production": "(100526) food and beverage production",
    "(100527) food science": "(100527) food science",
    "(100529) horticulture": "(100529) horticulture",
    "(100531) veterinary medicine": "(100531) veterinary medicine",
    "(100532) veterinary nursing": "(100532) veterinary nursing",
    "(100539) broadcast engineering": "(100539) broadcast engineering",
    "(100540) cellular pathology": "(100540) cellular pathology",
    "(100544) marine engineering": "(100544) marine engineering",
    "(100545) corrosion technology": "(100545) corrosion technology",
    "(100546) digital circuit engineering": "(100546) digital circuit engineering",
    "(100548) engineering surveying": "(100548) engineering surveying",
    "(100549) environmental impact assessment": "(100549) environmental impact assessment",
    "(100550) gemmology": "(100550) gemmology",
    "(100551) geotechnical engineering": "(100551) geotechnical engineering",
    "(100553) integrated circuit design": "(100553) integrated circuit design",
    "(100560) musical instrument manufacture": "(100560) musical instrument manufacture",
    "(100564) aerospace propulsion systems": "(100564) aerospace propulsion systems",
    "(100565) public health engineering": "(100565) public health engineering",
    "(100566) quarrying": "(100566) quarrying",
    "(100568) ship design": "(100568) ship design",
    "(100572) tissue engineering and regenerative medicine": "(100572) tissue engineering and regenerative medicine",
    "(100573) water quality control": "(100573) water quality control",
    "(100577) fluid mechanics": "(100577) fluid mechanics",
    "(100579) structural mechanics": "(100579) structural mechanics",
    "(100580) acoustics and vibration": "(100580) acoustics and vibration",
    "(100581) electrical power": "(100581) electrical power",
    "(100583) architectural design": "(100583) architectural design",
    "(100584) building technology": "(100584) building technology",
    "(100585) conservation of buildings": "(100585) conservation of buildings",
    "(100586) property development": "(100586) property development",
    "(100587) drawing": "(100587) drawing",
    "(100588) landscape studies": "(100588) landscape studies",
    "(100589) painting": "(100589) painting",
    "(100590) garden design": "(100590) garden design",
    "(100592) sculpture": "(100592) sculpture",
    "(100593) rural planning": "(100593) rural planning",
    "(100594) urban studies": "(100594) urban studies",
    "(100595) printmaking": "(100595) printmaking",
    "(100597) applied economics": "(100597) applied economics",
    "(100599) fine art conservation": "(100599) fine art conservation",
    "(100600) agricultural economics": "(100600) agricultural economics",
    "(100601) economic policy": "(100601) economic policy",
    "(100604) econometrics": "(100604) econometrics",
    "(100606) economic systems": "(100606) economic systems",
    "(100609) democracy": "(100609) democracy",
    "(100610) UK government/parliamentary studies": "(100610) UK government/parliamentary studies",
    "(100612) European Union politics": "(100612) European Union politics",
    "(100616) strategic studies": "(100616) strategic studies",
    "(100617) war and peace studies": "(100617) war and peace studies",
    "(100618) comparative politics": "(100618) comparative politics",
    "(100619) applied sociology": "(100619) applied sociology",
    "(100621) gender studies": "(100621) gender studies",
    "(100622) women's studies": "(100622) women's studies",
    "(100623) men's studies": "(100623) men's studies",
    "(100624) ethnicity": "(100624) ethnicity",
    "(100625) disability studies": "(100625) disability studies",
    "(100626) religion in society": "(100626) religion in society",
    "(100627) socio-economics": "(100627) socio-economics",
    "(100628) social theory": "(100628) social theory",
    "(100629) political sociology": "(100629) political sociology",
    "(100630) typography": "(100630) typography",
    "(100631) sociology of science and technology": "(100631) sociology of science and technology",
    "(100632) visual communication": "(100632) visual communication",
    "(100633) furniture design and making": "(100633) furniture design and making",
    "(100636) interactive and electronic design": "(100636) interactive and electronic design",
    "(100637) musicianship and performance studies": "(100637) musicianship and performance studies",
    "(100639) instrumental or vocal performance": "(100639) instrumental or vocal performance",
    "(100642) music education and teaching": "(100642) music education and teaching",
    "(100643) music and arts management": "(100643) music and arts management",
    "(100644) music marketing": "(100644) music marketing",
    "(100645) international social policy": "(100645) international social policy",
    "(100647) public policy": "(100647) public policy",
    "(100648) health policy": "(100648) health policy",
    "(100649) welfare policy": "(100649) welfare policy",
    "(100650) conducting": "(100650) conducting",
    "(100651) education policy": "(100651) education policy",
    "(100652) security policy": "(100652) security policy",
    "(100653) health and welfare": "(100653) health and welfare",
    "(100654) child care": "(100654) child care",
    "(100655) community work": "(100655) community work",
    "(100656) jazz performance": "(100656) jazz performance",
    "(100657) popular music performance": "(100657) popular music performance",
    "(100658) careers guidance": "(100658) careers guidance",
    "(100659) community justice": "(100659) community justice",
    "(100661) historical performance practice": "(100661) historical performance practice",
    "(100662) probation/after-care": "(100662) probation/after-care",
    "(100663) physical and biological anthropology": "(100663) physical and biological anthropology",
    "(100664) history of music": "(100664) history of music",
    "(100665) economic geography": "(100665) economic geography",
    "(100666) urban geography": "(100666) urban geography",
    "(100667) musicology": "(100667) musicology",
    "(100668) political geography": "(100668) political geography",
    "(100669) transport geography": "(100669) transport geography",
    "(100670) environmental history": "(100670) environmental history",
    "(100671) cultural geography": "(100671) cultural geography",
    "(100674) ethnomusicology and world music": "(100674) ethnomusicology and world music",
    "(100676) English law": "(100676) English law",
    "(100677) Northern Irish law": "(100677) Northern Irish law",
    "(100678) Scots law": "(100678) Scots law",
    "(100680) European Union law": "(100680) European Union law",
    "(100681) public international law": "(100681) public international law",
    "(100683) comparative law": "(100683) comparative law",
    "(100684) public law": "(100684) public law",
    "(100685) criminal law": "(100685) criminal law",
    "(100686) private law": "(100686) private law",
    "(100689) property law": "(100689) property law",
    "(100690) torts": "(100690) torts",
    "(100691) jurisprudence": "(100691) jurisprudence",
    "(100692) legal practice": "(100692) legal practice",
    "(100693) medical law": "(100693) medical law",
    "(100695) music composition": "(100695) music composition",
    "(100697) directing for theatre": "(100697) directing for theatre",
    "(100698) theatre studies": "(100698) theatre studies",
    "(100700) theatre production": "(100700) theatre production",
    "(100702) technical theatre studies": "(100702) technical theatre studies",
    "(100703) stage management": "(100703) stage management",
    "(100704) technical stage management": "(100704) technical stage management",
    "(100705) theatrical wardrobe design": "(100705) theatrical wardrobe design",
    "(100706) hair and make-up": "(100706) hair and make-up",
    "(100707) circus arts": "(100707) circus arts",
    "(100708) stage design": "(100708) stage design",
    "(100710) community theatre": "(100710) community theatre",
    "(100711) choreography": "(100711) choreography",
    "(100712) dance performance": "(100712) dance performance",
    "(100714) history of photography": "(100714) history of photography",
    "(100716) cinematography": "(100716) cinematography",
    "(100717) visual and audio effects": "(100717) visual and audio effects",
    "(100721) metal work": "(100721) metal work",
    "(100724) glass crafts": "(100724) glass crafts",
    "(100725) silversmithing and goldsmithing": "(100725) silversmithing and goldsmithing",
    "(100726) clock and watchmaking": "(100726) clock and watchmaking",
    "(100728) surface decoration": "(100728) surface decoration",
    "(100729) scriptwriting": "(100729) scriptwriting",
    "(100730) poetry writing": "(100730) poetry writing",
    "(100731) professional writing": "(100731) professional writing",
    "(100734) computer architectures": "(100734) computer architectures",
    "(100735) operating systems": "(100735) operating systems",
    "(100736) human-computer interaction": "(100736) human-computer interaction",
    "(100737) multimedia computing science": "(100737) multimedia computing science",
    "(100738) e-business": "(100738) e-business",
    "(100739) beauty therapy": "(100739) beauty therapy",
    "(100741) high performance computing": "(100741) high performance computing",
    "(100743) systems thinking": "(100743) systems thinking",
    "(100744) dietetics": "(100744) dietetics",
    "(100746) clinical practice nursing": "(100746) clinical practice nursing",
    "(100747) medical nursing": "(100747) medical nursing",
    "(100748) cardiology": "(100748) cardiology",
    "(100749) paramedic science": "(100749) paramedic science",
    "(100750) physician associate studies": "(100750) physician associate studies",
    "(100751) information modelling": "(100751) information modelling",
    "(100753) systems analysis and design": "(100753) systems analysis and design",
    "(100754) databases": "(100754) databases",
    "(100755) data management": "(100755) data management",
    "(100756) systems auditing": "(100756) systems auditing",
    "(100757) intelligent systems": "(100757) intelligent systems",
    "(100758) British history": "(100758) British history",
    "(100759) Irish history": "(100759) Irish history",
    "(100760) Welsh history": "(100760) Welsh history",
    "(100761) English history": "(100761) English history",
    "(100762) European history": "(100762) European history",
    "(100763) German history": "(100763) German history",
    "(100764) Italian history": "(100764) Italian history",
    "(100765) Iberian studies": "(100765) Iberian studies",
    "(100766) Russian history": "(100766) Russian history",
    "(100767) American history": "(100767) American history",
    "(100768) USA history": "(100768) USA history",
    "(100769) Latin American history": "(100769) Latin American history",
    "(100771) Chinese history": "(100771) Chinese history",
    "(100772) South Asian history": "(100772) South Asian history",
    "(100773) South East Asian history": "(100773) South East Asian history",
    "(100774) Byzantine studies": "(100774) Byzantine studies",
    "(100777) world history": "(100777) world history",
    "(100778) international history": "(100778) international history",
    "(100779) family history": "(100779) family history",
    "(100780) history of religions": "(100780) history of religions",
    "(100781) intellectual history": "(100781) intellectual history",
    "(100782) history of architecture": "(100782) history of architecture",
    "(100783) history of design": "(100783) history of design",
    "(100784) history of mathematics": "(100784) history of mathematics",
    "(100785) history of medicine": "(100785) history of medicine",
    "(100786) military history": "(100786) military history",
    "(100787) Egyptology": "(100787) Egyptology",
    "(100791) mental philosophy": "(100791) mental philosophy",
    "(100792) social philosophy": "(100792) social philosophy",
    "(100793) ethics": "(100793) ethics",
    "(100794) theology and religious studies": "(100794) theology and religious studies",
    "(100795) Christian studies": "(100795) Christian studies",
    "(100796) Islamic studies": "(100796) Islamic studies",
    "(100797) Judaism": "(100797) Judaism",
    "(100798) buddhism": "(100798) buddhism",
    "(100799) divinity": "(100799) divinity",
    "(100800) religious writings": "(100800) religious writings",
    "(100801) biblical studies": "(100801) biblical studies",
    "(100802) pastoral studies": "(100802) pastoral studies",
    "(100803) comparative religious studies": "(100803) comparative religious studies",
    "(100805) heritage studies": "(100805) heritage studies",
    "(100807) heritage management": "(100807) heritage management",
    "(100808) European business studies": "(100808) European business studies",
    "(100810) strategic management": "(100810) strategic management",
    "(100811) creative management": "(100811) creative management",
    "(100812) project management": "(100812) project management",
    "(100813) change management": "(100813) change management",
    "(100814) organisational development": "(100814) organisational development",
    "(100815) institutional management": "(100815) institutional management",
    "(100817) management and organisation of education": "(100817) management and organisation of education",
    "(100819) land management": "(100819) land management",
    "(100820) property management": "(100820) property management",
    "(100821) requirements engineering": "(100821) requirements engineering",
    "(100822) cell biology": "(100822) cell biology",
    "(100823) emergency and disaster management": "(100823) emergency and disaster management",
    "(100825) property valuation and auctioneering": "(100825) property valuation and auctioneering",
    "(100826) parasitology": "(100826) parasitology",
    "(100827) banking": "(100827) banking",
    "(100828) investment": "(100828) investment",
    "(100829) behavioural biology": "(100829) behavioural biology",
    "(100830) insurance": "(100830) insurance",
    "(100831) taxation": "(100831) taxation",
    "(100832) financial management": "(100832) financial management",
    "(100834) developmental biology": "(100834) developmental biology",
    "(100835) financial risk": "(100835) financial risk",
    "(100836) management accountancy": "(100836) management accountancy",
    "(100838) book-keeping": "(100838) book-keeping",
    "(100840) audit management": "(100840) audit management",
    "(100841) popular music": "(100841) popular music",
    "(100842) film music and screen music": "(100842) film music and screen music",
    "(100843) jazz": "(100843) jazz",
    "(100845) financial reporting": "(100845) financial reporting",
    "(100846) market research": "(100846) market research",
    "(100847) reproductive biology": "(100847) reproductive biology",
    "(100848) aquatic biology": "(100848) aquatic biology",
    "(100849) freshwater biology": "(100849) freshwater biology",
    "(100850) population biology": "(100850) population biology",
    "(100851) sales management": "(100851) sales management",
    "(100853) international marketing": "(100853) international marketing",
    "(100854) community music": "(100854) community music",
    "(100855) promotion and advertising": "(100855) promotion and advertising",
    "(100856) corporate image": "(100856) corporate image",
    "(100858) evolution": "(100858) evolution",
    "(100861) staff development": "(100861) staff development",
    "(100862) sonic arts": "(100862) sonic arts",
    "(100864) ecosystem ecology and land use": "(100864) ecosystem ecology and land use",
    "(100865) biometry": "(100865) biometry",
    "(100866) health and safety management": "(100866) health and safety management",
    "(100867) electronic music": "(100867) electronic music",
    "(100868) office administration": "(100868) office administration",
    "(100869) bioinformatics": "(100869) bioinformatics",
    "(100870) jazz composition": "(100870) jazz composition",
    "(100872) mycology": "(100872) mycology",
    "(100873) plant cell science": "(100873) plant cell science",
    "(100874) plant pathology": "(100874) plant pathology",
    "(100875) tourism": "(100875) tourism",
    "(100880) applied zoology": "(100880) applied zoology",
    "(100881) cell zoology": "(100881) cell zoology",
    "(100882) entomology": "(100882) entomology",
    "(100883) marine zoology": "(100883) marine zoology",
    "(100884) pest management": "(100884) pest management",
    "(100885) ballet": "(100885) ballet",
    "(100886) contemporary dance": "(100886) contemporary dance",
    "(100887) moving image techniques": "(100887) moving image techniques",
    "(100888) film directing": "(100888) film directing",
    "(100890) film and sound recording": "(100890) film and sound recording",
    "(100891) hospitality": "(100891) hospitality",
    "(100893) recreation and leisure studies": "(100893) recreation and leisure studies",
    "(100894) spa management": "(100894) spa management",
    "(100895) crafts": "(100895) crafts",
    "(100898) human genetics": "(100898) human genetics",
    "(100899) medical genetics": "(100899) medical genetics",
    "(100900) molecular genetics": "(100900) molecular genetics",
    "(100901) genomics": "(100901) genomics",
    "(100902) population genetics": "(100902) population genetics",
    "(100906) applied microbiology": "(100906) applied microbiology",
    "(100907) medical microbiology": "(100907) medical microbiology",
    "(100908) veterinary microbiology": "(100908) veterinary microbiology",
    "(100909) bacteriology": "(100909) bacteriology",
    "(100910) virology": "(100910) virology",
    "(100911) immunology": "(100911) immunology",
    "(100912) blood sciences": "(100912) blood sciences",
    "(100913) librarianship": "(100913) librarianship",
    "(100914) curatorial studies": "(100914) curatorial studies",
    "(100915) archives and records management": "(100915) archives and records management",
    "(100916) information services": "(100916) information services",
    "(100918) museum studies": "(100918) museum studies",
    "(100919) publicity studies": "(100919) publicity studies",
    "(100920) television studies": "(100920) television studies",
    "(100921) radio studies": "(100921) radio studies",
    "(100922) paper-based media studies": "(100922) paper-based media studies",
    "(100923) television production": "(100923) television production",
    "(100924) radio production": "(100924) radio production",
    "(100925) publishing": "(100925) publishing",
    "(100926) electronic publishing": "(100926) electronic publishing",
    "(100927) online publishing": "(100927) online publishing",
    "(100932) plant biochemistry": "(100932) plant biochemistry",
    "(100936) animal health": "(100936) animal health",
    "(100937) animal physiology": "(100937) animal physiology",
    "(100938) veterinary pathology": "(100938) veterinary pathology",
    "(100939) veterinary pharmacology": "(100939) veterinary pharmacology",
    "(100940) animal nutrition": "(100940) animal nutrition",
    "(100941) veterinary pharmacy": "(100941) veterinary pharmacy",
    "(100942) veterinary public health": "(100942) veterinary public health",
    "(100945) crop protection": "(100945) crop protection",
    "(100946) crop nutrition": "(100946) crop nutrition",
    "(100947) crop production": "(100947) crop production",
    "(100948) biomolecular science": "(100948) biomolecular science",
    "(100949) biophysical science": "(100949) biophysical science",
    "(100950) occupational psychology": "(100950) occupational psychology",
    "(100952) developmental psychology": "(100952) developmental psychology",
    "(100953) child psychology": "(100953) child psychology",
    "(100954) business psychology": "(100954) business psychology",
    "(100956) programming": "(100956) programming",
    "(100958) psychology of ageing": "(100958) psychology of ageing",
    "(100959) research methods in psychology": "(100959) research methods in psychology",
    "(100960) object-oriented programming": "(100960) object-oriented programming",
    "(100961) natural language processing": "(100961) natural language processing",
    "(100962) research skills": "(100962) research skills",
    "(100963) knowledge and information systems": "(100963) knowledge and information systems",
    "(100966) neural computing": "(100966) neural computing",
    "(100968) computer vision": "(100968) computer vision",
    "(100970) applied linguistics": "(100970) applied linguistics",
    "(100971) phonetics and phonology": "(100971) phonetics and phonology",
    "(100972) phonetics": "(100972) phonetics",
    "(100973) phonology": "(100973) phonology",
    "(100974) livestock": "(100974) livestock",
    "(100976) aquaculture": "(100976) aquaculture",
    "(100977) rural estate management": "(100977) rural estate management",
    "(100978) farm management": "(100978) farm management",
    "(100979) game keeping management": "(100979) game keeping management",
    "(100985) health psychology": "(100985) health psychology",
    "(100986) water resource management": "(100986) water resource management",
    "(100989) cognitive modelling": "(100989) cognitive modelling",
    "(100992) machine learning": "(100992) machine learning",
    "(100993) cognitive psychology": "(100993) cognitive psychology",
    "(100994) health informatics": "(100994) health informatics",
    "(100998) sustainable agriculture and landscape development": "(100998) sustainable agriculture and landscape development",
    "(101001) international agriculture": "(101001) international agriculture",
    "(101003) psychology of religion": "(101003) psychology of religion",
    "(101006) agricultural technology": "(101006) agricultural technology",
    "(101010) agricultural machinery": "(101010) agricultural machinery",
    "(101016) sociolinguistics": "(101016) sociolinguistics",
    "(101017) food and beverage studies": "(101017) food and beverage studies",
    "(101018) food safety": "(101018) food safety",
    "(101019) computer games graphics": "(101019) computer games graphics",
    "(101020) computer games programming": "(101020) computer games programming",
    "(101021) baking technology management": "(101021) baking technology management",
    "(101022) brewing": "(101022) brewing",
    "(101025) agricultural botany": "(101025) agricultural botany",
    "(101027) numerical analysis": "(101027) numerical analysis",
    "(101028) engineering and industrial mathematics": "(101028) engineering and industrial mathematics",
    "(101029) computational mathematics": "(101029) computational mathematics",
    "(101030) applied statistics": "(101030) applied statistics",
    "(101031) medical statistics": "(101031) medical statistics",
    "(101032) probability": "(101032) probability",
    "(101033) stochastic processes": "(101033) stochastic processes",
    "(101034) statistical modelling": "(101034) statistical modelling",
    "(101035) psycholinguistics": "(101035) psycholinguistics",
    "(101037) comparative literary studies": "(101037) comparative literary studies",
    "(101038) applied chemistry": "(101038) applied chemistry",
    "(101040) risk management": "(101040) risk management",
    "(101041) industrial chemistry": "(101041) industrial chemistry",
    "(101042) colour chemistry": "(101042) colour chemistry",
    "(101043) inorganic chemistry": "(101043) inorganic chemistry",
    "(101044) crystallography": "(101044) crystallography",
    "(101045) environmental chemistry": "(101045) environmental chemistry",
    "(101046) marine chemistry": "(101046) marine chemistry",
    "(101048) environmental risk": "(101048) environmental risk",
    "(101049) health risk": "(101049) health risk",
    "(101050) physical chemistry": "(101050) physical chemistry",
    "(101053) polymer chemistry": "(101053) polymer chemistry",
    "(101054) oil and gas chemistry": "(101054) oil and gas chemistry",
    "(101056) remote sensing": "(101056) remote sensing",
    "(101058) mapping science": "(101058) mapping science",
    "(101060) applied physics": "(101060) applied physics",
    "(101061) engineering physics": "(101061) engineering physics",
    "(101064) geomorphology": "(101064) geomorphology",
    "(101065) maritime geography": "(101065) maritime geography",
    "(101067) soil science": "(101067) soil science",
    "(101068) atmospheric physics": "(101068) atmospheric physics",
    "(101070) climate change": "(101070) climate change",
    "(101071) computational physics": "(101071) computational physics",
    "(101072) pollution control": "(101072) pollution control",
    "(101073) hydrography": "(101073) hydrography",
    "(101074) radiation physics": "(101074) radiation physics",
    "(101075) photonics and optical physics": "(101075) photonics and optical physics",
    "(101076) laser physics": "(101076) laser physics",
    "(101077) nuclear and particle physics": "(101077) nuclear and particle physics",
    "(101078) applied environmental sciences": "(101078) applied environmental sciences",
    "(101079) hydrology": "(101079) hydrology",
    "(101081) volcanology": "(101081) volcanology",
    "(101082) geological hazards": "(101082) geological hazards",
    "(101083) geochemistry": "(101083) geochemistry",
    "(101084) exploration geophysics": "(101084) exploration geophysics",
    "(101085) specialist teaching": "(101085) specialist teaching",
    "(101086) geological oceanography": "(101086) geological oceanography",
    "(101087) special needs teaching": "(101087) special needs teaching",
    "(101088) research and study skills in education": "(101088) research and study skills in education",
    "(101089) hydrogeology": "(101089) hydrogeology",
    "(101090) study skills": "(101090) study skills",
    "(101091) quaternary studies": "(101091) quaternary studies",
    "(101093) exploration geology": "(101093) exploration geology",
    "(101094) English literature 1200 - 1700": "(101094) English literature 1200 - 1700",
    "(101095) English literature 1700 - 1900": "(101095) English literature 1700 - 1900",
    "(101102) space science": "(101102) space science",
    "(101103) planetary science": "(101103) planetary science",
    "(101104) applied geology": "(101104) applied geology",
    "(101105) petroleum geology": "(101105) petroleum geology",
    "(101106) engineering geology": "(101106) engineering geology",
    "(101107) Shakespeare studies": "(101107) Shakespeare studies",
    "(101108) colonial and post-colonial literature": "(101108) colonial and post-colonial literature",
    "(101109) English as a second language": "(101109) English as a second language",
    "(101111) Scottish literature": "(101111) Scottish literature",
    "(101112) ancient Middle Eastern languages": "(101112) ancient Middle Eastern languages",
    "(101113) ancient Egyptian studies": "(101113) ancient Egyptian studies",
    "(101114) classical Arabic": "(101114) classical Arabic",
    "(101115) Sanskrit studies": "(101115) Sanskrit studies",
    "(101117) ancient Hebrew language": "(101117) ancient Hebrew language",
    "(101118) Celtic studies": "(101118) Celtic studies",
    "(101120) Gaelic language": "(101120) Gaelic language",
    "(101121) Irish language": "(101121) Irish language",
    "(101124) Latin studies": "(101124) Latin studies",
    "(101125) Latin literature": "(101125) Latin literature",
    "(101126) classical Greek studies": "(101126) classical Greek studies",
    "(101129) classical reception": "(101129) classical reception",
    "(101130) translation studies": "(101130) translation studies",
    "(101132) French literature": "(101132) French literature",
    "(101133) French society and culture": "(101133) French society and culture",
    "(101134) German literature": "(101134) German literature",
    "(101135) German society and culture": "(101135) German society and culture",
    "(101136) Italian society and culture": "(101136) Italian society and culture",
    "(101138) Spanish society and culture": "(101138) Spanish society and culture",
    "(101139) Spanish literature": "(101139) Spanish literature",
    "(101141) Portuguese studies": "(101141) Portuguese studies",
    "(101142) Portuguese language": "(101142) Portuguese language",
    "(101143) Brazilian studies": "(101143) Brazilian studies",
    "(101144) Portuguese society and culture": "(101144) Portuguese society and culture",
    "(101145) Scandinavian studies": "(101145) Scandinavian studies",
    "(101148) Swedish language": "(101148) Swedish language",
    "(101149) Norwegian language": "(101149) Norwegian language",
    "(101150) Finnish language": "(101150) Finnish language",
    "(101151) Russian studies": "(101151) Russian studies",
    "(101152) Polish studies": "(101152) Polish studies",
    "(101153) Polish language": "(101153) Polish language",
    "(101155) Czech language": "(101155) Czech language",
    "(101157) Russian literature": "(101157) Russian literature",
    "(101158) Russian and East European society and culture": "(101158) Russian and East European society and culture",
    "(101159) European studies": "(101159) European studies",
    "(101161) Dutch studies": "(101161) Dutch studies",
    "(101163) Welsh literature": "(101163) Welsh literature",
    "(101164) Chinese studies": "(101164) Chinese studies",
    "(101165) Chinese languages": "(101165) Chinese languages",
    "(101167) Chinese society and culture studies": "(101167) Chinese society and culture studies",
    "(101168) Japanese studies": "(101168) Japanese studies",
    "(101169) Japanese languages": "(101169) Japanese languages",
    "(101171) Japanese society and culture studies": "(101171) Japanese society and culture studies",
    "(101172) South Asian studies": "(101172) South Asian studies",
    "(101179) Indian society and culture studies": "(101179) Indian society and culture studies",
    "(101180) Asian studies": "(101180) Asian studies",
    "(101184) African studies": "(101184) African studies",
    "(101185) African languages": "(101185) African languages",
    "(101189) African society and culture studies": "(101189) African society and culture studies",
    "(101190) modern Middle Eastern studies": "(101190) modern Middle Eastern studies",
    "(101191) modern Middle Eastern languages": "(101191) modern Middle Eastern languages",
    "(101192) Arabic languages": "(101192) Arabic languages",
    "(101193) Persian languages": "(101193) Persian languages",
    "(101194) Israeli studies": "(101194) Israeli studies",
    "(101195) Turkish studies": "(101195) Turkish studies",
    "(101197) modern Middle Eastern society and culture studies": "(101197) modern Middle Eastern society and culture studies",
    "(101198) Arab society and culture studies": "(101198) Arab society and culture studies",
    "(101199) Latin American studies": "(101199) Latin American studies",
    "(101202) Latin American society and culture studies": "(101202) Latin American society and culture studies",
    "(101203) North American literature studies": "(101203) North American literature studies",
    "(101204) North American society and culture studies": "(101204) North American society and culture studies",
    "(101205) Canadian studies": "(101205) Canadian studies",
    "(101207) Caribbean studies": "(101207) Caribbean studies",
    "(101210) biomaterials": "(101210) biomaterials",
    "(101212) Korean studies": "(101212) Korean studies",
    "(101214) cinematics": "(101214) cinematics",
    "(101215) food marketing": "(101215) food marketing",
    "(101216) bioelectronics": "(101216) bioelectronics",
    "(101217) composite materials": "(101217) composite materials",
    "(101218) forensic anthropology": "(101218) forensic anthropology",
    "(101219) forensic archaeology": "(101219) forensic archaeology",
    "(101220) veterinary epidemiology": "(101220) veterinary epidemiology",
    "(101221) enterprise and entrepreneurship": "(101221) enterprise and entrepreneurship",
    "(101222) crime scene investigation": "(101222) crime scene investigation",
    "(101223) condensed matter physics": "(101223) condensed matter physics",
    "(101233) cultural studies": "(101233) cultural studies",
    "(101234) nanotechnology": "(101234) nanotechnology",
    "(101241) music therapy": "(101241) music therapy",
    "(101243) bioengineering": "(101243) bioengineering",
    "(101246) professional practice in education": "(101246) professional practice in education",
    "(101248) French history": "(101248) French history",
    "(101258) Thai language": "(101258) Thai language",
    "(101261) maritime archaeology": "(101261) maritime archaeology",
    "(101267) computer games": "(101267) computer games",
    "(101268) computer games design": "(101268) computer games design",
    "(101269) modern Hebrew language": "(101269) modern Hebrew language",
    "(101271) East Asian studies": "(101271) East Asian studies",
    "(101273) combined studies": "(101273) combined studies",
    "(101274) general studies": "(101274) general studies",
    "(101275) negotiated studies": "(101275) negotiated studies",
    "(101276) work placement experience (personal learning)": "(101276) work placement experience (personal learning)",
    "(101277) work-based learning": "(101277) work-based learning",
    "(101278) employability skills (personal learning)": "(101278) employability skills (personal learning)",
    "(101279) advice and guidance (personal learning)": "(101279) advice and guidance (personal learning)",
    "(101288) international studies": "(101288) international studies",
    "(101289) rehabilitation studies": "(101289) rehabilitation studies",
    "(101290) neurological rehabilitation": "(101290) neurological rehabilitation",
    "(101291) cardiovascular rehabilitation": "(101291) cardiovascular rehabilitation",
    "(101294) coaching psychology": "(101294) coaching psychology",
    "(101300) quantum theory and applications": "(101300) quantum theory and applications",
    "(101307) applied social science": "(101307) applied social science",
    "(101308) facilities management": "(101308) facilities management",
    "(101309) obstetrics and gynaecology": "(101309) obstetrics and gynaecology",
    "(101312) Czech studies": "(101312) Czech studies",
    "(101315) Irish studies": "(101315) Irish studies",
    "(101316) interior design and architecture": "(101316) interior design and architecture",
    "(101317) environmental and public health": "(101317) environmental and public health",
    "(101318) biodiversity conservation": "(101318) biodiversity conservation",
    "(101319) exercise for health": "(101319) exercise for health",
    "(101320) art psychotherapy": "(101320) art psychotherapy",
    "(101322) mentorship": "(101322) mentorship",
    "(101323) dispute resolution": "(101323) dispute resolution",
    "(101324) orthopaedics": "(101324) orthopaedics",
    "(101325) paediatrics": "(101325) paediatrics",
    "(101326) gerontology": "(101326) gerontology",
    "(101327) oncology": "(101327) oncology",
    "(101329) dementia studies": "(101329) dementia studies",
    "(101330) ultrasound": "(101330) ultrasound",
    "(101331) gastroenterology": "(101331) gastroenterology",
    "(101332) drug and alcohol studies": "(101332) drug and alcohol studies",
    "(101333) epilepsy care": "(101333) epilepsy care",
    "(101334) allergy": "(101334) allergy",
    "(101335) epidemiology": "(101335) epidemiology",
    "(101336) anaesthesia": "(101336) anaesthesia",
    "(101337) endocrinology": "(101337) endocrinology",
    "(101338) diabetes": "(101338) diabetes",
    "(101339) dermatology": "(101339) dermatology",
    "(101340) hypnotherapy": "(101340) hypnotherapy",
    "(101341) psychology of communication": "(101341) psychology of communication",
    "(101342) psychology of memory and learning": "(101342) psychology of memory and learning",
    "(101343) transpersonal psychology": "(101343) transpersonal psychology",
    "(101344) psychobiology": "(101344) psychobiology",
    "(101345) evolutionary psychology": "(101345) evolutionary psychology",
    "(101348) exotic plants and crops": "(101348) exotic plants and crops",
    "(101351) atmosphere-ocean interactions": "(101351) atmosphere-ocean interactions",
    "(101352) biogeography": "(101352) biogeography",
    "(101353) electrical power generation": "(101353) electrical power generation",
    "(101354) electrical power distribution": "(101354) electrical power distribution",
    "(101355) cybernetics": "(101355) cybernetics",
    "(101356) paper technology": "(101356) paper technology",
    "(101357) nationalism": "(101357) nationalism",
    "(101358) development in Africa": "(101358) development in Africa",
    "(101360) African history": "(101360) African history",
    "(101361) creative arts and design": "(101361) creative arts and design",
    "(101363) psychology of music": "(101363) psychology of music",
    "(101366) Swahili and other Bantu languages": "(101366) Swahili and other Bantu languages",
    "(101368) Indonesian language": "(101368) Indonesian language",
    "(101372) South East Asian studies": "(101372) South East Asian studies",
    "(101373) hair and beauty sciences": "(101373) hair and beauty sciences",
    "(101376) applied botany": "(101376) applied botany",
    "(101377) transcriptomics": "(101377) transcriptomics",
    "(101378) genetic engineering": "(101378) genetic engineering",
    "(101379) sport technology": "(101379) sport technology",
    "(101380) metabolic biochemistry": "(101380) metabolic biochemistry",
    "(101381) cognitive neuroscience": "(101381) cognitive neuroscience",
    "(101382) affective neuroscience": "(101382) affective neuroscience",
    "(101383) psychometrics": "(101383) psychometrics",
    "(101384) pre-clinical veterinary medicine": "(101384) pre-clinical veterinary medicine",
    "(101387) meat science": "(101387) meat science",
    "(101388) cereal science": "(101388) cereal science",
    "(101389) organometallic chemistry": "(101389) organometallic chemistry",
    "(101390) marine physics": "(101390) marine physics",
    "(101391) electromagnetism": "(101391) electromagnetism",
    "(101394) glaciology and cryospheric systems": "(101394) glaciology and cryospheric systems",
    "(101396) solid mechanics": "(101396) solid mechanics",
    "(101397) turbine technology": "(101397) turbine technology",
    "(101398) rail vehicle engineering": "(101398) rail vehicle engineering",
    "(101399) analogue circuit engineering": "(101399) analogue circuit engineering",
    "(101400) parallel computing": "(101400) parallel computing",
    "(101401) microeconomics": "(101401) microeconomics",
    "(101402) macroeconomics": "(101402) macroeconomics",
    "(101403) feminism": "(101403) feminism",
    "(101404) anarchism": "(101404) anarchism",
    "(101405) victimology": "(101405) victimology",
    "(101406) transport policy": "(101406) transport policy",
    "(101407) agricultural geography": "(101407) agricultural geography",
    "(101408) human demography": "(101408) human demography",
    "(101412) Scots language": "(101412) Scots language",
    "(101413) Irish language literature": "(101413) Irish language literature",
    "(101415) Akkadian language": "(101415) Akkadian language",
    "(101416) Sumerian language": "(101416) Sumerian language",
    "(101420) Latin language": "(101420) Latin language",
    "(101422) classical church Greek": "(101422) classical church Greek",
    "(101423) classical Greek literature": "(101423) classical Greek literature",
    "(101424) Danish language": "(101424) Danish language",
    "(101427) Hungarian language": "(101427) Hungarian language",
    "(101428) south Slavonic languages": "(101428) south Slavonic languages",
    "(101429) Ukrainian language": "(101429) Ukrainian language",
    "(101430) Indian literature studies": "(101430) Indian literature studies",
    "(101431) Turkish languages": "(101431) Turkish languages",
    "(101432) Arabic literature": "(101432) Arabic literature",
    "(101433) Persian literature studies": "(101433) Persian literature studies",
    "(101434) Turkish literature": "(101434) Turkish literature",
    "(101435) oral history": "(101435) oral history",
    "(101436) crime history": "(101436) crime history",
    "(101437) Stone Age": "(101437) Stone Age",
    "(101440) classical art and archaeology": "(101440) classical art and archaeology",
    "(101441) metaphysics": "(101441) metaphysics",
    "(101442) epistemology": "(101442) epistemology",
    "(101443) scholastic philosophy": "(101443) scholastic philosophy",
    "(101444) hinduism": "(101444) hinduism",
    "(101445) the Qur'an and Islamic texts": "(101445) the Qur'an and Islamic texts",
    "(101447) folk music": "(101447) folk music",
    "(101448) opera": "(101448) opera",
    "(101449) music theory and analysis": "(101449) music theory and analysis",
    "(101450) applied music and musicianship": "(101450) applied music and musicianship",
    "(101451) popular music composition": "(101451) popular music composition",
    "(101452) body awareness": "(101452) body awareness",
    "(101453) dance and culture": "(101453) dance and culture",
    "(101454) community dance": "(101454) community dance",
    "(101455) stone crafts": "(101455) stone crafts",
    "(101457) community ecology": "(101457) community ecology",
    "(101458) population ecology": "(101458) population ecology",
    "(101459) ecotoxicology": "(101459) ecotoxicology",
    "(101461) veterinary biochemistry": "(101461) veterinary biochemistry",
    "(101462) quantitative psychology": "(101462) quantitative psychology",
    "(101463) qualitative psychology": "(101463) qualitative psychology",
    "(101464) psychopharmacology": "(101464) psychopharmacology",
    "(101465) sociology of law": "(101465) sociology of law",
    "(101468) John Donne studies": "(101468) John Donne studies",
    "(101484) Shelley studies": "(101484) Shelley studies",
    "(101490) Robert Burns studies": "(101490) Robert Burns studies",
    "(101492) Bob Dylan studies": "(101492) Bob Dylan studies",
    "(101497) Gaelic literature": "(101497) Gaelic literature",
    "(101499) Russian society and culture": "(101499) Russian society and culture",
    "(101500) Polish society and culture": "(101500) Polish society and culture",
    "(101503) Persian society and culture studies": "(101503) Persian society and culture studies",
    "(101504) Turkish society and culture studies": "(101504) Turkish society and culture studies",
    "(101505) carpentry and joinery": "(101505) carpentry and joinery",
    "(101506) liberalism": "(101506) liberalism",
    "(101508) socialism": "(101508) socialism",
    "(101509) fascism": "(101509) fascism",
    "(101510) environmentalism": "(101510) environmentalism",
    "(101511) ophthalmic dispensing": "(101511) ophthalmic dispensing",
    "(200001) apportioned teacher training": "(200001) apportioned teacher training",
    "(100003)": "(100003) ceramics",
    "(100005)": "(100005) clinical engineering",
    "(100033)": "(100033) metallurgy",
    "(100035)": "(100035) musical theatre",
    "(100036)": "(100036) optometry",
    "(100037)": "(100037) orthoptics",
    "(100038)": "(100038) pathobiology",
    "(100046)": "(100046) creative writing",
    "(100048)": "(100048) design",
    "(100050)": "(100050) product design",
    "(100051)": "(100051) textile design",
    "(100052)": "(100052) ergonomics",
    "(100054)": "(100054) fashion",
    "(100055)": "(100055) fashion design",
    "(100057)": "(100057) animation",
    "(100058)": "(100058) film studies",
    "(100059)": "(100059) fine art",
    "(100060)": "(100060) graphic arts",
    "(100061)": "(100061) graphic design",
    "(100062)": "(100062) illustration",
    "(100063)": "(100063) photography",
    "(100065)": "(100065) liberal arts",
    "(100067)": "(100067) acting",
    "(100068)": "(100068) dance",
    "(100069)": "(100069) drama",
    "(100070)": "(100070) music",
    "(100071)": "(100071) performing arts",
    "(100074)": "(100074) advertising",
    "(100075)": "(100075) marketing",
    "(100076)": "(100076) public relations",
    "(100078)": "(100078) business and management",
    "(100079)": "(100079) business studies",
    "(100080)": "(100080) international business",
    "(100083)": "(100083) event management",
    "(100084)": "(100084) hospitality management",
    "(100085)": "(100085) human resource management",
    "(100087)": "(100087) international hospitality management",
    "(100088)": "(100088) leadership",
    "(100089)": "(100089) management studies",
    "(100090)": "(100090) public administration",
    "(100091)": "(100091) public services",
    "(100092)": "(100092) retail management",
    "(100093)": "(100093) logistics",
    "(100095)": "(100095) sports coaching",
    "(100096)": "(100096) sports development",
    "(100097)": "(100097) sports management",
    "(100098)": "(100098) sports studies",
    "(100100)": "(100100) tourism management",
    "(100101)": "(100101) travel and tourism",
    "(100102)": "(100102) travel management",
    "(100104)": "(100104) accountancy",
    "(100105)": "(100105) accounting",
    "(100106)": "(100106) actuarial science",
    "(100107)": "(100107) finance",
    "(100109)": "(100109) clothing production",
    "(100110)": "(100110) footwear production",
    "(100114)": "(100114) aeronautical engineering",
    "(100115)": "(100115) aerospace engineering",
    "(100116)": "(100116) space technology",
    "(100117)": "(100117) avionics",
    "(100118)": "(100118) satellite engineering",
    "(100120)": "(100120) architectural engineering",
    "(100121)": "(100121) architectural technology",
    "(100122)": "(100122) architecture",
    "(100124)": "(100124) landscape architecture and design",
    "(100126)": "(100126) biomechanics",
    "(100127)": "(100127) biomedical engineering",
    "(100128)": "(100128) dental technology",
    "(100129)": "(100129) diagnostic imaging",
    "(100130)": "(100130) prosthetics and orthotics",
    "(100131)": "(100131) radiology",
    "(100132)": "(100132) therapeutic imaging",
    "(100134)": "(100134) biotechnology",
    "(100135)": "(100135) bioprocessing",
    "(100136)": "(100136) environmental biotechnology",
    "(100137)": "(100137) industrial biotechnology",
    "(100138)": "(100138) medical biotechnology",
    "(100139)": "(100139) plant biotechnology",
    "(100141)": "(100141) biochemical engineering",
    "(100143)": "(100143) chemical engineering",
    "(100144)": "(100144) pharmaceutical engineering",
    "(100145)": "(100145) polymer science and technology",
    "(100147)": "(100147) building services engineering",
    "(100148)": "(100148) civil engineering",
    "(100149)": "(100149) construction",
    "(100150)": "(100150) construction and the built environment",
    "(100151)": "(100151) construction management",
    "(100152)": "(100152) offshore engineering",
    "(100153)": "(100153) structural engineering",
    "(100154)": "(100154) transport engineering",
    "(100155)": "(100155) minerals technology",
    "(100156)": "(100156) highways engineering",
    "(100157)": "(100157) railway engineering",
    "(100159)": "(100159) telecommunications engineering",
    "(100160)": "(100160) computer aided engineering",
    "(100162)": "(100162) computer systems engineering",
    "(100163)": "(100163) electrical and electronic engineering",
    "(100164)": "(100164) electrical engineering",
    "(100165)": "(100165) electronic engineering",
    "(100166)": "(100166) control systems",
    "(100168)": "(100168) microelectronic engineering",
    "(100169)": "(100169) optoelectronic engineering",
    "(100170)": "(100170) mechatronics and robotics",
    "(100172)": "(100172) nuclear engineering",
    "(100175)": "(100175) energy engineering",
    "(100176)": "(100176) gas engineering",
    "(100177)": "(100177) microwave engineering",
    "(100178)": "(100178) petroleum engineering",
    "(100180)": "(100180) environmental engineering",
    "(100182)": "(100182) engineering design",
    "(100183)": "(100183) fire safety engineering",
    "(100184)": "(100184) general or integrated engineering",
    "(100185)": "(100185) safety engineering",
    "(100186)": "(100186) emergency and disaster technologies",
    "(100188)": "(100188) systems engineering",
    "(100190)": "(100190) mechanical engineering",
    "(100192)": "(100192) electromechanical engineering",
    "(100193)": "(100193) maintenance engineering",
    "(100194)": "(100194) marine technology",
    "(100196)": "(100196) housing",
    "(100197)": "(100197) planning",
    "(100198)": "(100198) transport planning",
    "(100199)": "(100199) urban and regional planning",
    "(100201)": "(100201) automotive engineering",
    "(100202)": "(100202) manufacturing engineering",
    "(100203)": "(100203) materials engineering",
    "(100204)": "(100204) mining engineering",
    "(100205)": "(100205) motorcycle engineering",
    "(100206)": "(100206) motorsport engineering",
    "(100207)": "(100207) naval architecture",
    "(100209)": "(100209) production and manufacturing engineering",
    "(100210)": "(100210) leather technology",
    "(100211)": "(100211) fabrication",
    "(100213)": "(100213) quality management",
    "(100214)": "(100214) textiles technology",
    "(100216)": "(100216) building surveying",
    "(100217)": "(100217) quantity surveying",
    "(100218)": "(100218) real estate",
    "(100219)": "(100219) surveying",
    "(100221)": "(100221) music technology",
    "(100222)": "(100222) audio technology",
    "(100223)": "(100223) music production",
    "(100225)": "(100225) materials science",
    "(100229)": "(100229) aviation studies",
    "(100230)": "(100230) navigation",
    "(100233)": "(100233) acupuncture",
    "(100234)": "(100234) alternative medicines and therapies",
    "(100235)": "(100235) aromatherapy",
    "(100236)": "(100236) Chinese medical techniques",
    "(100237)": "(100237) herbal medicine",
    "(100239)": "(100239) reflexology",
    "(100241)": "(100241) chiropractic",
    "(100242)": "(100242) complementary medicines and therapies",
    "(100243)": "(100243) osteopathy",
    "(100246)": "(100246) health sciences",
    "(100247)": "(100247) nutrition",
    "(100248)": "(100248) occupational health",
    "(100249)": "(100249) occupational therapy",
    "(100250)": "(100250) pharmacology",
    "(100251)": "(100251) pharmacy",
    "(100252)": "(100252) physiotherapy",
    "(100253)": "(100253) podiatry",
    "(100254)": "(100254) psychotherapy",
    "(100255)": "(100255) speech and language therapy",
    "(100257)": "(100257) audiology",
    "(100258)": "(100258) clinical physiology",
    "(100259)": "(100259) genetics",
    "(100260)": "(100260) healthcare science",
    "(100261)": "(100261) ophthalmology",
    "(100262)": "(100262) physiology",
    "(100264)": "(100264) anatomy",
    "(100265)": "(100265) biomedical sciences",
    "(100266)": "(100266) clinical dentistry",
    "(100267)": "(100267) clinical medicine",
    "(100268)": "(100268) dentistry",
    "(100270)": "(100270) medical sciences",
    "(100271)": "(100271) medicine",
    "(100272)": "(100272) neuroscience",
    "(100273)": "(100273) operating department practice",
    "(100274)": "(100274) pathology",
    "(100275)": "(100275) pre-clinical dentistry",
    "(100276)": "(100276) pre-clinical medicine",
    "(100277)": "(100277) toxicology",
    "(100279)": "(100279) adult nursing",
    "(100280)": "(100280) children's nursing",
    "(100281)": "(100281) community nursing",
    "(100282)": "(100282) critical care nursing",
    "(100283)": "(100283) dental nursing",
    "(100284)": "(100284) emergency nursing",
    "(100285)": "(100285) general practice nursing",
    "(100286)": "(100286) learning disabilities nursing",
    "(100287)": "(100287) mental health nursing",
    "(100288)": "(100288) midwifery",
    "(100289)": "(100289) neonatal nursing",
    "(100290)": "(100290) nursing",
    "(100291)": "(100291) older people nursing",
    "(100292)": "(100292) palliative care nursing",
    "(100293)": "(100293) school nursing",
    "(100294)": "(100294) theatre nursing",
    "(100295)": "(100295) health visiting",
    "(100298)": "(100298) ancient history",
    "(100299)": "(100299) archaeology",
    "(100300)": "(100300) classical studies",
    "(100301)": "(100301) economic history",
    "(100302)": "(100302) history",
    "(100306)": "(100306) history of art",
    "(100307)": "(100307) history of science",
    "(100308)": "(100308) local history",
    "(100309)": "(100309) medieval history",
    "(100310)": "(100310) modern history",
    "(100311)": "(100311) Scottish history",
    "(100312)": "(100312) social history",
    "(100314)": "(100314) humanities",
    "(100316)": "(100316) American studies",
    "(100317)": "(100317) British Sign Language studies",
    "(100318)": "(100318) English language",
    "(100319)": "(100319) English literature",
    "(100320)": "(100320) English studies",
    "(100321)": "(100321) French language",
    "(100322)": "(100322) French studies",
    "(100323)": "(100323) German language",
    "(100324)": "(100324) German studies",
    "(100325)": "(100325) Spanish studies",
    "(100326)": "(100326) Italian language",
    "(100327)": "(100327) Italian studies",
    "(100328)": "(100328) linguistics",
    "(100329)": "(100329) modern languages",
    "(100330)": "(100330) Russian languages",
    "(100331)": "(100331) Russian and East European studies",
    "(100332)": "(100332) Spanish language",
    "(100333)": "(100333) Welsh language",
    "(100335)": "(100335) Welsh studies",
    "(100337)": "(100337) philosophy",
    "(100338)": "(100338) philosophy of science",
    "(100339)": "(100339) religious studies",
    "(100340)": "(100340) theology",
    "(100343)": "(100343) applied biology",
    "(100344)": "(100344) biochemistry",
    "(100345)": "(100345) biological sciences",
    "(100346)": "(100346) biology",
    "(100347)": "(100347) ecology",
    "(100348)": "(100348) environmental biology",
    "(100350)": "(100350) human biology",
    "(100351)": "(100351) marine biology",
    "(100352)": "(100352) medical biochemistry",
    "(100353)": "(100353) microbiology",
    "(100354)": "(100354) molecular biology",
    "(100355)": "(100355) plant sciences",
    "(100356)": "(100356) zoology",
    "(100358)": "(100358) applied computing",
    "(100359)": "(100359) artificial intelligence",
    "(100360)": "(100360) business computing",
    "(100361)": "(100361) business information systems",
    "(100362)": "(100362) business information technology",
    "(100363)": "(100363) computer animation and visual effects",
    "(100365)": "(100365) computer networks",
    "(100366)": "(100366) computer science",
    "(100367)": "(100367) computing and information technology",
    "(100368)": "(100368) creative computing",
    "(100369)": "(100369) geographical information systems",
    "(100370)": "(100370) information management",
    "(100371)": "(100371) information systems",
    "(100372)": "(100372) information technology",
    "(100373)": "(100373) internet technologies",
    "(100374)": "(100374) software engineering",
    "(100375)": "(100375) web and multimedia design",
    "(100376)": "(100376) computer and information security",
    "(100379)": "(100379) climate science",
    "(100380)": "(100380) environmental geoscience",
    "(100381)": "(100381) environmental sciences",
    "(100382)": "(100382) meteorology",
    "(100384)": "(100384) archaeological sciences",
    "(100385)": "(100385) computer forensics",
    "(100386)": "(100386) forensic biology",
    "(100387)": "(100387) forensic psychology",
    "(100388)": "(100388) forensic science",
    "(100390)": "(100390) general science",
    "(100391)": "(100391) natural sciences",
    "(100392)": "(100392) applied science",
    "(100394)": "(100394) earth sciences",
    "(100395)": "(100395) geology",
    "(100396)": "(100396) geophysics",
    "(100398)": "(100398) palaeontology",
    "(100400)": "(100400) applied mathematics",
    "(100401)": "(100401) financial mathematics",
    "(100402)": "(100402) mathematical modelling",
    "(100403)": "(100403) mathematics",
    "(100404)": "(100404) operational research",
    "(100405)": "(100405) pure mathematics",
    "(100406)": "(100406) statistics",
    "(100408)": "(100408) environmental geography",
    "(100409)": "(100409) geography",
    "(100410)": "(100410) physical geography",
    "(100413)": "(100413) analytical chemistry",
    "(100414)": "(100414) astronomy",
    "(100415)": "(100415) astrophysics",
    "(100416)": "(100416) chemical physics",
    "(100417)": "(100417) chemistry",
    "(100418)": "(100418) marine sciences",
    "(100419)": "(100419) medical physics",
    "(100420)": "(100420) medicinal chemistry",
    "(100421)": "(100421) ocean sciences",
    "(100422)": "(100422) organic chemistry",
    "(100423)": "(100423) pharmaceutical chemistry",
    "(100424)": "(100424) physical sciences",
    "(100425)": "(100425) physics",
    "(100426)": "(100426) theoretical physics",
    "(100427)": "(100427) acoustics",
    "(100428)": "(100428) aerodynamics",
    "(100429)": "(100429) dynamics",
    "(100430)": "(100430) mechanics",
    "(100431)": "(100431) thermodynamics",
    "(100433)": "(100433) sport and exercise sciences",
    "(100436)": "(100436) anthropology",
    "(100437)": "(100437) social anthropology",
    "(100439)": "(100439) broadcast journalism",
    "(100440)": "(100440) digital media",
    "(100441)": "(100441) film production",
    "(100442)": "(100442) journalism",
    "(100443)": "(100443) media production",
    "(100444)": "(100444) media and communication studies",
    "(100445)": "(100445) multimedia journalism",
    "(100449)": "(100449) business economics",
    "(100450)": "(100450) economics",
    "(100451)": "(100451) financial economics",
    "(100452)": "(100452) international economics",
    "(100454)": "(100454) adult education",
    "(100455)": "(100455) childhood and youth studies",
    "(100456)": "(100456) childhood studies",
    "(100457)": "(100457) early childhood studies",
    "(100459)": "(100459) education studies",
    "(100460)": "(100460) further education",
    "(100461)": "(100461) higher education",
    "(100462)": "(100462) learning support",
    "(100463)": "(100463) early years education",
    "(100464)": "(100464) primary education",
    "(100465)": "(100465) secondary education",
    "(100466)": "(100466) youth and community work",
    "(100468)": "(100468) countryside management",
    "(100469)": "(100469) environmental management",
    "(100471)": "(100471) social sciences",
    "(100473)": "(100473) health studies",
    "(100475)": "(100475) sports therapy",
    "(100476)": "(100476) health and social care",
    "(100478)": "(100478) human geography",
    "(100482)": "(100482) business law",
    "(100483)": "(100483) criminal justice",
    "(100484)": "(100484) criminology",
    "(100485)": "(100485) law",
    "(100486)": "(100486) policing",
    "(100488)": "(100488) international development",
    "(100489)": "(100489) international politics",
    "(100490)": "(100490) international relations",
    "(100491)": "(100491) politics",
    "(100493)": "(100493) applied psychology",
    "(100494)": "(100494) clinical psychology",
    "(100495)": "(100495) counselling",
    "(100496)": "(100496) educational psychology",
    "(100497)": "(100497) psychology",
    "(100498)": "(100498) social psychology",
    "(100499)": "(100499) sport and exercise psychology",
    "(100501)": "(100501) social care",
    "(100502)": "(100502) social policy",
    "(100503)": "(100503) social work",
    "(100505)": "(100505) sociology",
    "(100507)": "(100507) adult education teaching",
    "(100508)": "(100508) post compulsory education and training",
    "(100509)": "(100509) higher education teaching",
    "(100510)": "(100510) early years teaching",
    "(100511)": "(100511) primary teaching",
    "(100512)": "(100512) secondary teaching",
    "(100513)": "(100513) teaching English as a foreign language",
    "(100516)": "(100516) agricultural sciences",
    "(100517)": "(100517) agriculture",
    "(100518)": "(100518) animal management",
    "(100519)": "(100519) equine studies",
    "(100520)": "(100520) forestry and arboriculture",
    "(100522)": "(100522) animal behaviour",
    "(100523)": "(100523) animal science",
    "(100526)": "(100526) food and beverage production",
    "(100527)": "(100527) food science",
    "(100529)": "(100529) horticulture",
    "(100531)": "(100531) veterinary medicine",
    "(100532)": "(100532) veterinary nursing",
    "(100539)": "(100539) broadcast engineering",
    "(100540)": "(100540) cellular pathology",
    "(100544)": "(100544) marine engineering",
    "(100545)": "(100545) corrosion technology",
    "(100546)": "(100546) digital circuit engineering",
    "(100548)": "(100548) engineering surveying",
    "(100549)": "(100549) environmental impact assessment",
    "(100550)": "(100550) gemmology",
    "(100551)": "(100551) geotechnical engineering",
    "(100553)": "(100553) integrated circuit design",
    "(100560)": "(100560) musical instrument manufacture",
    "(100564)": "(100564) aerospace propulsion systems",
    "(100565)": "(100565) public health engineering",
    "(100566)": "(100566) quarrying",
    "(100568)": "(100568) ship design",
    "(100572)": "(100572) tissue engineering and regenerative medicine",
    "(100573)": "(100573) water quality control",
    "(100577)": "(100577) fluid mechanics",
    "(100579)": "(100579) structural mechanics",
    "(100580)": "(100580) acoustics and vibration",
    "(100581)": "(100581) electrical power",
    "(100583)": "(100583) architectural design",
    "(100584)": "(100584) building technology",
    "(100585)": "(100585) conservation of buildings",
    "(100586)": "(100586) property development",
    "(100587)": "(100587) drawing",
    "(100588)": "(100588) landscape studies",
    "(100589)": "(100589) painting",
    "(100590)": "(100590) garden design",
    "(100592)": "(100592) sculpture",
    "(100593)": "(100593) rural planning",
    "(100594)": "(100594) urban studies",
    "(100595)": "(100595) printmaking",
    "(100597)": "(100597) applied economics",
    "(100599)": "(100599) fine art conservation",
    "(100600)": "(100600) agricultural economics",
    "(100601)": "(100601) economic policy",
    "(100604)": "(100604) econometrics",
    "(100606)": "(100606) economic systems",
    "(100609)": "(100609) democracy",
    "(100610)": "(100610) UK government/parliamentary studies",
    "(100612)": "(100612) European Union politics",
    "(100616)": "(100616) strategic studies",
    "(100617)": "(100617) war and peace studies",
    "(100618)": "(100618) comparative politics",
    "(100619)": "(100619) applied sociology",
    "(100621)": "(100621) gender studies",
    "(100622)": "(100622) women's studies",
    "(100623)": "(100623) men's studies",
    "(100624)": "(100624) ethnicity",
    "(100625)": "(100625) disability studies",
    "(100626)": "(100626) religion in society",
    "(100627)": "(100627) socio-economics",
    "(100628)": "(100628) social theory",
    "(100629)": "(100629) political sociology",
    "(100630)": "(100630) typography",
    "(100631)": "(100631) sociology of science and technology",
    "(100632)": "(100632) visual communication",
    "(100633)": "(100633) furniture design and making",
    "(100636)": "(100636) interactive and electronic design",
    "(100637)": "(100637) musicianship and performance studies",
    "(100639)": "(100639) instrumental or vocal performance",
    "(100642)": "(100642) music education and teaching",
    "(100643)": "(100643) music and arts management",
    "(100644)": "(100644) music marketing",
    "(100645)": "(100645) international social policy",
    "(100647)": "(100647) public policy",
    "(100648)": "(100648) health policy",
    "(100649)": "(100649) welfare policy",
    "(100650)": "(100650) conducting",
    "(100651)": "(100651) education policy",
    "(100652)": "(100652) security policy",
    "(100653)": "(100653) health and welfare",
    "(100654)": "(100654) child care",
    "(100655)": "(100655) community work",
    "(100656)": "(100656) jazz performance",
    "(100657)": "(100657) popular music performance",
    "(100658)": "(100658) careers guidance",
    "(100659)": "(100659) community justice",
    "(100661)": "(100661) historical performance practice",
    "(100662)": "(100662) probation/after-care",
    "(100663)": "(100663) physical and biological anthropology",
    "(100664)": "(100664) history of music",
    "(100665)": "(100665) economic geography",
    "(100666)": "(100666) urban geography",
    "(100667)": "(100667) musicology",
    "(100668)": "(100668) political geography",
    "(100669)": "(100669) transport geography",
    "(100670)": "(100670) environmental history",
    "(100671)": "(100671) cultural geography",
    "(100674)": "(100674) ethnomusicology and world music",
    "(100676)": "(100676) English law",
    "(100677)": "(100677) Northern Irish law",
    "(100678)": "(100678) Scots law",
    "(100680)": "(100680) European Union law",
    "(100681)": "(100681) public international law",
    "(100683)": "(100683) comparative law",
    "(100684)": "(100684) public law",
    "(100685)": "(100685) criminal law",
    "(100686)": "(100686) private law",
    "(100689)": "(100689) property law",
    "(100690)": "(100690) torts",
    "(100691)": "(100691) jurisprudence",
    "(100692)": "(100692) legal practice",
    "(100693)": "(100693) medical law",
    "(100695)": "(100695) music composition",
    "(100697)": "(100697) directing for theatre",
    "(100698)": "(100698) theatre studies",
    "(100700)": "(100700) theatre production",
    "(100702)": "(100702) technical theatre studies",
    "(100703)": "(100703) stage management",
    "(100704)": "(100704) technical stage management",
    "(100705)": "(100705) theatrical wardrobe design",
    "(100706)": "(100706) hair and make-up",
    "(100707)": "(100707) circus arts",
    "(100708)": "(100708) stage design",
    "(100710)": "(100710) community theatre",
    "(100711)": "(100711) choreography",
    "(100712)": "(100712) dance performance",
    "(100714)": "(100714) history of photography",
    "(100716)": "(100716) cinematography",
    "(100717)": "(100717) visual and audio effects",
    "(100721)": "(100721) metal work",
    "(100724)": "(100724) glass crafts",
    "(100725)": "(100725) silversmithing and goldsmithing",
    "(100726)": "(100726) clock and watchmaking",
    "(100728)": "(100728) surface decoration",
    "(100729)": "(100729) scriptwriting",
    "(100730)": "(100730) poetry writing",
    "(100731)": "(100731) professional writing",
    "(100734)": "(100734) computer architectures",
    "(100735)": "(100735) operating systems",
    "(100736)": "(100736) human-computer interaction",
    "(100737)": "(100737) multimedia computing science",
    "(100738)": "(100738) e-business",
    "(100739)": "(100739) beauty therapy",
    "(100741)": "(100741) high performance computing",
    "(100743)": "(100743) systems thinking",
    "(100744)": "(100744) dietetics",
    "(100746)": "(100746) clinical practice nursing",
    "(100747)": "(100747) medical nursing",
    "(100748)": "(100748) cardiology",
    "(100749)": "(100749) paramedic science",
    "(100750)": "(100750) physician associate studies",
    "(100751)": "(100751) information modelling",
    "(100753)": "(100753) systems analysis and design",
    "(100754)": "(100754) databases",
    "(100755)": "(100755) data management",
    "(100756)": "(100756) systems auditing",
    "(100757)": "(100757) intelligent systems",
    "(100758)": "(100758) British history",
    "(100759)": "(100759) Irish history",
    "(100760)": "(100760) Welsh history",
    "(100761)": "(100761) English history",
    "(100762)": "(100762) European history",
    "(100763)": "(100763) German history",
    "(100764)": "(100764) Italian history",
    "(100765)": "(100765) Iberian studies",
    "(100766)": "(100766) Russian history",
    "(100767)": "(100767) American history",
    "(100768)": "(100768) USA history",
    "(100769)": "(100769) Latin American history",
    "(100771)": "(100771) Chinese history",
    "(100772)": "(100772) South Asian history",
    "(100773)": "(100773) South East Asian history",
    "(100774)": "(100774) Byzantine studies",
    "(100777)": "(100777) world history",
    "(100778)": "(100778) international history",
    "(100779)": "(100779) family history",
    "(100780)": "(100780) history of religions",
    "(100781)": "(100781) intellectual history",
    "(100782)": "(100782) history of architecture",
    "(100783)": "(100783) history of design",
    "(100784)": "(100784) history of mathematics",
    "(100785)": "(100785) history of medicine",
    "(100786)": "(100786) military history",
    "(100787)": "(100787) Egyptology",
    "(100791)": "(100791) mental philosophy",
    "(100792)": "(100792) social philosophy",
    "(100793)": "(100793) ethics",
    "(100794)": "(100794) theology and religious studies",
    "(100795)": "(100795) Christian studies",
    "(100796)": "(100796) Islamic studies",
    "(100797)": "(100797) Judaism",
    "(100798)": "(100798) buddhism",
    "(100799)": "(100799) divinity",
    "(100800)": "(100800) religious writings",
    "(100801)": "(100801) biblical studies",
    "(100802)": "(100802) pastoral studies",
    "(100803)": "(100803) comparative religious studies",
    "(100805)": "(100805) heritage studies",
    "(100807)": "(100807) heritage management",
    "(100808)": "(100808) European business studies",
    "(100810)": "(100810) strategic management",
    "(100811)": "(100811) creative management",
    "(100812)": "(100812) project management",
    "(100813)": "(100813) change management",
    "(100814)": "(100814) organisational development",
    "(100815)": "(100815) institutional management",
    "(100817)": "(100817) management and organisation of education",
    "(100819)": "(100819) land management",
    "(100820)": "(100820) property management",
    "(100821)": "(100821) requirements engineering",
    "(100822)": "(100822) cell biology",
    "(100823)": "(100823) emergency and disaster management",
    "(100825)": "(100825) property valuation and auctioneering",
    "(100826)": "(100826) parasitology",
    "(100827)": "(100827) banking",
    "(100828)": "(100828) investment",
    "(100829)": "(100829) behavioural biology",
    "(100830)": "(100830) insurance",
    "(100831)": "(100831) taxation",
    "(100832)": "(100832) financial management",
    "(100834)": "(100834) developmental biology",
    "(100835)": "(100835) financial risk",
    "(100836)": "(100836) management accountancy",
    "(100838)": "(100838) book-keeping",
    "(100840)": "(100840) audit management",
    "(100841)": "(100841) popular music",
    "(100842)": "(100842) film music and screen music",
    "(100843)": "(100843) jazz",
    "(100845)": "(100845) financial reporting",
    "(100846)": "(100846) market research",
    "(100847)": "(100847) reproductive biology",
    "(100848)": "(100848) aquatic biology",
    "(100849)": "(100849) freshwater biology",
    "(100850)": "(100850) population biology",
    "(100851)": "(100851) sales management",
    "(100853)": "(100853) international marketing",
    "(100854)": "(100854) community music",
    "(100855)": "(100855) promotion and advertising",
    "(100856)": "(100856) corporate image",
    "(100858)": "(100858) evolution",
    "(100861)": "(100861) staff development",
    "(100862)": "(100862) sonic arts",
    "(100864)": "(100864) ecosystem ecology and land use",
    "(100865)": "(100865) biometry",
    "(100866)": "(100866) health and safety management",
    "(100867)": "(100867) electronic music",
    "(100868)": "(100868) office administration",
    "(100869)": "(100869) bioinformatics",
    "(100870)": "(100870) jazz composition",
    "(100872)": "(100872) mycology",
    "(100873)": "(100873) plant cell science",
    "(100874)": "(100874) plant pathology",
    "(100875)": "(100875) tourism",
    "(100880)": "(100880) applied zoology",
    "(100881)": "(100881) cell zoology",
    "(100882)": "(100882) entomology",
    "(100883)": "(100883) marine zoology",
    "(100884)": "(100884) pest management",
    "(100885)": "(100885) ballet",
    "(100886)": "(100886) contemporary dance",
    "(100887)": "(100887) moving image techniques",
    "(100888)": "(100888) film directing",
    "(100890)": "(100890) film and sound recording",
    "(100891)": "(100891) hospitality",
    "(100893)": "(100893) recreation and leisure studies",
    "(100894)": "(100894) spa management",
    "(100895)": "(100895) crafts",
    "(100898)": "(100898) human genetics",
    "(100899)": "(100899) medical genetics",
    "(100900)": "(100900) molecular genetics",
    "(100901)": "(100901) genomics",
    "(100902)": "(100902) population genetics",
    "(100906)": "(100906) applied microbiology",
    "(100907)": "(100907) medical microbiology",
    "(100908)": "(100908) veterinary microbiology",
    "(100909)": "(100909) bacteriology",
    "(100910)": "(100910) virology",
    "(100911)": "(100911) immunology",
    "(100912)": "(100912) blood sciences",
    "(100913)": "(100913) librarianship",
    "(100914)": "(100914) curatorial studies",
    "(100915)": "(100915) archives and records management",
    "(100916)": "(100916) information services",
    "(100918)": "(100918) museum studies",
    "(100919)": "(100919) publicity studies",
    "(100920)": "(100920) television studies",
    "(100921)": "(100921) radio studies",
    "(100922)": "(100922) paper-based media studies",
    "(100923)": "(100923) television production",
    "(100924)": "(100924) radio production",
    "(100925)": "(100925) publishing",
    "(100926)": "(100926) electronic publishing",
    "(100927)": "(100927) online publishing",
    "(100932)": "(100932) plant biochemistry",
    "(100936)": "(100936) animal health",
    "(100937)": "(100937) animal physiology",
    "(100938)": "(100938) veterinary pathology",
    "(100939)": "(100939) veterinary pharmacology",
    "(100940)": "(100940) animal nutrition",
    "(100941)": "(100941) veterinary pharmacy",
    "(100942)": "(100942) veterinary public health",
    "(100945)": "(100945) crop protection",
    "(100946)": "(100946) crop nutrition",
    "(100947)": "(100947) crop production",
    "(100948)": "(100948) biomolecular science",
    "(100949)": "(100949) biophysical science",
    "(100950)": "(100950) occupational psychology",
    "(100952)": "(100952) developmental psychology",
    "(100953)": "(100953) child psychology",
    "(100954)": "(100954) business psychology",
    "(100956)": "(100956) programming",
    "(100958)": "(100958) psychology of ageing",
    "(100959)": "(100959) research methods in psychology",
    "(100960)": "(100960) object-oriented programming",
    "(100961)": "(100961) natural language processing",
    "(100962)": "(100962) research skills",
    "(100963)": "(100963) knowledge and information systems",
    "(100966)": "(100966) neural computing",
    "(100968)": "(100968) computer vision",
    "(100970)": "(100970) applied linguistics",
    "(100971)": "(100971) phonetics and phonology",
    "(100972)": "(100972) phonetics",
    "(100973)": "(100973) phonology",
    "(100974)": "(100974) livestock",
    "(100976)": "(100976) aquaculture",
    "(100977)": "(100977) rural estate management",
    "(100978)": "(100978) farm management",
    "(100979)": "(100979) game keeping management",
    "(100985)": "(100985) health psychology",
    "(100986)": "(100986) water resource management",
    "(100989)": "(100989) cognitive modelling",
    "(100992)": "(100992) machine learning",
    "(100993)": "(100993) cognitive psychology",
    "(100994)": "(100994) health informatics",
    "(100998)": "(100998) sustainable agriculture and landscape development",
    "(101001)": "(101001) international agriculture",
    "(101003)": "(101003) psychology of religion",
    "(101006)": "(101006) agricultural technology",
    "(101010)": "(101010) agricultural machinery",
    "(101016)": "(101016) sociolinguistics",
    "(101017)": "(101017) food and beverage studies",
    "(101018)": "(101018) food safety",
    "(101019)": "(101019) computer games graphics",
    "(101020)": "(101020) computer games programming",
    "(101021)": "(101021) baking technology management",
    "(101022)": "(101022) brewing",
    "(101025)": "(101025) agricultural botany",
    "(101027)": "(101027) numerical analysis",
    "(101028)": "(101028) engineering and industrial mathematics",
    "(101029)": "(101029) computational mathematics",
    "(101030)": "(101030) applied statistics",
    "(101031)": "(101031) medical statistics",
    "(101032)": "(101032) probability",
    "(101033)": "(101033) stochastic processes",
    "(101034)": "(101034) statistical modelling",
    "(101035)": "(101035) psycholinguistics",
    "(101037)": "(101037) comparative literary studies",
    "(101038)": "(101038) applied chemistry",
    "(101040)": "(101040) risk management",
    "(101041)": "(101041) industrial chemistry",
    "(101042)": "(101042) colour chemistry",
    "(101043)": "(101043) inorganic chemistry",
    "(101044)": "(101044) crystallography",
    "(101045)": "(101045) environmental chemistry",
    "(101046)": "(101046) marine chemistry",
    "(101048)": "(101048) environmental risk",
    "(101049)": "(101049) health risk",
    "(101050)": "(101050) physical chemistry",
    "(101053)": "(101053) polymer chemistry",
    "(101054)": "(101054) oil and gas chemistry",
    "(101056)": "(101056) remote sensing",
    "(101058)": "(101058) mapping science",
    "(101060)": "(101060) applied physics",
    "(101061)": "(101061) engineering physics",
    "(101064)": "(101064) geomorphology",
    "(101065)": "(101065) maritime geography",
    "(101067)": "(101067) soil science",
    "(101068)": "(101068) atmospheric physics",
    "(101070)": "(101070) climate change",
    "(101071)": "(101071) computational physics",
    "(101072)": "(101072) pollution control",
    "(101073)": "(101073) hydrography",
    "(101074)": "(101074) radiation physics",
    "(101075)": "(101075) photonics and optical physics",
    "(101076)": "(101076) laser physics",
    "(101077)": "(101077) nuclear and particle physics",
    "(101078)": "(101078) applied environmental sciences",
    "(101079)": "(101079) hydrology",
    "(101081)": "(101081) volcanology",
    "(101082)": "(101082) geological hazards",
    "(101083)": "(101083) geochemistry",
    "(101084)": "(101084) exploration geophysics",
    "(101085)": "(101085) specialist teaching",
    "(101086)": "(101086) geological oceanography",
    "(101087)": "(101087) special needs teaching",
    "(101088)": "(101088) research and study skills in education",
    "(101089)": "(101089) hydrogeology",
    "(101090)": "(101090) study skills",
    "(101091)": "(101091) quaternary studies",
    "(101093)": "(101093) exploration geology",
    "(101094)": "(101094) English literature 1200 - 1700",
    "(101095)": "(101095) English literature 1700 - 1900",
    "(101102)": "(101102) space science",
    "(101103)": "(101103) planetary science",
    "(101104)": "(101104) applied geology",
    "(101105)": "(101105) petroleum geology",
    "(101106)": "(101106) engineering geology",
    "(101107)": "(101107) Shakespeare studies",
    "(101108)": "(101108) colonial and post-colonial literature",
    "(101109)": "(101109) English as a second language",
    "(101111)": "(101111) Scottish literature",
    "(101112)": "(101112) ancient Middle Eastern languages",
    "(101113)": "(101113) ancient Egyptian studies",
    "(101114)": "(101114) classical Arabic",
    "(101115)": "(101115) Sanskrit studies",
    "(101117)": "(101117) ancient Hebrew language",
    "(101118)": "(101118) Celtic studies",
    "(101120)": "(101120) Gaelic language",
    "(101121)": "(101121) Irish language",
    "(101124)": "(101124) Latin studies",
    "(101125)": "(101125) Latin literature",
    "(101126)": "(101126) classical Greek studies",
    "(101129)": "(101129) classical reception",
    "(101130)": "(101130) translation studies",
    "(101132)": "(101132) French literature",
    "(101133)": "(101133) French society and culture",
    "(101134)": "(101134) German literature",
    "(101135)": "(101135) German society and culture",
    "(101136)": "(101136) Italian society and culture",
    "(101138)": "(101138) Spanish society and culture",
    "(101139)": "(101139) Spanish literature",
    "(101141)": "(101141) Portuguese studies",
    "(101142)": "(101142) Portuguese language",
    "(101143)": "(101143) Brazilian studies",
    "(101144)": "(101144) Portuguese society and culture",
    "(101145)": "(101145) Scandinavian studies",
    "(101148)": "(101148) Swedish language",
    "(101149)": "(101149) Norwegian language",
    "(101150)": "(101150) Finnish language",
    "(101151)": "(101151) Russian studies",
    "(101152)": "(101152) Polish studies",
    "(101153)": "(101153) Polish language",
    "(101155)": "(101155) Czech language",
    "(101157)": "(101157) Russian literature",
    "(101158)": "(101158) Russian and East European society and culture",
    "(101159)": "(101159) European studies",
    "(101161)": "(101161) Dutch studies",
    "(101163)": "(101163) Welsh literature",
    "(101164)": "(101164) Chinese studies",
    "(101165)": "(101165) Chinese languages",
    "(101167)": "(101167) Chinese society and culture studies",
    "(101168)": "(101168) Japanese studies",
    "(101169)": "(101169) Japanese languages",
    "(101171)": "(101171) Japanese society and culture studies",
    "(101172)": "(101172) South Asian studies",
    "(101179)": "(101179) Indian society and culture studies",
    "(101180)": "(101180) Asian studies",
    "(101184)": "(101184) African studies",
    "(101185)": "(101185) African languages",
    "(101189)": "(101189) African society and culture studies",
    "(101190)": "(101190) modern Middle Eastern studies",
    "(101191)": "(101191) modern Middle Eastern languages",
    "(101192)": "(101192) Arabic languages",
    "(101193)": "(101193) Persian languages",
    "(101194)": "(101194) Israeli studies",
    "(101195)": "(101195) Turkish studies",
    "(101197)": "(101197) modern Middle Eastern society and culture studies",
    "(101198)": "(101198) Arab society and culture studies",
    "(101199)": "(101199) Latin American studies",
    "(101202)": "(101202) Latin American society and culture studies",
    "(101203)": "(101203) North American literature studies",
    "(101204)": "(101204) North American society and culture studies",
    "(101205)": "(101205) Canadian studies",
    "(101207)": "(101207) Caribbean studies",
    "(101210)": "(101210) biomaterials",
    "(101212)": "(101212) Korean studies",
    "(101214)": "(101214) cinematics",
    "(101215)": "(101215) food marketing",
    "(101216)": "(101216) bioelectronics",
    "(101217)": "(101217) composite materials",
    "(101218)": "(101218) forensic anthropology",
    "(101219)": "(101219) forensic archaeology",
    "(101220)": "(101220) veterinary epidemiology",
    "(101221)": "(101221) enterprise and entrepreneurship",
    "(101222)": "(101222) crime scene investigation",
    "(101223)": "(101223) condensed matter physics",
    "(101233)": "(101233) cultural studies",
    "(101234)": "(101234) nanotechnology",
    "(101241)": "(101241) music therapy",
    "(101243)": "(101243) bioengineering",
    "(101246)": "(101246) professional practice in education",
    "(101248)": "(101248) French history",
    "(101258)": "(101258) Thai language",
    "(101261)": "(101261) maritime archaeology",
    "(101267)": "(101267) computer games",
    "(101268)": "(101268) computer games design",
    "(101269)": "(101269) modern Hebrew language",
    "(101271)": "(101271) East Asian studies",
    "(101273)": "(101273) combined studies",
    "(101274)": "(101274) general studies",
    "(101275)": "(101275) negotiated studies",
    "(101276)": "(101276) work placement experience (personal learning)",
    "(101277)": "(101277) work-based learning",
    "(101278)": "(101278) employability skills (personal learning)",
    "(101279)": "(101279) advice and guidance (personal learning)",
    "(101288)": "(101288) international studies",
    "(101289)": "(101289) rehabilitation studies",
    "(101290)": "(101290) neurological rehabilitation",
    "(101291)": "(101291) cardiovascular rehabilitation",
    "(101294)": "(101294) coaching psychology",
    "(101300)": "(101300) quantum theory and applications",
    "(101307)": "(101307) applied social science",
    "(101308)": "(101308) facilities management",
    "(101309)": "(101309) obstetrics and gynaecology",
    "(101312)": "(101312) Czech studies",
    "(101315)": "(101315) Irish studies",
    "(101316)": "(101316) interior design and architecture",
    "(101317)": "(101317) environmental and public health",
    "(101318)": "(101318) biodiversity conservation",
    "(101319)": "(101319) exercise for health",
    "(101320)": "(101320) art psychotherapy",
    "(101322)": "(101322) mentorship",
    "(101323)": "(101323) dispute resolution",
    "(101324)": "(101324) orthopaedics",
    "(101325)": "(101325) paediatrics",
    "(101326)": "(101326) gerontology",
    "(101327)": "(101327) oncology",
    "(101329)": "(101329) dementia studies",
    "(101330)": "(101330) ultrasound",
    "(101331)": "(101331) gastroenterology",
    "(101332)": "(101332) drug and alcohol studies",
    "(101333)": "(101333) epilepsy care",
    "(101334)": "(101334) allergy",
    "(101335)": "(101335) epidemiology",
    "(101336)": "(101336) anaesthesia",
    "(101337)": "(101337) endocrinology",
    "(101338)": "(101338) diabetes",
    "(101339)": "(101339) dermatology",
    "(101340)": "(101340) hypnotherapy",
    "(101341)": "(101341) psychology of communication",
    "(101342)": "(101342) psychology of memory and learning",
    "(101343)": "(101343) transpersonal psychology",
    "(101344)": "(101344) psychobiology",
    "(101345)": "(101345) evolutionary psychology",
    "(101348)": "(101348) exotic plants and crops",
    "(101351)": "(101351) atmosphere-ocean interactions",
    "(101352)": "(101352) biogeography",
    "(101353)": "(101353) electrical power generation",
    "(101354)": "(101354) electrical power distribution",
    "(101355)": "(101355) cybernetics",
    "(101356)": "(101356) paper technology",
    "(101357)": "(101357) nationalism",
    "(101358)": "(101358) development in Africa",
    "(101360)": "(101360) African history",
    "(101361)": "(101361) creative arts and design",
    "(101363)": "(101363) psychology of music",
    "(101366)": "(101366) Swahili and other Bantu languages",
    "(101368)": "(101368) Indonesian language",
    "(101372)": "(101372) South East Asian studies",
    "(101373)": "(101373) hair and beauty sciences",
    "(101376)": "(101376) applied botany",
    "(101377)": "(101377) transcriptomics",
    "(101378)": "(101378) genetic engineering",
    "(101379)": "(101379) sport technology",
    "(101380)": "(101380) metabolic biochemistry",
    "(101381)": "(101381) cognitive neuroscience",
    "(101382)": "(101382) affective neuroscience",
    "(101383)": "(101383) psychometrics",
    "(101384)": "(101384) pre-clinical veterinary medicine",
    "(101387)": "(101387) meat science",
    "(101388)": "(101388) cereal science",
    "(101389)": "(101389) organometallic chemistry",
    "(101390)": "(101390) marine physics",
    "(101391)": "(101391) electromagnetism",
    "(101394)": "(101394) glaciology and cryospheric systems",
    "(101396)": "(101396) solid mechanics",
    "(101397)": "(101397) turbine technology",
    "(101398)": "(101398) rail vehicle engineering",
    "(101399)": "(101399) analogue circuit engineering",
    "(101400)": "(101400) parallel computing",
    "(101401)": "(101401) microeconomics",
    "(101402)": "(101402) macroeconomics",
    "(101403)": "(101403) feminism",
    "(101404)": "(101404) anarchism",
    "(101405)": "(101405) victimology",
    "(101406)": "(101406) transport policy",
    "(101407)": "(101407) agricultural geography",
    "(101408)": "(101408) human demography",
    "(101412)": "(101412) Scots language",
    "(101413)": "(101413) Irish language literature",
    "(101415)": "(101415) Akkadian language",
    "(101416)": "(101416) Sumerian language",
    "(101420)": "(101420) Latin language",
    "(101422)": "(101422) classical church Greek",
    "(101423)": "(101423) classical Greek literature",
    "(101424)": "(101424) Danish language",
    "(101427)": "(101427) Hungarian language",
    "(101428)": "(101428) south Slavonic languages",
    "(101429)": "(101429) Ukrainian language",
    "(101430)": "(101430) Indian literature studies",
    "(101431)": "(101431) Turkish languages",
    "(101432)": "(101432) Arabic literature",
    "(101433)": "(101433) Persian literature studies",
    "(101434)": "(101434) Turkish literature",
    "(101435)": "(101435) oral history",
    "(101436)": "(101436) crime history",
    "(101437)": "(101437) Stone Age",
    "(101440)": "(101440) classical art and archaeology",
    "(101441)": "(101441) metaphysics",
    "(101442)": "(101442) epistemology",
    "(101443)": "(101443) scholastic philosophy",
    "(101444)": "(101444) hinduism",
    "(101445)": "(101445) the Qur'an and Islamic texts",
    "(101447)": "(101447) folk music",
    "(101448)": "(101448) opera",
    "(101449)": "(101449) music theory and analysis",
    "(101450)": "(101450) applied music and musicianship",
    "(101451)": "(101451) popular music composition",
    "(101452)": "(101452) body awareness",
    "(101453)": "(101453) dance and culture",
    "(101454)": "(101454) community dance",
    "(101455)": "(101455) stone crafts",
    "(101457)": "(101457) community ecology",
    "(101458)": "(101458) population ecology",
    "(101459)": "(101459) ecotoxicology",
    "(101461)": "(101461) veterinary biochemistry",
    "(101462)": "(101462) quantitative psychology",
    "(101463)": "(101463) qualitative psychology",
    "(101464)": "(101464) psychopharmacology",
    "(101465)": "(101465) sociology of law",
    "(101468)": "(101468) John Donne studies",
    "(101484)": "(101484) Shelley studies",
    "(101490)": "(101490) Robert Burns studies",
    "(101492)": "(101492) Bob Dylan studies",
    "(101497)": "(101497) Gaelic literature",
    "(101499)": "(101499) Russian society and culture",
    "(101500)": "(101500) Polish society and culture",
    "(101503)": "(101503) Persian society and culture studies",
    "(101504)": "(101504) Turkish society and culture studies",
    "(101505)": "(101505) carpentry and joinery",
    "(101506)": "(101506) liberalism",
    "(101508)": "(101508) socialism",
    "(101509)": "(101509) fascism",
    "(101510)": "(101510) environmentalism",
    "(101511)": "(101511) ophthalmic dispensing",
    "(200001)": "(200001) apportioned teacher training",
    "100003": "(100003) ceramics",
    "100005": "(100005) clinical engineering",
    "100033": "(100033) metallurgy",
    "100035": "(100035) musical theatre",
    "100036": "(100036) optometry",
    "100037": "(100037) orthoptics",
    "100038": "(100038) pathobiology",
    "100046": "(100046) creative writing",
    "100048": "(100048) design",
    "100050": "(100050) product design",
    "100051": "(100051) textile design",
    "100052": "(100052) ergonomics",
    "100054": "(100054) fashion",
    "100055": "(100055) fashion design",
    "100057": "(100057) animation",
    "100058": "(100058) film studies",
    "100059": "(100059) fine art",
    "100060": "(100060) graphic arts",
    "100061": "(100061) graphic design",
    "100062": "(100062) illustration",
    "100063": "(100063) photography",
    "100065": "(100065) liberal arts",
    "100067": "(100067) acting",
    "100068": "(100068) dance",
    "100069": "(100069) drama",
    "100070": "(100070) music",
    "100071": "(100071) performing arts",
    "100074": "(100074) advertising",
    "100075": "(100075) marketing",
    "100076": "(100076) public relations",
    "100078": "(100078) business and management",
    "100079": "(100079) business studies",
    "100080": "(100080) international business",
    "100083": "(100083) event management",
    "100084": "(100084) hospitality management",
    "100085": "(100085) human resource management",
    "100087": "(100087) international hospitality management",
    "100088": "(100088) leadership",
    "100089": "(100089) management studies",
    "100090": "(100090) public administration",
    "100091": "(100091) public services",
    "100092": "(100092) retail management",
    "100093": "(100093) logistics",
    "100095": "(100095) sports coaching",
    "100096": "(100096) sports development",
    "100097": "(100097) sports management",
    "100098": "(100098) sports studies",
    "100100": "(100100) tourism management",
    "100101": "(100101) travel and tourism",
    "100102": "(100102) travel management",
    "100104": "(100104) accountancy",
    "100105": "(100105) accounting",
    "100106": "(100106) actuarial science",
    "100107": "(100107) finance",
    "100109": "(100109) clothing production",
    "100110": "(100110) footwear production",
    "100114": "(100114) aeronautical engineering",
    "100115": "(100115) aerospace engineering",
    "100116": "(100116) space technology",
    "100117": "(100117) avionics",
    "100118": "(100118) satellite engineering",
    "100120": "(100120) architectural engineering",
    "100121": "(100121) architectural technology",
    "100122": "(100122) architecture",
    "100124": "(100124) landscape architecture and design",
    "100126": "(100126) biomechanics",
    "100127": "(100127) biomedical engineering",
    "100128": "(100128) dental technology",
    "100129": "(100129) diagnostic imaging",
    "100130": "(100130) prosthetics and orthotics",
    "100131": "(100131) radiology",
    "100132": "(100132) therapeutic imaging",
    "100134": "(100134) biotechnology",
    "100135": "(100135) bioprocessing",
    "100136": "(100136) environmental biotechnology",
    "100137": "(100137) industrial biotechnology",
    "100138": "(100138) medical biotechnology",
    "100139": "(100139) plant biotechnology",
    "100141": "(100141) biochemical engineering",
    "100143": "(100143) chemical engineering",
    "100144": "(100144) pharmaceutical engineering",
    "100145": "(100145) polymer science and technology",
    "100147": "(100147) building services engineering",
    "100148": "(100148) civil engineering",
    "100149": "(100149) construction",
    "100150": "(100150) construction and the built environment",
    "100151": "(100151) construction management",
    "100152": "(100152) offshore engineering",
    "100153": "(100153) structural engineering",
    "100154": "(100154) transport engineering",
    "100155": "(100155) minerals technology",
    "100156": "(100156) highways engineering",
    "100157": "(100157) railway engineering",
    "100159": "(100159) telecommunications engineering",
    "100160": "(100160) computer aided engineering",
    "100162": "(100162) computer systems engineering",
    "100163": "(100163) electrical and electronic engineering",
    "100164": "(100164) electrical engineering",
    "100165": "(100165) electronic engineering",
    "100166": "(100166) control systems",
    "100168": "(100168) microelectronic engineering",
    "100169": "(100169) optoelectronic engineering",
    "100170": "(100170) mechatronics and robotics",
    "100172": "(100172) nuclear engineering",
    "100175": "(100175) energy engineering",
    "100176": "(100176) gas engineering",
    "100177": "(100177) microwave engineering",
    "100178": "(100178) petroleum engineering",
    "100180": "(100180) environmental engineering",
    "100182": "(100182) engineering design",
    "100183": "(100183) fire safety engineering",
    "100184": "(100184) general or integrated engineering",
    "100185": "(100185) safety engineering",
    "100186": "(100186) emergency and disaster technologies",
    "100188": "(100188) systems engineering",
    "100190": "(100190) mechanical engineering",
    "100192": "(100192) electromechanical engineering",
    "100193": "(100193) maintenance engineering",
    "100194": "(100194) marine technology",
    "100196": "(100196) housing",
    "100197": "(100197) planning",
    "100198": "(100198) transport planning",
    "100199": "(100199) urban and regional planning",
    "100201": "(100201) automotive engineering",
    "100202": "(100202) manufacturing engineering",
    "100203": "(100203) materials engineering",
    "100204": "(100204) mining engineering",
    "100205": "(100205) motorcycle engineering",
    "100206": "(100206) motorsport engineering",
    "100207": "(100207) naval architecture",
    "100209": "(100209) production and manufacturing engineering",
    "100210": "(100210) leather technology",
    "100211": "(100211) fabrication",
    "100213": "(100213) quality management",
    "100214": "(100214) textiles technology",
    "100216": "(100216) building surveying",
    "100217": "(100217) quantity surveying",
    "100218": "(100218) real estate",
    "100219": "(100219) surveying",
    "100221": "(100221) music technology",
    "100222": "(100222) audio technology",
    "100223": "(100223) music production",
    "100225": "(100225) materials science",
    "100229": "(100229) aviation studies",
    "100230": "(100230) navigation",
    "100233": "(100233) acupuncture",
    "100234": "(100234) alternative medicines and therapies",
    "100235": "(100235) aromatherapy",
    "100236": "(100236) Chinese medical techniques",
    "100237": "(100237) herbal medicine",
    "100239": "(100239) reflexology",
    "100241": "(100241) chiropractic",
    "100242": "(100242) complementary medicines and therapies",
    "100243": "(100243) osteopathy",
    "100246": "(100246) health sciences",
    "100247": "(100247) nutrition",
    "100248": "(100248) occupational health",
    "100249": "(100249) occupational therapy",
    "100250": "(100250) pharmacology",
    "100251": "(100251) pharmacy",
    "100252": "(100252) physiotherapy",
    "100253": "(100253) podiatry",
    "100254": "(100254) psychotherapy",
    "100255": "(100255) speech and language therapy",
    "100257": "(100257) audiology",
    "100258": "(100258) clinical physiology",
    "100259": "(100259) genetics",
    "100260": "(100260) healthcare science",
    "100261": "(100261) ophthalmology",
    "100262": "(100262) physiology",
    "100264": "(100264) anatomy",
    "100265": "(100265) biomedical sciences",
    "100266": "(100266) clinical dentistry",
    "100267": "(100267) clinical medicine",
    "100268": "(100268) dentistry",
    "100270": "(100270) medical sciences",
    "100271": "(100271) medicine",
    "100272": "(100272) neuroscience",
    "100273": "(100273) operating department practice",
    "100274": "(100274) pathology",
    "100275": "(100275) pre-clinical dentistry",
    "100276": "(100276) pre-clinical medicine",
    "100277": "(100277) toxicology",
    "100279": "(100279) adult nursing",
    "100280": "(100280) children's nursing",
    "100281": "(100281) community nursing",
    "100282": "(100282) critical care nursing",
    "100283": "(100283) dental nursing",
    "100284": "(100284) emergency nursing",
    "100285": "(100285) general practice nursing",
    "100286": "(100286) learning disabilities nursing",
    "100287": "(100287) mental health nursing",
    "100288": "(100288) midwifery",
    "100289": "(100289) neonatal nursing",
    "100290": "(100290) nursing",
    "100291": "(100291) older people nursing",
    "100292": "(100292) palliative care nursing",
    "100293": "(100293) school nursing",
    "100294": "(100294) theatre nursing",
    "100295": "(100295) health visiting",
    "100298": "(100298) ancient history",
    "100299": "(100299) archaeology",
    "100300": "(100300) classical studies",
    "100301": "(100301) economic history",
    "100302": "(100302) history",
    "100306": "(100306) history of art",
    "100307": "(100307) history of science",
    "100308": "(100308) local history",
    "100309": "(100309) medieval history",
    "100310": "(100310) modern history",
    "100311": "(100311) Scottish history",
    "100312": "(100312) social history",
    "100314": "(100314) humanities",
    "100316": "(100316) American studies",
    "100317": "(100317) British Sign Language studies",
    "100318": "(100318) English language",
    "100319": "(100319) English literature",
    "100320": "(100320) English studies",
    "100321": "(100321) French language",
    "100322": "(100322) French studies",
    "100323": "(100323) German language",
    "100324": "(100324) German studies",
    "100325": "(100325) Spanish studies",
    "100326": "(100326) Italian language",
    "100327": "(100327) Italian studies",
    "100328": "(100328) linguistics",
    "100329": "(100329) modern languages",
    "100330": "(100330) Russian languages",
    "100331": "(100331) Russian and East European studies",
    "100332": "(100332) Spanish language",
    "100333": "(100333) Welsh language",
    "100335": "(100335) Welsh studies",
    "100337": "(100337) philosophy",
    "100338": "(100338) philosophy of science",
    "100339": "(100339) religious studies",
    "100340": "(100340) theology",
    "100343": "(100343) applied biology",
    "100344": "(100344) biochemistry",
    "100345": "(100345) biological sciences",
    "100346": "(100346) biology",
    "100347": "(100347) ecology",
    "100348": "(100348) environmental biology",
    "100350": "(100350) human biology",
    "100351": "(100351) marine biology",
    "100352": "(100352) medical biochemistry",
    "100353": "(100353) microbiology",
    "100354": "(100354) molecular biology",
    "100355": "(100355) plant sciences",
    "100356": "(100356) zoology",
    "100358": "(100358) applied computing",
    "100359": "(100359) artificial intelligence",
    "100360": "(100360) business computing",
    "100361": "(100361) business information systems",
    "100362": "(100362) business information technology",
    "100363": "(100363) computer animation and visual effects",
    "100365": "(100365) computer networks",
    "100366": "(100366) computer science",
    "100367": "(100367) computing and information technology",
    "100368": "(100368) creative computing",
    "100369": "(100369) geographical information systems",
    "100370": "(100370) information management",
    "100371": "(100371) information systems",
    "100372": "(100372) information technology",
    "100373": "(100373) internet technologies",
    "100374": "(100374) software engineering",
    "100375": "(100375) web and multimedia design",
    "100376": "(100376) computer and information security",
    "100379": "(100379) climate science",
    "100380": "(100380) environmental geoscience",
    "100381": "(100381) environmental sciences",
    "100382": "(100382) meteorology",
    "100384": "(100384) archaeological sciences",
    "100385": "(100385) computer forensics",
    "100386": "(100386) forensic biology",
    "100387": "(100387) forensic psychology",
    "100388": "(100388) forensic science",
    "100390": "(100390) general science",
    "100391": "(100391) natural sciences",
    "100392": "(100392) applied science",
    "100394": "(100394) earth sciences",
    "100395": "(100395) geology",
    "100396": "(100396) geophysics",
    "100398": "(100398) palaeontology",
    "100400": "(100400) applied mathematics",
    "100401": "(100401) financial mathematics",
    "100402": "(100402) mathematical modelling",
    "100403": "(100403) mathematics",
    "100404": "(100404) operational research",
    "100405": "(100405) pure mathematics",
    "100406": "(100406) statistics",
    "100408": "(100408) environmental geography",
    "100409": "(100409) geography",
    "100410": "(100410) physical geography",
    "100413": "(100413) analytical chemistry",
    "100414": "(100414) astronomy",
    "100415": "(100415) astrophysics",
    "100416": "(100416) chemical physics",
    "100417": "(100417) chemistry",
    "100418": "(100418) marine sciences",
    "100419": "(100419) medical physics",
    "100420": "(100420) medicinal chemistry",
    "100421": "(100421) ocean sciences",
    "100422": "(100422) organic chemistry",
    "100423": "(100423) pharmaceutical chemistry",
    "100424": "(100424) physical sciences",
    "100425": "(100425) physics",
    "100426": "(100426) theoretical physics",
    "100427": "(100427) acoustics",
    "100428": "(100428) aerodynamics",
    "100429": "(100429) dynamics",
    "100430": "(100430) mechanics",
    "100431": "(100431) thermodynamics",
    "100433": "(100433) sport and exercise sciences",
    "100436": "(100436) anthropology",
    "100437": "(100437) social anthropology",
    "100439": "(100439) broadcast journalism",
    "100440": "(100440) digital media",
    "100441": "(100441) film production",
    "100442": "(100442) journalism",
    "100443": "(100443) media production",
    "100444": "(100444) media and communication studies",
    "100445": "(100445) multimedia journalism",
    "100449": "(100449) business economics",
    "100450": "(100450) economics",
    "100451": "(100451) financial economics",
    "100452": "(100452) international economics",
    "100454": "(100454) adult education",
    "100455": "(100455) childhood and youth studies",
    "100456": "(100456) childhood studies",
    "100457": "(100457) early childhood studies",
    "100459": "(100459) education studies",
    "100460": "(100460) further education",
    "100461": "(100461) higher education",
    "100462": "(100462) learning support",
    "100463": "(100463) early years education",
    "100464": "(100464) primary education",
    "100465": "(100465) secondary education",
    "100466": "(100466) youth and community work",
    "100468": "(100468) countryside management",
    "100469": "(100469) environmental management",
    "100471": "(100471) social sciences",
    "100473": "(100473) health studies",
    "100475": "(100475) sports therapy",
    "100476": "(100476) health and social care",
    "100478": "(100478) human geography",
    "100482": "(100482) business law",
    "100483": "(100483) criminal justice",
    "100484": "(100484) criminology",
    "100485": "(100485) law",
    "100486": "(100486) policing",
    "100488": "(100488) international development",
    "100489": "(100489) international politics",
    "100490": "(100490) international relations",
    "100491": "(100491) politics",
    "100493": "(100493) applied psychology",
    "100494": "(100494) clinical psychology",
    "100495": "(100495) counselling",
    "100496": "(100496) educational psychology",
    "100497": "(100497) psychology",
    "100498": "(100498) social psychology",
    "100499": "(100499) sport and exercise psychology",
    "100501": "(100501) social care",
    "100502": "(100502) social policy",
    "100503": "(100503) social work",
    "100505": "(100505) sociology",
    "100507": "(100507) adult education teaching",
    "100508": "(100508) post compulsory education and training",
    "100509": "(100509) higher education teaching",
    "100510": "(100510) early years teaching",
    "100511": "(100511) primary teaching",
    "100512": "(100512) secondary teaching",
    "100513": "(100513) teaching English as a foreign language",
    "100516": "(100516) agricultural sciences",
    "100517": "(100517) agriculture",
    "100518": "(100518) animal management",
    "100519": "(100519) equine studies",
    "100520": "(100520) forestry and arboriculture",
    "100522": "(100522) animal behaviour",
    "100523": "(100523) animal science",
    "100526": "(100526) food and beverage production",
    "100527": "(100527) food science",
    "100529": "(100529) horticulture",
    "100531": "(100531) veterinary medicine",
    "100532": "(100532) veterinary nursing",
    "100539": "(100539) broadcast engineering",
    "100540": "(100540) cellular pathology",
    "100544": "(100544) marine engineering",
    "100545": "(100545) corrosion technology",
    "100546": "(100546) digital circuit engineering",
    "100548": "(100548) engineering surveying",
    "100549": "(100549) environmental impact assessment",
    "100550": "(100550) gemmology",
    "100551": "(100551) geotechnical engineering",
    "100553": "(100553) integrated circuit design",
    "100560": "(100560) musical instrument manufacture",
    "100564": "(100564) aerospace propulsion systems",
    "100565": "(100565) public health engineering",
    "100566": "(100566) quarrying",
    "100568": "(100568) ship design",
    "100572": "(100572) tissue engineering and regenerative medicine",
    "100573": "(100573) water quality control",
    "100577": "(100577) fluid mechanics",
    "100579": "(100579) structural mechanics",
    "100580": "(100580) acoustics and vibration",
    "100581": "(100581) electrical power",
    "100583": "(100583) architectural design",
    "100584": "(100584) building technology",
    "100585": "(100585) conservation of buildings",
    "100586": "(100586) property development",
    "100587": "(100587) drawing",
    "100588": "(100588) landscape studies",
    "100589": "(100589) painting",
    "100590": "(100590) garden design",
    "100592": "(100592) sculpture",
    "100593": "(100593) rural planning",
    "100594": "(100594) urban studies",
    "100595": "(100595) printmaking",
    "100597": "(100597) applied economics",
    "100599": "(100599) fine art conservation",
    "100600": "(100600) agricultural economics",
    "100601": "(100601) economic policy",
    "100604": "(100604) econometrics",
    "100606": "(100606) economic systems",
    "100609": "(100609) democracy",
    "100610": "(100610) UK government/parliamentary studies",
    "100612": "(100612) European Union politics",
    "100616": "(100616) strategic studies",
    "100617": "(100617) war and peace studies",
    "100618": "(100618) comparative politics",
    "100619": "(100619) applied sociology",
    "100621": "(100621) gender studies",
    "100622": "(100622) women's studies",
    "100623": "(100623) men's studies",
    "100624": "(100624) ethnicity",
    "100625": "(100625) disability studies",
    "100626": "(100626) religion in society",
    "100627": "(100627) socio-economics",
    "100628": "(100628) social theory",
    "100629": "(100629) political sociology",
    "100630": "(100630) typography",
    "100631": "(100631) sociology of science and technology",
    "100632": "(100632) visual communication",
    "100633": "(100633) furniture design and making",
    "100636": "(100636) interactive and electronic design",
    "100637": "(100637) musicianship and performance studies",
    "100639": "(100639) instrumental or vocal performance",
    "100642": "(100642) music education and teaching",
    "100643": "(100643) music and arts management",
    "100644": "(100644) music marketing",
    "100645": "(100645) international social policy",
    "100647": "(100647) public policy",
    "100648": "(100648) health policy",
    "100649": "(100649) welfare policy",
    "100650": "(100650) conducting",
    "100651": "(100651) education policy",
    "100652": "(100652) security policy",
    "100653": "(100653) health and welfare",
    "100654": "(100654) child care",
    "100655": "(100655) community work",
    "100656": "(100656) jazz performance",
    "100657": "(100657) popular music performance",
    "100658": "(100658) careers guidance",
    "100659": "(100659) community justice",
    "100661": "(100661) historical performance practice",
    "100662": "(100662) probation/after-care",
    "100663": "(100663) physical and biological anthropology",
    "100664": "(100664) history of music",
    "100665": "(100665) economic geography",
    "100666": "(100666) urban geography",
    "100667": "(100667) musicology",
    "100668": "(100668) political geography",
    "100669": "(100669) transport geography",
    "100670": "(100670) environmental history",
    "100671": "(100671) cultural geography",
    "100674": "(100674) ethnomusicology and world music",
    "100676": "(100676) English law",
    "100677": "(100677) Northern Irish law",
    "100678": "(100678) Scots law",
    "100680": "(100680) European Union law",
    "100681": "(100681) public international law",
    "100683": "(100683) comparative law",
    "100684": "(100684) public law",
    "100685": "(100685) criminal law",
    "100686": "(100686) private law",
    "100689": "(100689) property law",
    "100690": "(100690) torts",
    "100691": "(100691) jurisprudence",
    "100692": "(100692) legal practice",
    "100693": "(100693) medical law",
    "100695": "(100695) music composition",
    "100697": "(100697) directing for theatre",
    "100698": "(100698) theatre studies",
    "100700": "(100700) theatre production",
    "100702": "(100702) technical theatre studies",
    "100703": "(100703) stage management",
    "100704": "(100704) technical stage management",
    "100705": "(100705) theatrical wardrobe design",
    "100706": "(100706) hair and make-up",
    "100707": "(100707) circus arts",
    "100708": "(100708) stage design",
    "100710": "(100710) community theatre",
    "100711": "(100711) choreography",
    "100712": "(100712) dance performance",
    "100714": "(100714) history of photography",
    "100716": "(100716) cinematography",
    "100717": "(100717) visual and audio effects",
    "100721": "(100721) metal work",
    "100724": "(100724) glass crafts",
    "100725": "(100725) silversmithing and goldsmithing",
    "100726": "(100726) clock and watchmaking",
    "100728": "(100728) surface decoration",
    "100729": "(100729) scriptwriting",
    "100730": "(100730) poetry writing",
    "100731": "(100731) professional writing",
    "100734": "(100734) computer architectures",
    "100735": "(100735) operating systems",
    "100736": "(100736) human-computer interaction",
    "100737": "(100737) multimedia computing science",
    "100738": "(100738) e-business",
    "100739": "(100739) beauty therapy",
    "100741": "(100741) high performance computing",
    "100743": "(100743) systems thinking",
    "100744": "(100744) dietetics",
    "100746": "(100746) clinical practice nursing",
    "100747": "(100747) medical nursing",
    "100748": "(100748) cardiology",
    "100749": "(100749) paramedic science",
    "100750": "(100750) physician associate studies",
    "100751": "(100751) information modelling",
    "100753": "(100753) systems analysis and design",
    "100754": "(100754) databases",
    "100755": "(100755) data management",
    "100756": "(100756) systems auditing",
    "100757": "(100757) intelligent systems",
    "100758": "(100758) British history",
    "100759": "(100759) Irish history",
    "100760": "(100760) Welsh history",
    "100761": "(100761) English history",
    "100762": "(100762) European history",
    "100763": "(100763) German history",
    "100764": "(100764) Italian history",
    "100765": "(100765) Iberian studies",
    "100766": "(100766) Russian history",
    "100767": "(100767) American history",
    "100768": "(100768) USA history",
    "100769": "(100769) Latin American history",
    "100771": "(100771) Chinese history",
    "100772": "(100772) South Asian history",
    "100773": "(100773) South East Asian history",
    "100774": "(100774) Byzantine studies",
    "100777": "(100777) world history",
    "100778": "(100778) international history",
    "100779": "(100779) family history",
    "100780": "(100780) history of religions",
    "100781": "(100781) intellectual history",
    "100782": "(100782) history of architecture",
    "100783": "(100783) history of design",
    "100784": "(100784) history of mathematics",
    "100785": "(100785) history of medicine",
    "100786": "(100786) military history",
    "100787": "(100787) Egyptology",
    "100791": "(100791) mental philosophy",
    "100792": "(100792) social philosophy",
    "100793": "(100793) ethics",
    "100794": "(100794) theology and religious studies",
    "100795": "(100795) Christian studies",
    "100796": "(100796) Islamic studies",
    "100797": "(100797) Judaism",
    "100798": "(100798) buddhism",
    "100799": "(100799) divinity",
    "100800": "(100800) religious writings",
    "100801": "(100801) biblical studies",
    "100802": "(100802) pastoral studies",
    "100803": "(100803) comparative religious studies",
    "100805": "(100805) heritage studies",
    "100807": "(100807) heritage management",
    "100808": "(100808) European business studies",
    "100810": "(100810) strategic management",
    "100811": "(100811) creative management",
    "100812": "(100812) project management",
    "100813": "(100813) change management",
    "100814": "(100814) organisational development",
    "100815": "(100815) institutional management",
    "100817": "(100817) management and organisation of education",
    "100819": "(100819) land management",
    "100820": "(100820) property management",
    "100821": "(100821) requirements engineering",
    "100822": "(100822) cell biology",
    "100823": "(100823) emergency and disaster management",
    "100825": "(100825) property valuation and auctioneering",
    "100826": "(100826) parasitology",
    "100827": "(100827) banking",
    "100828": "(100828) investment",
    "100829": "(100829) behavioural biology",
    "100830": "(100830) insurance",
    "100831": "(100831) taxation",
    "100832": "(100832) financial management",
    "100834": "(100834) developmental biology",
    "100835": "(100835) financial risk",
    "100836": "(100836) management accountancy",
    "100838": "(100838) book-keeping",
    "100840": "(100840) audit management",
    "100841": "(100841) popular music",
    "100842": "(100842) film music and screen music",
    "100843": "(100843) jazz",
    "100845": "(100845) financial reporting",
    "100846": "(100846) market research",
    "100847": "(100847) reproductive biology",
    "100848": "(100848) aquatic biology",
    "100849": "(100849) freshwater biology",
    "100850": "(100850) population biology",
    "100851": "(100851) sales management",
    "100853": "(100853) international marketing",
    "100854": "(100854) community music",
    "100855": "(100855) promotion and advertising",
    "100856": "(100856) corporate image",
    "100858": "(100858) evolution",
    "100861": "(100861) staff development",
    "100862": "(100862) sonic arts",
    "100864": "(100864) ecosystem ecology and land use",
    "100865": "(100865) biometry",
    "100866": "(100866) health and safety management",
    "100867": "(100867) electronic music",
    "100868": "(100868) office administration",
    "100869": "(100869) bioinformatics",
    "100870": "(100870) jazz composition",
    "100872": "(100872) mycology",
    "100873": "(100873) plant cell science",
    "100874": "(100874) plant pathology",
    "100875": "(100875) tourism",
    "100880": "(100880) applied zoology",
    "100881": "(100881) cell zoology",
    "100882": "(100882) entomology",
    "100883": "(100883) marine zoology",
    "100884": "(100884) pest management",
    "100885": "(100885) ballet",
    "100886": "(100886) contemporary dance",
    "100887": "(100887) moving image techniques",
    "100888": "(100888) film directing",
    "100890": "(100890) film and sound recording",
    "100891": "(100891) hospitality",
    "100893": "(100893) recreation and leisure studies",
    "100894": "(100894) spa management",
    "100895": "(100895) crafts",
    "100898": "(100898) human genetics",
    "100899": "(100899) medical genetics",
    "100900": "(100900) molecular genetics",
    "100901": "(100901) genomics",
    "100902": "(100902) population genetics",
    "100906": "(100906) applied microbiology",
    "100907": "(100907) medical microbiology",
    "100908": "(100908) veterinary microbiology",
    "100909": "(100909) bacteriology",
    "100910": "(100910) virology",
    "100911": "(100911) immunology",
    "100912": "(100912) blood sciences",
    "100913": "(100913) librarianship",
    "100914": "(100914) curatorial studies",
    "100915": "(100915) archives and records management",
    "100916": "(100916) information services",
    "100918": "(100918) museum studies",
    "100919": "(100919) publicity studies",
    "100920": "(100920) television studies",
    "100921": "(100921) radio studies",
    "100922": "(100922) paper-based media studies",
    "100923": "(100923) television production",
    "100924": "(100924) radio production",
    "100925": "(100925) publishing",
    "100926": "(100926) electronic publishing",
    "100927": "(100927) online publishing",
    "100932": "(100932) plant biochemistry",
    "100936": "(100936) animal health",
    "100937": "(100937) animal physiology",
    "100938": "(100938) veterinary pathology",
    "100939": "(100939) veterinary pharmacology",
    "100940": "(100940) animal nutrition",
    "100941": "(100941) veterinary pharmacy",
    "100942": "(100942) veterinary public health",
    "100945": "(100945) crop protection",
    "100946": "(100946) crop nutrition",
    "100947": "(100947) crop production",
    "100948": "(100948) biomolecular science",
    "100949": "(100949) biophysical science",
    "100950": "(100950) occupational psychology",
    "100952": "(100952) developmental psychology",
    "100953": "(100953) child psychology",
    "100954": "(100954) business psychology",
    "100956": "(100956) programming",
    "100958": "(100958) psychology of ageing",
    "100959": "(100959) research methods in psychology",
    "100960": "(100960) object-oriented programming",
    "100961": "(100961) natural language processing",
    "100962": "(100962) research skills",
    "100963": "(100963) knowledge and information systems",
    "100966": "(100966) neural computing",
    "100968": "(100968) computer vision",
    "100970": "(100970) applied linguistics",
    "100971": "(100971) phonetics and phonology",
    "100972": "(100972) phonetics",
    "100973": "(100973) phonology",
    "100974": "(100974) livestock",
    "100976": "(100976) aquaculture",
    "100977": "(100977) rural estate management",
    "100978": "(100978) farm management",
    "100979": "(100979) game keeping management",
    "100985": "(100985) health psychology",
    "100986": "(100986) water resource management",
    "100989": "(100989) cognitive modelling",
    "100992": "(100992) machine learning",
    "100993": "(100993) cognitive psychology",
    "100994": "(100994) health informatics",
    "100998": "(100998) sustainable agriculture and landscape development",
    "101001": "(101001) international agriculture",
    "101003": "(101003) psychology of religion",
    "101006": "(101006) agricultural technology",
    "101010": "(101010) agricultural machinery",
    "101016": "(101016) sociolinguistics",
    "101017": "(101017) food and beverage studies",
    "101018": "(101018) food safety",
    "101019": "(101019) computer games graphics",
    "101020": "(101020) computer games programming",
    "101021": "(101021) baking technology management",
    "101022": "(101022) brewing",
    "101025": "(101025) agricultural botany",
    "101027": "(101027) numerical analysis",
    "101028": "(101028) engineering and industrial mathematics",
    "101029": "(101029) computational mathematics",
    "101030": "(101030) applied statistics",
    "101031": "(101031) medical statistics",
    "101032": "(101032) probability",
    "101033": "(101033) stochastic processes",
    "101034": "(101034) statistical modelling",
    "101035": "(101035) psycholinguistics",
    "101037": "(101037) comparative literary studies",
    "101038": "(101038) applied chemistry",
    "101040": "(101040) risk management",
    "101041": "(101041) industrial chemistry",
    "101042": "(101042) colour chemistry",
    "101043": "(101043) inorganic chemistry",
    "101044": "(101044) crystallography",
    "101045": "(101045) environmental chemistry",
    "101046": "(101046) marine chemistry",
    "101048": "(101048) environmental risk",
    "101049": "(101049) health risk",
    "101050": "(101050) physical chemistry",
    "101053": "(101053) polymer chemistry",
    "101054": "(101054) oil and gas chemistry",
    "101056": "(101056) remote sensing",
    "101058": "(101058) mapping science",
    "101060": "(101060) applied physics",
    "101061": "(101061) engineering physics",
    "101064": "(101064) geomorphology",
    "101065": "(101065) maritime geography",
    "101067": "(101067) soil science",
    "101068": "(101068) atmospheric physics",
    "101070": "(101070) climate change",
    "101071": "(101071) computational physics",
    "101072": "(101072) pollution control",
    "101073": "(101073) hydrography",
    "101074": "(101074) radiation physics",
    "101075": "(101075) photonics and optical physics",
    "101076": "(101076) laser physics",
    "101077": "(101077) nuclear and particle physics",
    "101078": "(101078) applied environmental sciences",
    "101079": "(101079) hydrology",
    "101081": "(101081) volcanology",
    "101082": "(101082) geological hazards",
    "101083": "(101083) geochemistry",
    "101084": "(101084) exploration geophysics",
    "101085": "(101085) specialist teaching",
    "101086": "(101086) geological oceanography",
    "101087": "(101087) special needs teaching",
    "101088": "(101088) research and study skills in education",
    "101089": "(101089) hydrogeology",
    "101090": "(101090) study skills",
    "101091": "(101091) quaternary studies",
    "101093": "(101093) exploration geology",
    "101094": "(101094) English literature 1200 - 1700",
    "101095": "(101095) English literature 1700 - 1900",
    "101102": "(101102) space science",
    "101103": "(101103) planetary science",
    "101104": "(101104) applied geology",
    "101105": "(101105) petroleum geology",
    "101106": "(101106) engineering geology",
    "101107": "(101107) Shakespeare studies",
    "101108": "(101108) colonial and post-colonial literature",
    "101109": "(101109) English as a second language",
    "101111": "(101111) Scottish literature",
    "101112": "(101112) ancient Middle Eastern languages",
    "101113": "(101113) ancient Egyptian studies",
    "101114": "(101114) classical Arabic",
    "101115": "(101115) Sanskrit studies",
    "101117": "(101117) ancient Hebrew language",
    "101118": "(101118) Celtic studies",
    "101120": "(101120) Gaelic language",
    "101121": "(101121) Irish language",
    "101124": "(101124) Latin studies",
    "101125": "(101125) Latin literature",
    "101126": "(101126) classical Greek studies",
    "101129": "(101129) classical reception",
    "101130": "(101130) translation studies",
    "101132": "(101132) French literature",
    "101133": "(101133) French society and culture",
    "101134": "(101134) German literature",
    "101135": "(101135) German society and culture",
    "101136": "(101136) Italian society and culture",
    "101138": "(101138) Spanish society and culture",
    "101139": "(101139) Spanish literature",
    "101141": "(101141) Portuguese studies",
    "101142": "(101142) Portuguese language",
    "101143": "(101143) Brazilian studies",
    "101144": "(101144) Portuguese society and culture",
    "101145": "(101145) Scandinavian studies",
    "101148": "(101148) Swedish language",
    "101149": "(101149) Norwegian language",
    "101150": "(101150) Finnish language",
    "101151": "(101151) Russian studies",
    "101152": "(101152) Polish studies",
    "101153": "(101153) Polish language",
    "101155": "(101155) Czech language",
    "101157": "(101157) Russian literature",
    "101158": "(101158) Russian and East European society and culture",
    "101159": "(101159) European studies",
    "101161": "(101161) Dutch studies",
    "101163": "(101163) Welsh literature",
    "101164": "(101164) Chinese studies",
    "101165": "(101165) Chinese languages",
    "101167": "(101167) Chinese society and culture studies",
    "101168": "(101168) Japanese studies",
    "101169": "(101169) Japanese languages",
    "101171": "(101171) Japanese society and culture studies",
    "101172": "(101172) South Asian studies",
    "101179": "(101179) Indian society and culture studies",
    "101180": "(101180) Asian studies",
    "101184": "(101184) African studies",
    "101185": "(101185) African languages",
    "101189": "(101189) African society and culture studies",
    "101190": "(101190) modern Middle Eastern studies",
    "101191": "(101191) modern Middle Eastern languages",
    "101192": "(101192) Arabic languages",
    "101193": "(101193) Persian languages",
    "101194": "(101194) Israeli studies",
    "101195": "(101195) Turkish studies",
    "101197": "(101197) modern Middle Eastern society and culture studies",
    "101198": "(101198) Arab society and culture studies",
    "101199": "(101199) Latin American studies",
    "101202": "(101202) Latin American society and culture studies",
    "101203": "(101203) North American literature studies",
    "101204": "(101204) North American society and culture studies",
    "101205": "(101205) Canadian studies",
    "101207": "(101207) Caribbean studies",
    "101210": "(101210) biomaterials",
    "101212": "(101212) Korean studies",
    "101214": "(101214) cinematics",
    "101215": "(101215) food marketing",
    "101216": "(101216) bioelectronics",
    "101217": "(101217) composite materials",
    "101218": "(101218) forensic anthropology",
    "101219": "(101219) forensic archaeology",
    "101220": "(101220) veterinary epidemiology",
    "101221": "(101221) enterprise and entrepreneurship",
    "101222": "(101222) crime scene investigation",
    "101223": "(101223) condensed matter physics",
    "101233": "(101233) cultural studies",
    "101234": "(101234) nanotechnology",
    "101241": "(101241) music therapy",
    "101243": "(101243) bioengineering",
    "101246": "(101246) professional practice in education",
    "101248": "(101248) French history",
    "101258": "(101258) Thai language",
    "101261": "(101261) maritime archaeology",
    "101267": "(101267) computer games",
    "101268": "(101268) computer games design",
    "101269": "(101269) modern Hebrew language",
    "101271": "(101271) East Asian studies",
    "101273": "(101273) combined studies",
    "101274": "(101274) general studies",
    "101275": "(101275) negotiated studies",
    "101276": "(101276) work placement experience (personal learning)",
    "101277": "(101277) work-based learning",
    "101278": "(101278) employability skills (personal learning)",
    "101279": "(101279) advice and guidance (personal learning)",
    "101288": "(101288) international studies",
    "101289": "(101289) rehabilitation studies",
    "101290": "(101290) neurological rehabilitation",
    "101291": "(101291) cardiovascular rehabilitation",
    "101294": "(101294) coaching psychology",
    "101300": "(101300) quantum theory and applications",
    "101307": "(101307) applied social science",
    "101308": "(101308) facilities management",
    "101309": "(101309) obstetrics and gynaecology",
    "101312": "(101312) Czech studies",
    "101315": "(101315) Irish studies",
    "101316": "(101316) interior design and architecture",
    "101317": "(101317) environmental and public health",
    "101318": "(101318) biodiversity conservation",
    "101319": "(101319) exercise for health",
    "101320": "(101320) art psychotherapy",
    "101322": "(101322) mentorship",
    "101323": "(101323) dispute resolution",
    "101324": "(101324) orthopaedics",
    "101325": "(101325) paediatrics",
    "101326": "(101326) gerontology",
    "101327": "(101327) oncology",
    "101329": "(101329) dementia studies",
    "101330": "(101330) ultrasound",
    "101331": "(101331) gastroenterology",
    "101332": "(101332) drug and alcohol studies",
    "101333": "(101333) epilepsy care",
    "101334": "(101334) allergy",
    "101335": "(101335) epidemiology",
    "101336": "(101336) anaesthesia",
    "101337": "(101337) endocrinology",
    "101338": "(101338) diabetes",
    "101339": "(101339) dermatology",
    "101340": "(101340) hypnotherapy",
    "101341": "(101341) psychology of communication",
    "101342": "(101342) psychology of memory and learning",
    "101343": "(101343) transpersonal psychology",
    "101344": "(101344) psychobiology",
    "101345": "(101345) evolutionary psychology",
    "101348": "(101348) exotic plants and crops",
    "101351": "(101351) atmosphere-ocean interactions",
    "101352": "(101352) biogeography",
    "101353": "(101353) electrical power generation",
    "101354": "(101354) electrical power distribution",
    "101355": "(101355) cybernetics",
    "101356": "(101356) paper technology",
    "101357": "(101357) nationalism",
    "101358": "(101358) development in Africa",
    "101360": "(101360) African history",
    "101361": "(101361) creative arts and design",
    "101363": "(101363) psychology of music",
    "101366": "(101366) Swahili and other Bantu languages",
    "101368": "(101368) Indonesian language",
    "101372": "(101372) South East Asian studies",
    "101373": "(101373) hair and beauty sciences",
    "101376": "(101376) applied botany",
    "101377": "(101377) transcriptomics",
    "101378": "(101378) genetic engineering",
    "101379": "(101379) sport technology",
    "101380": "(101380) metabolic biochemistry",
    "101381": "(101381) cognitive neuroscience",
    "101382": "(101382) affective neuroscience",
    "101383": "(101383) psychometrics",
    "101384": "(101384) pre-clinical veterinary medicine",
    "101387": "(101387) meat science",
    "101388": "(101388) cereal science",
    "101389": "(101389) organometallic chemistry",
    "101390": "(101390) marine physics",
    "101391": "(101391) electromagnetism",
    "101394": "(101394) glaciology and cryospheric systems",
    "101396": "(101396) solid mechanics",
    "101397": "(101397) turbine technology",
    "101398": "(101398) rail vehicle engineering",
    "101399": "(101399) analogue circuit engineering",
    "101400": "(101400) parallel computing",
    "101401": "(101401) microeconomics",
    "101402": "(101402) macroeconomics",
    "101403": "(101403) feminism",
    "101404": "(101404) anarchism",
    "101405": "(101405) victimology",
    "101406": "(101406) transport policy",
    "101407": "(101407) agricultural geography",
    "101408": "(101408) human demography",
    "101412": "(101412) Scots language",
    "101413": "(101413) Irish language literature",
    "101415": "(101415) Akkadian language",
    "101416": "(101416) Sumerian language",
    "101420": "(101420) Latin language",
    "101422": "(101422) classical church Greek",
    "101423": "(101423) classical Greek literature",
    "101424": "(101424) Danish language",
    "101427": "(101427) Hungarian language",
    "101428": "(101428) south Slavonic languages",
    "101429": "(101429) Ukrainian language",
    "101430": "(101430) Indian literature studies",
    "101431": "(101431) Turkish languages",
    "101432": "(101432) Arabic literature",
    "101433": "(101433) Persian literature studies",
    "101434": "(101434) Turkish literature",
    "101435": "(101435) oral history",
    "101436": "(101436) crime history",
    "101437": "(101437) Stone Age",
    "101440": "(101440) classical art and archaeology",
    "101441": "(101441) metaphysics",
    "101442": "(101442) epistemology",
    "101443": "(101443) scholastic philosophy",
    "101444": "(101444) hinduism",
    "101445": "(101445) the Qur'an and Islamic texts",
    "101447": "(101447) folk music",
    "101448": "(101448) opera",
    "101449": "(101449) music theory and analysis",
    "101450": "(101450) applied music and musicianship",
    "101451": "(101451) popular music composition",
    "101452": "(101452) body awareness",
    "101453": "(101453) dance and culture",
    "101454": "(101454) community dance",
    "101455": "(101455) stone crafts",
    "101457": "(101457) community ecology",
    "101458": "(101458) population ecology",
    "101459": "(101459) ecotoxicology",
    "101461": "(101461) veterinary biochemistry",
    "101462": "(101462) quantitative psychology",
    "101463": "(101463) qualitative psychology",
    "101464": "(101464) psychopharmacology",
    "101465": "(101465) sociology of law",
    "101468": "(101468) John Donne studies",
    "101484": "(101484) Shelley studies",
    "101490": "(101490) Robert Burns studies",
    "101492": "(101492) Bob Dylan studies",
    "101497": "(101497) Gaelic literature",
    "101499": "(101499) Russian society and culture",
    "101500": "(101500) Polish society and culture",
    "101503": "(101503) Persian society and culture studies",
    "101504": "(101504) Turkish society and culture studies",
    "101505": "(101505) carpentry and joinery",
    "101506": "(101506) liberalism",
    "101508": "(101508) socialism",
    "101509": "(101509) fascism",
    "101510": "(101510) environmentalism",
    "101511": "(101511) ophthalmic dispensing",
    "200001": "(200001) apportioned teacher training",
};



export const cah1Mapping: DomainMappingType ={
    "(01) Medicine and dentistry": "(01) Medicine and dentistry",
    "(02) Subjects allied to medicine": "(02) Subjects allied to medicine",
    "(03) Biological and sport sciences": "(03) Biological and sport sciences",
    "(04) Psychology": "(04) Psychology",
    "(05) Veterinary sciences": "(05) Veterinary sciences",
    "(06) Agriculture, food and related studies": "(06) Agriculture, food and related studies",
    "(07) Physical sciences": "(07) Physical sciences",
    "(09) Mathematical sciences": "(09) Mathematical sciences",
    "(10) Engineering and technology": "(10) Engineering and technology",
    "(11) Computing": "(11) Computing",
    "(13) Architecture, building and planning": "(13) Architecture, building and planning",
    "(15) Social sciences": "(15) Social sciences",
    "(16) Law": "(16) Law",
    "(17) Business and management": "(17) Business and management",
    "(19) Language and area studies": "(19) Language and area studies",
    "(20) Historical, philosophical and religious studies": "(20) Historical, philosophical and religious studies",
    "(22) Education and teaching": "(22) Education and teaching",
    "(23) Combined and general studies": "(23) Combined and general studies",
    "(24) Media, journalism and communications": "(24) Media, journalism and communications",
    "(25) Design, and creative and performing arts": "(25) Design, and creative and performing arts",
    "(26) Geography, earth and environmental studies (natural sciences)": "(26) Geography, earth and environmental studies (natural sciences)",
    "(26) Geography, earth and environmental studies (social sciences)": "(26) Geography, earth and environmental studies (social sciences)",
    "01 Medicine and dentistry": "(01) Medicine and dentistry",
    "02 Subjects allied to medicine": "(02) Subjects allied to medicine",
    "03 Biological and sport sciences": "(03) Biological and sport sciences",
    "04 Psychology": "(04) Psychology",
    "05 Veterinary sciences": "(05) Veterinary sciences",
    "06 Agriculture, food and related studies": "(06) Agriculture, food and related studies",
    "07 Physical sciences": "(07) Physical sciences",
    "09 Mathematical sciences": "(09) Mathematical sciences",
    "10 Engineering and technology": "(10) Engineering and technology",
    "11 Computing": "(11) Computing",
    "13 Architecture, building and planning": "(13) Architecture, building and planning",
    "15 Social sciences": "(15) Social sciences",
    "16 Law": "(16) Law",
    "17 Business and management": "(17) Business and management",
    "19 Language and area studies": "(19) Language and area studies",
    "20 Historical, philosophical and religious studies": "(20) Historical, philosophical and religious studies",
    "22 Education and teaching": "(22) Education and teaching",
    "23 Combined and general studies": "(23) Combined and general studies",
    "24 Media, journalism and communications": "(24) Media, journalism and communications",
    "25 Design, and creative and performing arts": "(25) Design, and creative and performing arts",
    "26 Geography, earth and environmental studies natural sciences": "(26) Geography, earth and environmental studies (natural sciences)",
    "26 Geography, earth and environmental studies social sciences": "(26) Geography, earth and environmental studies (social sciences)",
    "(01)": "(01) Medicine and dentistry",
    "(02)": "(02) Subjects allied to medicine",
    "(03)": "(03) Biological and sport sciences",
    "(04)": "(04) Psychology",
    "(05)": "(05) Veterinary sciences",
    "(06)": "(06) Agriculture, food and related studies",
    "(07)": "(07) Physical sciences",
    "(09)": "(09) Mathematical sciences",
    "(10)": "(10) Engineering and technology",
    "(11)": "(11) Computing",
    "(13)": "(13) Architecture, building and planning",
    "(15)": "(15) Social sciences",
    "(16)": "(16) Law",
    "(17)": "(17) Business and management",
    "(19)": "(19) Language and area studies",
    "(20)": "(20) Historical, philosophical and religious studies",
    "(22)": "(22) Education and teaching",
    "(23)": "(23) Combined and general studies",
    "(24)": "(24) Media, journalism and communications",
    "(25)": "(25) Design, and creative and performing arts",
    "(26)": "(26) Geography, earth and environmental studies (social sciences)",
    "01": "(01) Medicine and dentistry",
    "02": "(02) Subjects allied to medicine",
    "03": "(03) Biological and sport sciences",
    "04": "(04) Psychology",
    "05": "(05) Veterinary sciences",
    "06": "(06) Agriculture, food and related studies",
    "07": "(07) Physical sciences",
    "09": "(09) Mathematical sciences",
    "10": "(10) Engineering and technology",
    "11": "(11) Computing",
    "13": "(13) Architecture, building and planning",
    "15": "(15) Social sciences",
    "16": "(16) Law",
    "17": "(17) Business and management",
    "19": "(19) Language and area studies",
    "20": "(20) Historical, philosophical and religious studies",
    "22": "(22) Education and teaching",
    "23": "(23) Combined and general studies",
    "24": "(24) Media, journalism and communications",
    "25": "(25) Design, and creative and performing arts",
    "26": "(26) Geography, earth and environmental studies (social sciences)",
};

export const cah2Mapping: DomainMappingType ={
    "(01-01) Medicine and dentistry": "(01-01) Medicine and dentistry",
    "(02-02) Pharmacology, toxicology and pharmacy": "(02-02) Pharmacology, toxicology and pharmacy",
    "(02-04) Nursing and midwifery": "(02-04) Nursing and midwifery",
    "(02-05) Medical sciences": "(02-05) Medical sciences",
    "(02-06) Allied health": "(02-06) Allied health",
    "(03-01) Biosciences": "(03-01) Biosciences",
    "(03-02) Sport and exercise sciences": "(03-02) Sport and exercise sciences",
    "(04-01) Psychology": "(04-01) Psychology",
    "(05-01) Veterinary sciences": "(05-01) Veterinary sciences",
    "(06-01) Agriculture, food and related studies": "(06-01) Agriculture, food and related studies",
    "(07-01) Physics and astronomy": "(07-01) Physics and astronomy",
    "(07-02) Chemistry": "(07-02) Chemistry",
    "(07-04) General, applied and forensic sciences": "(07-04) General, applied and forensic sciences",
    "(09-01) Mathematical sciences": "(09-01) Mathematical sciences",
    "(10-01) Engineering": "(10-01) Engineering",
    "(10-03) Materials and technology": "(10-03) Materials and technology",
    "(11-01) Computing": "(11-01) Computing",
    "(13-01) Architecture, building and planning": "(13-01) Architecture, building and planning",
    "(15-01) Sociology, social policy and anthropology": "(15-01) Sociology, social policy and anthropology",
    "(15-02) Economics": "(15-02) Economics",
    "(15-03) Politics": "(15-03) Politics",
    "(15-04) Health and social care": "(15-04) Health and social care",
    "(16-01) Law": "(16-01) Law",
    "(17-01) Business and management": "(17-01) Business and management",
    "(19-01) English studies": "(19-01) English studies",
    "(19-02) Celtic studies": "(19-02) Celtic studies",
    "(19-04) Languages and area studies": "(19-04) Languages and area studies",
    "(20-01) History and archaeology": "(20-01) History and archaeology",
    "(20-02) Philosophy and religious studies": "(20-02) Philosophy and religious studies",
    "(22-01) Education and teaching": "(22-01) Education and teaching",
    "(23-01) Combined and general studies": "(23-01) Combined and general studies",
    "(24-01) Media, journalism and communications": "(24-01) Media, journalism and communications",
    "(25-01) Creative arts and design": "(25-01) Creative arts and design",
    "(25-02) Performing arts": "(25-02) Performing arts",
    "(26-01) Geography, earth And environmental studies (natural sciences)": "(26-01) Geography, earth And environmental studies (natural sciences)",
    "(26-01) Geography, earth and environmental studies (social sciences)": "(26-01) Geography, earth and environmental studies (social sciences)",
    "01-01 Medicine and dentistry": "(01-01) Medicine and dentistry",
    "02-02 Pharmacology, toxicology and pharmacy": "(02-02) Pharmacology, toxicology and pharmacy",
    "02-04 Nursing and midwifery": "(02-04) Nursing and midwifery",
    "02-05 Medical sciences": "(02-05) Medical sciences",
    "02-06 Allied health": "(02-06) Allied health",
    "03-01 Biosciences": "(03-01) Biosciences",
    "03-02 Sport and exercise sciences": "(03-02) Sport and exercise sciences",
    "04-01 Psychology": "(04-01) Psychology",
    "05-01 Veterinary sciences": "(05-01) Veterinary sciences",
    "06-01 Agriculture, food and related studies": "(06-01) Agriculture, food and related studies",
    "07-01 Physics and astronomy": "(07-01) Physics and astronomy",
    "07-02 Chemistry": "(07-02) Chemistry",
    "07-04 General, applied and forensic sciences": "(07-04) General, applied and forensic sciences",
    "09-01 Mathematical sciences": "(09-01) Mathematical sciences",
    "10-01 Engineering": "(10-01) Engineering",
    "10-03 Materials and technology": "(10-03) Materials and technology",
    "11-01 Computing": "(11-01) Computing",
    "13-01 Architecture, building and planning": "(13-01) Architecture, building and planning",
    "15-01 Sociology, social policy and anthropology": "(15-01) Sociology, social policy and anthropology",
    "15-02 Economics": "(15-02) Economics",
    "15-03 Politics": "(15-03) Politics",
    "15-04 Health and social care": "(15-04) Health and social care",
    "16-01 Law": "(16-01) Law",
    "17-01 Business and management": "(17-01) Business and management",
    "19-01 English studies": "(19-01) English studies",
    "19-02 Celtic studies": "(19-02) Celtic studies",
    "19-04 Languages and area studies": "(19-04) Languages and area studies",
    "20-01 History and archaeology": "(20-01) History and archaeology",
    "20-02 Philosophy and religious studies": "(20-02) Philosophy and religious studies",
    "22-01 Education and teaching": "(22-01) Education and teaching",
    "23-01 Combined and general studies": "(23-01) Combined and general studies",
    "24-01 Media, journalism and communications": "(24-01) Media, journalism and communications",
    "25-01 Creative arts and design": "(25-01) Creative arts and design",
    "25-02 Performing arts": "(25-02) Performing arts",
    "26-01 Geography, earth And environmental studies natural sciences": "(26-01) Geography, earth And environmental studies (natural sciences)",
    "26-01 Geography, earth and environmental studies social sciences": "(26-01) Geography, earth and environmental studies (social sciences)",
    "(01-01)": "(01-01) Medicine and dentistry",
    "(02-02)": "(02-02) Pharmacology, toxicology and pharmacy",
    "(02-04)": "(02-04) Nursing and midwifery",
    "(02-05)": "(02-05) Medical sciences",
    "(02-06)": "(02-06) Allied health",
    "(03-01)": "(03-01) Biosciences",
    "(03-02)": "(03-02) Sport and exercise sciences",
    "(04-01)": "(04-01) Psychology",
    "(05-01)": "(05-01) Veterinary sciences",
    "(06-01)": "(06-01) Agriculture, food and related studies",
    "(07-01)": "(07-01) Physics and astronomy",
    "(07-02)": "(07-02) Chemistry",
    "(07-04)": "(07-04) General, applied and forensic sciences",
    "(09-01)": "(09-01) Mathematical sciences",
    "(10-01)": "(10-01) Engineering",
    "(10-03)": "(10-03) Materials and technology",
    "(11-01)": "(11-01) Computing",
    "(13-01)": "(13-01) Architecture, building and planning",
    "(15-01)": "(15-01) Sociology, social policy and anthropology",
    "(15-02)": "(15-02) Economics",
    "(15-03)": "(15-03) Politics",
    "(15-04)": "(15-04) Health and social care",
    "(16-01)": "(16-01) Law",
    "(17-01)": "(17-01) Business and management",
    "(19-01)": "(19-01) English studies",
    "(19-02)": "(19-02) Celtic studies",
    "(19-04)": "(19-04) Languages and area studies",
    "(20-01)": "(20-01) History and archaeology",
    "(20-02)": "(20-02) Philosophy and religious studies",
    "(22-01)": "(22-01) Education and teaching",
    "(23-01)": "(23-01) Combined and general studies",
    "(24-01)": "(24-01) Media, journalism and communications",
    "(25-01)": "(25-01) Creative arts and design",
    "(25-02)": "(25-02) Performing arts",
    "(26-01)": "(26-01) Geography, earth and environmental studies (social sciences)",
    "01-01": "(01-01) Medicine and dentistry",
    "02-02": "(02-02) Pharmacology, toxicology and pharmacy",
    "02-04": "(02-04) Nursing and midwifery",
    "02-05": "(02-05) Medical sciences",
    "02-06": "(02-06) Allied health",
    "03-01": "(03-01) Biosciences",
    "03-02": "(03-02) Sport and exercise sciences",
    "04-01": "(04-01) Psychology",
    "05-01": "(05-01) Veterinary sciences",
    "06-01": "(06-01) Agriculture, food and related studies",
    "07-01": "(07-01) Physics and astronomy",
    "07-02": "(07-02) Chemistry",
    "07-04": "(07-04) General, applied and forensic sciences",
    "09-01": "(09-01) Mathematical sciences",
    "10-01": "(10-01) Engineering",
    "10-03": "(10-03) Materials and technology",
    "11-01": "(11-01) Computing",
    "13-01": "(13-01) Architecture, building and planning",
    "15-01": "(15-01) Sociology, social policy and anthropology",
    "15-02": "(15-02) Economics",
    "15-03": "(15-03) Politics",
    "15-04": "(15-04) Health and social care",
    "16-01": "(16-01) Law",
    "17-01": "(17-01) Business and management",
    "19-01": "(19-01) English studies",
    "19-02": "(19-02) Celtic studies",
    "19-04": "(19-04) Languages and area studies",
    "20-01": "(20-01) History and archaeology",
    "20-02": "(20-02) Philosophy and religious studies",
    "22-01": "(22-01) Education and teaching",
    "23-01": "(23-01) Combined and general studies",
    "24-01": "(24-01) Media, journalism and communications",
    "25-01": "(25-01) Creative arts and design",
    "25-02": "(25-02) Performing arts",
    "26-01": "(26-01) Geography, earth and environmental studies (social sciences)",
};


export const cah3Mapping: DomainMappingType =
{
    "(01-01-01) Medical sciences (non-specific)": "(01-01-01) Medical sciences (non-specific)",
    "(01-01-02) Medicine (non-specific)": "(01-01-02) Medicine (non-specific)",
    "(01-01-03) Medicine by specialism": "(01-01-03) Medicine by specialism",
    "(01-01-04) Dentistry": "(01-01-04) Dentistry",
    "(02-02-01) Pharmacology": "(02-02-01) Pharmacology",
    "(02-02-02) Toxicology": "(02-02-02) Toxicology",
    "(02-02-03) Pharmacy": "(02-02-03) Pharmacy",
    "(02-04-01) Nursing (non-specific)": "(02-04-01) Nursing (non-specific)",
    "(02-04-02) Adult nursing": "(02-04-02) Adult nursing",
    "(02-04-03) Community nursing": "(02-04-03) Community nursing",
    "(02-04-04) Midwifery": "(02-04-04) Midwifery",
    "(02-04-05) Children's nursing": "(02-04-05) Children's nursing",
    "(02-04-06) Dental nursing": "(02-04-06) Dental nursing",
    "(02-04-07) Mental health nursing": "(02-04-07) Mental health nursing",
    "(02-04-08) Learning disabilities nursing": "(02-04-08) Learning disabilities nursing",
    "(02-04-09) Others in nursing": "(02-04-09) Others in nursing",
    "(02-05-01) Medical technology": "(02-05-01) Medical technology",
    "(02-05-02) Healthcare science (non-specific)": "(02-05-02) Healthcare science (non-specific)",
    "(02-05-03) Biomedical sciences (non-specific)": "(02-05-03) Biomedical sciences (non-specific)",
    "(02-05-04) Anatomy, physiology and pathology": "(02-05-04) Anatomy, physiology and pathology",
    "(02-06-01) Health sciences (non-specific)": "(02-06-01) Health sciences (non-specific)",
    "(02-06-02) Nutrition and dietetics": "(02-06-02) Nutrition and dietetics",
    "(02-06-03) Ophthalmics": "(02-06-03) Ophthalmics",
    "(02-06-04) Environmental and public health": "(02-06-04) Environmental and public health",
    "(02-06-05) Physiotherapy": "(02-06-05) Physiotherapy",
    "(02-06-06) Complementary and alternative medicine": "(02-06-06) Complementary and alternative medicine",
    "(02-06-07) Counselling, psychotherapy and occupational therapy": "(02-06-07) Counselling, psychotherapy and occupational therapy",
    "(03-01-01) Biosciences (non-specific)": "(03-01-01) Biosciences (non-specific)",
    "(03-01-02) Biology (non-specific)": "(03-01-02) Biology (non-specific)",
    "(03-01-03) Ecology and environmental biology": "(03-01-03) Ecology and environmental biology",
    "(03-01-04) Microbiology and cell science": "(03-01-04) Microbiology and cell science",
    "(03-01-05) Plant sciences": "(03-01-05) Plant sciences",
    "(03-01-06) Zoology": "(03-01-06) Zoology",
    "(03-01-07) Genetics": "(03-01-07) Genetics",
    "(03-01-08) Molecular biology, biophysics and biochemistry": "(03-01-08) Molecular biology, biophysics and biochemistry",
    "(03-01-09) Hair and beauty sciences": "(03-01-09) Hair and beauty sciences",
    "(03-01-10) Others in biosciences": "(03-01-10) Others in biosciences",
    "(03-02-01) Sport and exercise sciences": "(03-02-01) Sport and exercise sciences",
    "(04-01-01) Psychology (non-specific)": "(04-01-01) Psychology (non-specific)",
    "(04-01-02) Applied psychology": "(04-01-02) Applied psychology",
    "(04-01-03) Developmental psychology": "(04-01-03) Developmental psychology",
    "(04-01-04) Psychology and health": "(04-01-04) Psychology and health",
    "(04-01-05) Others in psychology": "(04-01-05) Others in psychology",
    "(05-01-01) Veterinary medicine and dentistry": "(05-01-01) Veterinary medicine and dentistry",
    "(05-01-02) Others in veterinary sciences": "(05-01-02) Others in veterinary sciences",
    "(06-01-01) Animal science": "(06-01-01) Animal science",
    "(06-01-02) Agricultural sciences": "(06-01-02) Agricultural sciences",
    "(06-01-03) Agriculture": "(06-01-03) Agriculture",
    "(06-01-04) Rural estate management": "(06-01-04) Rural estate management",
    "(06-01-05) Forestry and arboriculture": "(06-01-05) Forestry and arboriculture",
    "(06-01-06) Food and beverage studies (non-specific)": "(06-01-06) Food and beverage studies (non-specific)",
    "(06-01-07) Food sciences": "(06-01-07) Food sciences",
    "(06-01-08) Food and beverage production": "(06-01-08) Food and beverage production",
    "(07-01-01) Physics": "(07-01-01) Physics",
    "(07-01-02) Astronomy": "(07-01-02) Astronomy",
    "(07-02-01) Chemistry": "(07-02-01) Chemistry",
    "(07-04-01) Physical sciences (non-specific)": "(07-04-01) Physical sciences (non-specific)",
    "(07-04-02) Forensic and archaeological sciences": "(07-04-02) Forensic and archaeological sciences",
    "(07-04-03) Sciences (non-specific)": "(07-04-03) Sciences (non-specific)",
    "(07-04-04) Natural sciences (non-specific)": "(07-04-04) Natural sciences (non-specific)",
    "(09-01-01) Mathematics": "(09-01-01) Mathematics",
    "(09-01-02) Operational research": "(09-01-02) Operational research",
    "(09-01-03) Statistics": "(09-01-03) Statistics",
    "(10-01-01) Engineering (non-specific)": "(10-01-01) Engineering (non-specific)",
    "(10-01-02) Mechanical engineering": "(10-01-02) Mechanical engineering",
    "(10-01-03) Production and manufacturing engineering": "(10-01-03) Production and manufacturing engineering",
    "(10-01-04) Aeronautical and aerospace engineering": "(10-01-04) Aeronautical and aerospace engineering",
    "(10-01-05) Naval architecture": "(10-01-05) Naval architecture",
    "(10-01-06) Bioengineering, medical and biomedical engineering": "(10-01-06) Bioengineering, medical and biomedical engineering",
    "(10-01-07) Civil engineering": "(10-01-07) Civil engineering",
    "(10-01-08) Electrical and electronic engineering": "(10-01-08) Electrical and electronic engineering",
    "(10-01-09) Chemical, process and energy engineering": "(10-01-09) Chemical, process and energy engineering",
    "(10-01-10) Others in engineering": "(10-01-10) Others in engineering",
    "(10-03-01) Minerals technology": "(10-03-01) Minerals technology",
    "(10-03-02) Materials technology": "(10-03-02) Materials technology",
    "(10-03-03) Polymers and textiles": "(10-03-03) Polymers and textiles",
    "(10-03-04) Maritime technology": "(10-03-04) Maritime technology",
    "(10-03-05) Biotechnology": "(10-03-05) Biotechnology",
    "(10-03-06) Others in technology": "(10-03-06) Others in technology",
    "(10-03-07) Materials science": "(10-03-07) Materials science",
    "(11-01-01) Computer science": "(11-01-01) Computer science",
    "(11-01-02) Information technology": "(11-01-02) Information technology",
    "(11-01-03) Information systems": "(11-01-03) Information systems",
    "(11-01-04) Software engineering": "(11-01-04) Software engineering",
    "(11-01-05) Artificial intelligence": "(11-01-05) Artificial intelligence",
    "(11-01-06) Computer games and animation": "(11-01-06) Computer games and animation",
    "(11-01-07) Business computing": "(11-01-07) Business computing",
    "(11-01-08) Others in computing": "(11-01-08) Others in computing",
    "(13-01-01) Architecture": "(13-01-01) Architecture",
    "(13-01-02) Building": "(13-01-02) Building",
    "(13-01-03) Landscape design": "(13-01-03) Landscape design",
    "(13-01-04) Planning (urban, rural and regional)": "(13-01-04) Planning (urban, rural and regional)",
    "(15-01-01) Social sciences (non-specific)": "(15-01-01) Social sciences (non-specific)",
    "(15-01-02) Sociology": "(15-01-02) Sociology",
    "(15-01-03) Social policy": "(15-01-03) Social policy",
    "(15-01-04) Anthropology": "(15-01-04) Anthropology",
    "(15-01-05) Development studies": "(15-01-05) Development studies",
    "(15-01-06) Cultural studies": "(15-01-06) Cultural studies",
    "(15-02-01) Economics": "(15-02-01) Economics",
    "(15-03-01) Politics": "(15-03-01) Politics",
    "(15-04-01) Social work": "(15-04-01) Social work",
    "(15-04-02) Childhood and youth studies": "(15-04-02) Childhood and youth studies",
    "(15-04-03) Health studies": "(15-04-03) Health studies",
    "(16-01-01) Law": "(16-01-01) Law",
    "(17-01-01) Business and management (non-specific)": "(17-01-01) Business and management (non-specific)",
    "(17-01-02) Business studies": "(17-01-02) Business studies",
    "(17-01-03) Marketing": "(17-01-03) Marketing",
    "(17-01-04) Management studies": "(17-01-04) Management studies",
    "(17-01-05) Human resource management": "(17-01-05) Human resource management",
    "(17-01-06) Tourism, transport and travel": "(17-01-06) Tourism, transport and travel",
    "(17-01-07) Finance": "(17-01-07) Finance",
    "(17-01-08) Accounting": "(17-01-08) Accounting",
    "(17-01-09) Others in business and management": "(17-01-09) Others in business and management",
    "(19-01-01) English studies (non-specific)": "(19-01-01) English studies (non-specific)",
    "(19-01-02) English language": "(19-01-02) English language",
    "(19-01-03) Literature in English": "(19-01-03) Literature in English",
    "(19-01-04) Studies of specific authors": "(19-01-04) Studies of specific authors",
    "(19-01-05) Creative writing": "(19-01-05) Creative writing",
    "(19-01-06) Others in English studies": "(19-01-06) Others in English studies",
    "(19-01-07) Linguistics": "(19-01-07) Linguistics",
    "(19-02-01) Celtic studies (non-specific)": "(19-02-01) Celtic studies (non-specific)",
    "(19-02-02) Gaelic studies": "(19-02-02) Gaelic studies",
    "(19-02-03) Welsh studies": "(19-02-03) Welsh studies",
    "(19-02-04) Others in Celtic studies": "(19-02-04) Others in Celtic studies",
    "(19-04-01) French studies": "(19-04-01) French studies",
    "(19-04-02) German and Scandinavian studies": "(19-04-02) German and Scandinavian studies",
    "(19-04-03) Italian studies": "(19-04-03) Italian studies",
    "(19-04-04) Iberian studies": "(19-04-04) Iberian studies",
    "(19-04-05) Slavic studies": "(19-04-05) Slavic studies",
    "(19-04-06) Asian studies": "(19-04-06) Asian studies",
    "(19-04-07) African and modern Middle Eastern studies": "(19-04-07) African and modern Middle Eastern studies",
    "(19-04-08) American and Australasian studies": "(19-04-08) American and Australasian studies",
    "(19-04-09) Others in language and area studies": "(19-04-09) Others in language and area studies",
    "(20-01-01) History": "(20-01-01) History",
    "(20-01-02) History of art, architecture and design": "(20-01-02) History of art, architecture and design",
    "(20-01-03) Archaeology": "(20-01-03) Archaeology",
    "(20-01-04) Heritage studies": "(20-01-04) Heritage studies",
    "(20-01-05) Classics": "(20-01-05) Classics",
    "(20-02-01) Philosophy": "(20-02-01) Philosophy",
    "(20-02-02) Theology and religious studies": "(20-02-02) Theology and religious studies",
    "(22-01-01) Education": "(22-01-01) Education",
    "(22-01-02) Teacher training": "(22-01-02) Teacher training",
    "(23-01-01) Combined, general or negotiated studies": "(23-01-01) Combined, general or negotiated studies",
    "(23-01-02) Personal development": "(23-01-02) Personal development",
    "(23-01-03) Humanities (non-specific)": "(23-01-03) Humanities (non-specific)",
    "(23-01-04) Liberal arts (non-specific)": "(23-01-04) Liberal arts (non-specific)",
    "(24-01-01) Information services": "(24-01-01) Information services",
    "(24-01-02) Publicity studies": "(24-01-02) Publicity studies",
    "(24-01-03) Publishing": "(24-01-03) Publishing",
    "(24-01-04) Journalism": "(24-01-04) Journalism",
    "(24-01-05) Media studies": "(24-01-05) Media studies",
    "(25-01-01) Creative arts and design (non-specific)": "(25-01-01) Creative arts and design (non-specific)",
    "(25-01-02) Art": "(25-01-02) Art",
    "(25-01-03) Design studies": "(25-01-03) Design studies",
    "(25-01-04) Cinematics and photography": "(25-01-04) Cinematics and photography",
    "(25-01-05) Others in creative arts and design": "(25-01-05) Others in creative arts and design",
    "(25-02-01) Performing arts (non-specific)": "(25-02-01) Performing arts (non-specific)",
    "(25-02-02) Music": "(25-02-02) Music",
    "(25-02-03) Drama": "(25-02-03) Drama",
    "(25-02-04) Dance": "(25-02-04) Dance",
    "(26-01-01) Geography (non-specific)": "(26-01-01) Geography (non-specific)",
    "(26-01-02) Physical geographical sciences": "(26-01-02) Physical geographical sciences",
    "(26-01-03) Human geography": "(26-01-03) Human geography",
    "(26-01-04) Environmental sciences": "(26-01-04) Environmental sciences",
    "(26-01-05) Others in geographical studies": "(26-01-05) Others in geographical studies",
    "(26-01-06) Earth sciences": "(26-01-06) Earth sciences",
    "01-01-01 Medical sciences non-specific": "(01-01-01) Medical sciences (non-specific)",
    "01-01-02 Medicine non-specific": "(01-01-02) Medicine (non-specific)",
    "01-01-03 Medicine by specialism": "(01-01-03) Medicine by specialism",
    "01-01-04 Dentistry": "(01-01-04) Dentistry",
    "02-02-01 Pharmacology": "(02-02-01) Pharmacology",
    "02-02-02 Toxicology": "(02-02-02) Toxicology",
    "02-02-03 Pharmacy": "(02-02-03) Pharmacy",
    "02-04-01 Nursing non-specific": "(02-04-01) Nursing (non-specific)",
    "02-04-02 Adult nursing": "(02-04-02) Adult nursing",
    "02-04-03 Community nursing": "(02-04-03) Community nursing",
    "02-04-04 Midwifery": "(02-04-04) Midwifery",
    "02-04-05 Children's nursing": "(02-04-05) Children's nursing",
    "02-04-06 Dental nursing": "(02-04-06) Dental nursing",
    "02-04-07 Mental health nursing": "(02-04-07) Mental health nursing",
    "02-04-08 Learning disabilities nursing": "(02-04-08) Learning disabilities nursing",
    "02-04-09 Others in nursing": "(02-04-09) Others in nursing",
    "02-05-01 Medical technology": "(02-05-01) Medical technology",
    "02-05-02 Healthcare science non-specific": "(02-05-02) Healthcare science (non-specific)",
    "02-05-03 Biomedical sciences non-specific": "(02-05-03) Biomedical sciences (non-specific)",
    "02-05-04 Anatomy, physiology and pathology": "(02-05-04) Anatomy, physiology and pathology",
    "02-06-01 Health sciences non-specific": "(02-06-01) Health sciences (non-specific)",
    "02-06-02 Nutrition and dietetics": "(02-06-02) Nutrition and dietetics",
    "02-06-03 Ophthalmics": "(02-06-03) Ophthalmics",
    "02-06-04 Environmental and public health": "(02-06-04) Environmental and public health",
    "02-06-05 Physiotherapy": "(02-06-05) Physiotherapy",
    "02-06-06 Complementary and alternative medicine": "(02-06-06) Complementary and alternative medicine",
    "02-06-07 Counselling, psychotherapy and occupational therapy": "(02-06-07) Counselling, psychotherapy and occupational therapy",
    "03-01-01 Biosciences non-specific": "(03-01-01) Biosciences (non-specific)",
    "03-01-02 Biology non-specific": "(03-01-02) Biology (non-specific)",
    "03-01-03 Ecology and environmental biology": "(03-01-03) Ecology and environmental biology",
    "03-01-04 Microbiology and cell science": "(03-01-04) Microbiology and cell science",
    "03-01-05 Plant sciences": "(03-01-05) Plant sciences",
    "03-01-06 Zoology": "(03-01-06) Zoology",
    "03-01-07 Genetics": "(03-01-07) Genetics",
    "03-01-08 Molecular biology, biophysics and biochemistry": "(03-01-08) Molecular biology, biophysics and biochemistry",
    "03-01-09 Hair and beauty sciences": "(03-01-09) Hair and beauty sciences",
    "03-01-10 Others in biosciences": "(03-01-10) Others in biosciences",
    "03-02-01 Sport and exercise sciences": "(03-02-01) Sport and exercise sciences",
    "04-01-01 Psychology non-specific": "(04-01-01) Psychology (non-specific)",
    "04-01-02 Applied psychology": "(04-01-02) Applied psychology",
    "04-01-03 Developmental psychology": "(04-01-03) Developmental psychology",
    "04-01-04 Psychology and health": "(04-01-04) Psychology and health",
    "04-01-05 Others in psychology": "(04-01-05) Others in psychology",
    "05-01-01 Veterinary medicine and dentistry": "(05-01-01) Veterinary medicine and dentistry",
    "05-01-02 Others in veterinary sciences": "(05-01-02) Others in veterinary sciences",
    "06-01-01 Animal science": "(06-01-01) Animal science",
    "06-01-02 Agricultural sciences": "(06-01-02) Agricultural sciences",
    "06-01-03 Agriculture": "(06-01-03) Agriculture",
    "06-01-04 Rural estate management": "(06-01-04) Rural estate management",
    "06-01-05 Forestry and arboriculture": "(06-01-05) Forestry and arboriculture",
    "06-01-06 Food and beverage studies non-specific": "(06-01-06) Food and beverage studies (non-specific)",
    "06-01-07 Food sciences": "(06-01-07) Food sciences",
    "06-01-08 Food and beverage production": "(06-01-08) Food and beverage production",
    "07-01-01 Physics": "(07-01-01) Physics",
    "07-01-02 Astronomy": "(07-01-02) Astronomy",
    "07-02-01 Chemistry": "(07-02-01) Chemistry",
    "07-04-01 Physical sciences non-specific": "(07-04-01) Physical sciences (non-specific)",
    "07-04-02 Forensic and archaeological sciences": "(07-04-02) Forensic and archaeological sciences",
    "07-04-03 Sciences non-specific": "(07-04-03) Sciences (non-specific)",
    "07-04-04 Natural sciences non-specific": "(07-04-04) Natural sciences (non-specific)",
    "09-01-01 Mathematics": "(09-01-01) Mathematics",
    "09-01-02 Operational research": "(09-01-02) Operational research",
    "09-01-03 Statistics": "(09-01-03) Statistics",
    "10-01-01 Engineering non-specific": "(10-01-01) Engineering (non-specific)",
    "10-01-02 Mechanical engineering": "(10-01-02) Mechanical engineering",
    "10-01-03 Production and manufacturing engineering": "(10-01-03) Production and manufacturing engineering",
    "10-01-04 Aeronautical and aerospace engineering": "(10-01-04) Aeronautical and aerospace engineering",
    "10-01-05 Naval architecture": "(10-01-05) Naval architecture",
    "10-01-06 Bioengineering, medical and biomedical engineering": "(10-01-06) Bioengineering, medical and biomedical engineering",
    "10-01-07 Civil engineering": "(10-01-07) Civil engineering",
    "10-01-08 Electrical and electronic engineering": "(10-01-08) Electrical and electronic engineering",
    "10-01-09 Chemical, process and energy engineering": "(10-01-09) Chemical, process and energy engineering",
    "10-01-10 Others in engineering": "(10-01-10) Others in engineering",
    "10-03-01 Minerals technology": "(10-03-01) Minerals technology",
    "10-03-02 Materials technology": "(10-03-02) Materials technology",
    "10-03-03 Polymers and textiles": "(10-03-03) Polymers and textiles",
    "10-03-04 Maritime technology": "(10-03-04) Maritime technology",
    "10-03-05 Biotechnology": "(10-03-05) Biotechnology",
    "10-03-06 Others in technology": "(10-03-06) Others in technology",
    "10-03-07 Materials science": "(10-03-07) Materials science",
    "11-01-01 Computer science": "(11-01-01) Computer science",
    "11-01-02 Information technology": "(11-01-02) Information technology",
    "11-01-03 Information systems": "(11-01-03) Information systems",
    "11-01-04 Software engineering": "(11-01-04) Software engineering",
    "11-01-05 Artificial intelligence": "(11-01-05) Artificial intelligence",
    "11-01-06 Computer games and animation": "(11-01-06) Computer games and animation",
    "11-01-07 Business computing": "(11-01-07) Business computing",
    "11-01-08 Others in computing": "(11-01-08) Others in computing",
    "13-01-01 Architecture": "(13-01-01) Architecture",
    "13-01-02 Building": "(13-01-02) Building",
    "13-01-03 Landscape design": "(13-01-03) Landscape design",
    "13-01-04 Planning urban, rural and regional": "(13-01-04) Planning (urban, rural and regional)",
    "15-01-01 Social sciences non-specific": "(15-01-01) Social sciences (non-specific)",
    "15-01-02 Sociology": "(15-01-02) Sociology",
    "15-01-03 Social policy": "(15-01-03) Social policy",
    "15-01-04 Anthropology": "(15-01-04) Anthropology",
    "15-01-05 Development studies": "(15-01-05) Development studies",
    "15-01-06 Cultural studies": "(15-01-06) Cultural studies",
    "15-02-01 Economics": "(15-02-01) Economics",
    "15-03-01 Politics": "(15-03-01) Politics",
    "15-04-01 Social work": "(15-04-01) Social work",
    "15-04-02 Childhood and youth studies": "(15-04-02) Childhood and youth studies",
    "15-04-03 Health studies": "(15-04-03) Health studies",
    "16-01-01 Law": "(16-01-01) Law",
    "17-01-01 Business and management non-specific": "(17-01-01) Business and management (non-specific)",
    "17-01-02 Business studies": "(17-01-02) Business studies",
    "17-01-03 Marketing": "(17-01-03) Marketing",
    "17-01-04 Management studies": "(17-01-04) Management studies",
    "17-01-05 Human resource management": "(17-01-05) Human resource management",
    "17-01-06 Tourism, transport and travel": "(17-01-06) Tourism, transport and travel",
    "17-01-07 Finance": "(17-01-07) Finance",
    "17-01-08 Accounting": "(17-01-08) Accounting",
    "17-01-09 Others in business and management": "(17-01-09) Others in business and management",
    "19-01-01 English studies non-specific": "(19-01-01) English studies (non-specific)",
    "19-01-02 English language": "(19-01-02) English language",
    "19-01-03 Literature in English": "(19-01-03) Literature in English",
    "19-01-04 Studies of specific authors": "(19-01-04) Studies of specific authors",
    "19-01-05 Creative writing": "(19-01-05) Creative writing",
    "19-01-06 Others in English studies": "(19-01-06) Others in English studies",
    "19-01-07 Linguistics": "(19-01-07) Linguistics",
    "19-02-01 Celtic studies non-specific": "(19-02-01) Celtic studies (non-specific)",
    "19-02-02 Gaelic studies": "(19-02-02) Gaelic studies",
    "19-02-03 Welsh studies": "(19-02-03) Welsh studies",
    "19-02-04 Others in Celtic studies": "(19-02-04) Others in Celtic studies",
    "19-04-01 French studies": "(19-04-01) French studies",
    "19-04-02 German and Scandinavian studies": "(19-04-02) German and Scandinavian studies",
    "19-04-03 Italian studies": "(19-04-03) Italian studies",
    "19-04-04 Iberian studies": "(19-04-04) Iberian studies",
    "19-04-05 Slavic studies": "(19-04-05) Slavic studies",
    "19-04-06 Asian studies": "(19-04-06) Asian studies",
    "19-04-07 African and modern Middle Eastern studies": "(19-04-07) African and modern Middle Eastern studies",
    "19-04-08 American and Australasian studies": "(19-04-08) American and Australasian studies",
    "19-04-09 Others in language and area studies": "(19-04-09) Others in language and area studies",
    "20-01-01 History": "(20-01-01) History",
    "20-01-02 History of art, architecture and design": "(20-01-02) History of art, architecture and design",
    "20-01-03 Archaeology": "(20-01-03) Archaeology",
    "20-01-04 Heritage studies": "(20-01-04) Heritage studies",
    "20-01-05 Classics": "(20-01-05) Classics",
    "20-02-01 Philosophy": "(20-02-01) Philosophy",
    "20-02-02 Theology and religious studies": "(20-02-02) Theology and religious studies",
    "22-01-01 Education": "(22-01-01) Education",
    "22-01-02 Teacher training": "(22-01-02) Teacher training",
    "23-01-01 Combined, general or negotiated studies": "(23-01-01) Combined, general or negotiated studies",
    "23-01-02 Personal development": "(23-01-02) Personal development",
    "23-01-03 Humanities non-specific": "(23-01-03) Humanities (non-specific)",
    "23-01-04 Liberal arts non-specific": "(23-01-04) Liberal arts (non-specific)",
    "24-01-01 Information services": "(24-01-01) Information services",
    "24-01-02 Publicity studies": "(24-01-02) Publicity studies",
    "24-01-03 Publishing": "(24-01-03) Publishing",
    "24-01-04 Journalism": "(24-01-04) Journalism",
    "24-01-05 Media studies": "(24-01-05) Media studies",
    "25-01-01 Creative arts and design non-specific": "(25-01-01) Creative arts and design (non-specific)",
    "25-01-02 Art": "(25-01-02) Art",
    "25-01-03 Design studies": "(25-01-03) Design studies",
    "25-01-04 Cinematics and photography": "(25-01-04) Cinematics and photography",
    "25-01-05 Others in creative arts and design": "(25-01-05) Others in creative arts and design",
    "25-02-01 Performing arts non-specific": "(25-02-01) Performing arts (non-specific)",
    "25-02-02 Music": "(25-02-02) Music",
    "25-02-03 Drama": "(25-02-03) Drama",
    "25-02-04 Dance": "(25-02-04) Dance",
    "26-01-01 Geography non-specific": "(26-01-01) Geography (non-specific)",
    "26-01-02 Physical geographical sciences": "(26-01-02) Physical geographical sciences",
    "26-01-03 Human geography": "(26-01-03) Human geography",
    "26-01-04 Environmental sciences": "(26-01-04) Environmental sciences",
    "26-01-05 Others in geographical studies": "(26-01-05) Others in geographical studies",
    "26-01-06 Earth sciences": "(26-01-06) Earth sciences",
    "(01-01-01)": "(01-01-01) Medical sciences (non-specific)",
    "(01-01-02)": "(01-01-02) Medicine (non-specific)",
    "(01-01-03)": "(01-01-03) Medicine by specialism",
    "(01-01-04)": "(01-01-04) Dentistry",
    "(02-02-01)": "(02-02-01) Pharmacology",
    "(02-02-02)": "(02-02-02) Toxicology",
    "(02-02-03)": "(02-02-03) Pharmacy",
    "(02-04-01)": "(02-04-01) Nursing (non-specific)",
    "(02-04-02)": "(02-04-02) Adult nursing",
    "(02-04-03)": "(02-04-03) Community nursing",
    "(02-04-04)": "(02-04-04) Midwifery",
    "(02-04-05)": "(02-04-05) Children's nursing",
    "(02-04-06)": "(02-04-06) Dental nursing",
    "(02-04-07)": "(02-04-07) Mental health nursing",
    "(02-04-08)": "(02-04-08) Learning disabilities nursing",
    "(02-04-09)": "(02-04-09) Others in nursing",
    "(02-05-01)": "(02-05-01) Medical technology",
    "(02-05-02)": "(02-05-02) Healthcare science (non-specific)",
    "(02-05-03)": "(02-05-03) Biomedical sciences (non-specific)",
    "(02-05-04)": "(02-05-04) Anatomy, physiology and pathology",
    "(02-06-01)": "(02-06-01) Health sciences (non-specific)",
    "(02-06-02)": "(02-06-02) Nutrition and dietetics",
    "(02-06-03)": "(02-06-03) Ophthalmics",
    "(02-06-04)": "(02-06-04) Environmental and public health",
    "(02-06-05)": "(02-06-05) Physiotherapy",
    "(02-06-06)": "(02-06-06) Complementary and alternative medicine",
    "(02-06-07)": "(02-06-07) Counselling, psychotherapy and occupational therapy",
    "(03-01-01)": "(03-01-01) Biosciences (non-specific)",
    "(03-01-02)": "(03-01-02) Biology (non-specific)",
    "(03-01-03)": "(03-01-03) Ecology and environmental biology",
    "(03-01-04)": "(03-01-04) Microbiology and cell science",
    "(03-01-05)": "(03-01-05) Plant sciences",
    "(03-01-06)": "(03-01-06) Zoology",
    "(03-01-07)": "(03-01-07) Genetics",
    "(03-01-08)": "(03-01-08) Molecular biology, biophysics and biochemistry",
    "(03-01-09)": "(03-01-09) Hair and beauty sciences",
    "(03-01-10)": "(03-01-10) Others in biosciences",
    "(03-02-01)": "(03-02-01) Sport and exercise sciences",
    "(04-01-01)": "(04-01-01) Psychology (non-specific)",
    "(04-01-02)": "(04-01-02) Applied psychology",
    "(04-01-03)": "(04-01-03) Developmental psychology",
    "(04-01-04)": "(04-01-04) Psychology and health",
    "(04-01-05)": "(04-01-05) Others in psychology",
    "(05-01-01)": "(05-01-01) Veterinary medicine and dentistry",
    "(05-01-02)": "(05-01-02) Others in veterinary sciences",
    "(06-01-01)": "(06-01-01) Animal science",
    "(06-01-02)": "(06-01-02) Agricultural sciences",
    "(06-01-03)": "(06-01-03) Agriculture",
    "(06-01-04)": "(06-01-04) Rural estate management",
    "(06-01-05)": "(06-01-05) Forestry and arboriculture",
    "(06-01-06)": "(06-01-06) Food and beverage studies (non-specific)",
    "(06-01-07)": "(06-01-07) Food sciences",
    "(06-01-08)": "(06-01-08) Food and beverage production",
    "(07-01-01)": "(07-01-01) Physics",
    "(07-01-02)": "(07-01-02) Astronomy",
    "(07-02-01)": "(07-02-01) Chemistry",
    "(07-04-01)": "(07-04-01) Physical sciences (non-specific)",
    "(07-04-02)": "(07-04-02) Forensic and archaeological sciences",
    "(07-04-03)": "(07-04-03) Sciences (non-specific)",
    "(07-04-04)": "(07-04-04) Natural sciences (non-specific)",
    "(09-01-01)": "(09-01-01) Mathematics",
    "(09-01-02)": "(09-01-02) Operational research",
    "(09-01-03)": "(09-01-03) Statistics",
    "(10-01-01)": "(10-01-01) Engineering (non-specific)",
    "(10-01-02)": "(10-01-02) Mechanical engineering",
    "(10-01-03)": "(10-01-03) Production and manufacturing engineering",
    "(10-01-04)": "(10-01-04) Aeronautical and aerospace engineering",
    "(10-01-05)": "(10-01-05) Naval architecture",
    "(10-01-06)": "(10-01-06) Bioengineering, medical and biomedical engineering",
    "(10-01-07)": "(10-01-07) Civil engineering",
    "(10-01-08)": "(10-01-08) Electrical and electronic engineering",
    "(10-01-09)": "(10-01-09) Chemical, process and energy engineering",
    "(10-01-10)": "(10-01-10) Others in engineering",
    "(10-03-01)": "(10-03-01) Minerals technology",
    "(10-03-02)": "(10-03-02) Materials technology",
    "(10-03-03)": "(10-03-03) Polymers and textiles",
    "(10-03-04)": "(10-03-04) Maritime technology",
    "(10-03-05)": "(10-03-05) Biotechnology",
    "(10-03-06)": "(10-03-06) Others in technology",
    "(10-03-07)": "(10-03-07) Materials science",
    "(11-01-01)": "(11-01-01) Computer science",
    "(11-01-02)": "(11-01-02) Information technology",
    "(11-01-03)": "(11-01-03) Information systems",
    "(11-01-04)": "(11-01-04) Software engineering",
    "(11-01-05)": "(11-01-05) Artificial intelligence",
    "(11-01-06)": "(11-01-06) Computer games and animation",
    "(11-01-07)": "(11-01-07) Business computing",
    "(11-01-08)": "(11-01-08) Others in computing",
    "(13-01-01)": "(13-01-01) Architecture",
    "(13-01-02)": "(13-01-02) Building",
    "(13-01-03)": "(13-01-03) Landscape design",
    "(13-01-04)": "(13-01-04) Planning (urban, rural and regional)",
    "(15-01-01)": "(15-01-01) Social sciences (non-specific)",
    "(15-01-02)": "(15-01-02) Sociology",
    "(15-01-03)": "(15-01-03) Social policy",
    "(15-01-04)": "(15-01-04) Anthropology",
    "(15-01-05)": "(15-01-05) Development studies",
    "(15-01-06)": "(15-01-06) Cultural studies",
    "(15-02-01)": "(15-02-01) Economics",
    "(15-03-01)": "(15-03-01) Politics",
    "(15-04-01)": "(15-04-01) Social work",
    "(15-04-02)": "(15-04-02) Childhood and youth studies",
    "(15-04-03)": "(15-04-03) Health studies",
    "(16-01-01)": "(16-01-01) Law",
    "(17-01-01)": "(17-01-01) Business and management (non-specific)",
    "(17-01-02)": "(17-01-02) Business studies",
    "(17-01-03)": "(17-01-03) Marketing",
    "(17-01-04)": "(17-01-04) Management studies",
    "(17-01-05)": "(17-01-05) Human resource management",
    "(17-01-06)": "(17-01-06) Tourism, transport and travel",
    "(17-01-07)": "(17-01-07) Finance",
    "(17-01-08)": "(17-01-08) Accounting",
    "(17-01-09)": "(17-01-09) Others in business and management",
    "(19-01-01)": "(19-01-01) English studies (non-specific)",
    "(19-01-02)": "(19-01-02) English language",
    "(19-01-03)": "(19-01-03) Literature in English",
    "(19-01-04)": "(19-01-04) Studies of specific authors",
    "(19-01-05)": "(19-01-05) Creative writing",
    "(19-01-06)": "(19-01-06) Others in English studies",
    "(19-01-07)": "(19-01-07) Linguistics",
    "(19-02-01)": "(19-02-01) Celtic studies (non-specific)",
    "(19-02-02)": "(19-02-02) Gaelic studies",
    "(19-02-03)": "(19-02-03) Welsh studies",
    "(19-02-04)": "(19-02-04) Others in Celtic studies",
    "(19-04-01)": "(19-04-01) French studies",
    "(19-04-02)": "(19-04-02) German and Scandinavian studies",
    "(19-04-03)": "(19-04-03) Italian studies",
    "(19-04-04)": "(19-04-04) Iberian studies",
    "(19-04-05)": "(19-04-05) Slavic studies",
    "(19-04-06)": "(19-04-06) Asian studies",
    "(19-04-07)": "(19-04-07) African and modern Middle Eastern studies",
    "(19-04-08)": "(19-04-08) American and Australasian studies",
    "(19-04-09)": "(19-04-09) Others in language and area studies",
    "(20-01-01)": "(20-01-01) History",
    "(20-01-02)": "(20-01-02) History of art, architecture and design",
    "(20-01-03)": "(20-01-03) Archaeology",
    "(20-01-04)": "(20-01-04) Heritage studies",
    "(20-01-05)": "(20-01-05) Classics",
    "(20-02-01)": "(20-02-01) Philosophy",
    "(20-02-02)": "(20-02-02) Theology and religious studies",
    "(22-01-01)": "(22-01-01) Education",
    "(22-01-02)": "(22-01-02) Teacher training",
    "(23-01-01)": "(23-01-01) Combined, general or negotiated studies",
    "(23-01-02)": "(23-01-02) Personal development",
    "(23-01-03)": "(23-01-03) Humanities (non-specific)",
    "(23-01-04)": "(23-01-04) Liberal arts (non-specific)",
    "(24-01-01)": "(24-01-01) Information services",
    "(24-01-02)": "(24-01-02) Publicity studies",
    "(24-01-03)": "(24-01-03) Publishing",
    "(24-01-04)": "(24-01-04) Journalism",
    "(24-01-05)": "(24-01-05) Media studies",
    "(25-01-01)": "(25-01-01) Creative arts and design (non-specific)",
    "(25-01-02)": "(25-01-02) Art",
    "(25-01-03)": "(25-01-03) Design studies",
    "(25-01-04)": "(25-01-04) Cinematics and photography",
    "(25-01-05)": "(25-01-05) Others in creative arts and design",
    "(25-02-01)": "(25-02-01) Performing arts (non-specific)",
    "(25-02-02)": "(25-02-02) Music",
    "(25-02-03)": "(25-02-03) Drama",
    "(25-02-04)": "(25-02-04) Dance",
    "(26-01-01)": "(26-01-01) Geography (non-specific)",
    "(26-01-02)": "(26-01-02) Physical geographical sciences",
    "(26-01-03)": "(26-01-03) Human geography",
    "(26-01-04)": "(26-01-04) Environmental sciences",
    "(26-01-05)": "(26-01-05) Others in geographical studies",
    "(26-01-06)": "(26-01-06) Earth sciences",
    "01-01-01": "(01-01-01) Medical sciences (non-specific)",
    "01-01-02": "(01-01-02) Medicine (non-specific)",
    "01-01-03": "(01-01-03) Medicine by specialism",
    "01-01-04": "(01-01-04) Dentistry",
    "02-02-01": "(02-02-01) Pharmacology",
    "02-02-02": "(02-02-02) Toxicology",
    "02-02-03": "(02-02-03) Pharmacy",
    "02-04-01": "(02-04-01) Nursing (non-specific)",
    "02-04-02": "(02-04-02) Adult nursing",
    "02-04-03": "(02-04-03) Community nursing",
    "02-04-04": "(02-04-04) Midwifery",
    "02-04-05": "(02-04-05) Children's nursing",
    "02-04-06": "(02-04-06) Dental nursing",
    "02-04-07": "(02-04-07) Mental health nursing",
    "02-04-08": "(02-04-08) Learning disabilities nursing",
    "02-04-09": "(02-04-09) Others in nursing",
    "02-05-01": "(02-05-01) Medical technology",
    "02-05-02": "(02-05-02) Healthcare science (non-specific)",
    "02-05-03": "(02-05-03) Biomedical sciences (non-specific)",
    "02-05-04": "(02-05-04) Anatomy, physiology and pathology",
    "02-06-01": "(02-06-01) Health sciences (non-specific)",
    "02-06-02": "(02-06-02) Nutrition and dietetics",
    "02-06-03": "(02-06-03) Ophthalmics",
    "02-06-04": "(02-06-04) Environmental and public health",
    "02-06-05": "(02-06-05) Physiotherapy",
    "02-06-06": "(02-06-06) Complementary and alternative medicine",
    "02-06-07": "(02-06-07) Counselling, psychotherapy and occupational therapy",
    "03-01-01": "(03-01-01) Biosciences (non-specific)",
    "03-01-02": "(03-01-02) Biology (non-specific)",
    "03-01-03": "(03-01-03) Ecology and environmental biology",
    "03-01-04": "(03-01-04) Microbiology and cell science",
    "03-01-05": "(03-01-05) Plant sciences",
    "03-01-06": "(03-01-06) Zoology",
    "03-01-07": "(03-01-07) Genetics",
    "03-01-08": "(03-01-08) Molecular biology, biophysics and biochemistry",
    "03-01-09": "(03-01-09) Hair and beauty sciences",
    "03-01-10": "(03-01-10) Others in biosciences",
    "03-02-01": "(03-02-01) Sport and exercise sciences",
    "04-01-01": "(04-01-01) Psychology (non-specific)",
    "04-01-02": "(04-01-02) Applied psychology",
    "04-01-03": "(04-01-03) Developmental psychology",
    "04-01-04": "(04-01-04) Psychology and health",
    "04-01-05": "(04-01-05) Others in psychology",
    "05-01-01": "(05-01-01) Veterinary medicine and dentistry",
    "05-01-02": "(05-01-02) Others in veterinary sciences",
    "06-01-01": "(06-01-01) Animal science",
    "06-01-02": "(06-01-02) Agricultural sciences",
    "06-01-03": "(06-01-03) Agriculture",
    "06-01-04": "(06-01-04) Rural estate management",
    "06-01-05": "(06-01-05) Forestry and arboriculture",
    "06-01-06": "(06-01-06) Food and beverage studies (non-specific)",
    "06-01-07": "(06-01-07) Food sciences",
    "06-01-08": "(06-01-08) Food and beverage production",
    "07-01-01": "(07-01-01) Physics",
    "07-01-02": "(07-01-02) Astronomy",
    "07-02-01": "(07-02-01) Chemistry",
    "07-04-01": "(07-04-01) Physical sciences (non-specific)",
    "07-04-02": "(07-04-02) Forensic and archaeological sciences",
    "07-04-03": "(07-04-03) Sciences (non-specific)",
    "07-04-04": "(07-04-04) Natural sciences (non-specific)",
    "09-01-01": "(09-01-01) Mathematics",
    "09-01-02": "(09-01-02) Operational research",
    "09-01-03": "(09-01-03) Statistics",
    "10-01-01": "(10-01-01) Engineering (non-specific)",
    "10-01-02": "(10-01-02) Mechanical engineering",
    "10-01-03": "(10-01-03) Production and manufacturing engineering",
    "10-01-04": "(10-01-04) Aeronautical and aerospace engineering",
    "10-01-05": "(10-01-05) Naval architecture",
    "10-01-06": "(10-01-06) Bioengineering, medical and biomedical engineering",
    "10-01-07": "(10-01-07) Civil engineering",
    "10-01-08": "(10-01-08) Electrical and electronic engineering",
    "10-01-09": "(10-01-09) Chemical, process and energy engineering",
    "10-01-10": "(10-01-10) Others in engineering",
    "10-03-01": "(10-03-01) Minerals technology",
    "10-03-02": "(10-03-02) Materials technology",
    "10-03-03": "(10-03-03) Polymers and textiles",
    "10-03-04": "(10-03-04) Maritime technology",
    "10-03-05": "(10-03-05) Biotechnology",
    "10-03-06": "(10-03-06) Others in technology",
    "10-03-07": "(10-03-07) Materials science",
    "11-01-01": "(11-01-01) Computer science",
    "11-01-02": "(11-01-02) Information technology",
    "11-01-03": "(11-01-03) Information systems",
    "11-01-04": "(11-01-04) Software engineering",
    "11-01-05": "(11-01-05) Artificial intelligence",
    "11-01-06": "(11-01-06) Computer games and animation",
    "11-01-07": "(11-01-07) Business computing",
    "11-01-08": "(11-01-08) Others in computing",
    "13-01-01": "(13-01-01) Architecture",
    "13-01-02": "(13-01-02) Building",
    "13-01-03": "(13-01-03) Landscape design",
    "13-01-04": "(13-01-04) Planning (urban, rural and regional)",
    "15-01-01": "(15-01-01) Social sciences (non-specific)",
    "15-01-02": "(15-01-02) Sociology",
    "15-01-03": "(15-01-03) Social policy",
    "15-01-04": "(15-01-04) Anthropology",
    "15-01-05": "(15-01-05) Development studies",
    "15-01-06": "(15-01-06) Cultural studies",
    "15-02-01": "(15-02-01) Economics",
    "15-03-01": "(15-03-01) Politics",
    "15-04-01": "(15-04-01) Social work",
    "15-04-02": "(15-04-02) Childhood and youth studies",
    "15-04-03": "(15-04-03) Health studies",
    "16-01-01": "(16-01-01) Law",
    "17-01-01": "(17-01-01) Business and management (non-specific)",
    "17-01-02": "(17-01-02) Business studies",
    "17-01-03": "(17-01-03) Marketing",
    "17-01-04": "(17-01-04) Management studies",
    "17-01-05": "(17-01-05) Human resource management",
    "17-01-06": "(17-01-06) Tourism, transport and travel",
    "17-01-07": "(17-01-07) Finance",
    "17-01-08": "(17-01-08) Accounting",
    "17-01-09": "(17-01-09) Others in business and management",
    "19-01-01": "(19-01-01) English studies (non-specific)",
    "19-01-02": "(19-01-02) English language",
    "19-01-03": "(19-01-03) Literature in English",
    "19-01-04": "(19-01-04) Studies of specific authors",
    "19-01-05": "(19-01-05) Creative writing",
    "19-01-06": "(19-01-06) Others in English studies",
    "19-01-07": "(19-01-07) Linguistics",
    "19-02-01": "(19-02-01) Celtic studies (non-specific)",
    "19-02-02": "(19-02-02) Gaelic studies",
    "19-02-03": "(19-02-03) Welsh studies",
    "19-02-04": "(19-02-04) Others in Celtic studies",
    "19-04-01": "(19-04-01) French studies",
    "19-04-02": "(19-04-02) German and Scandinavian studies",
    "19-04-03": "(19-04-03) Italian studies",
    "19-04-04": "(19-04-04) Iberian studies",
    "19-04-05": "(19-04-05) Slavic studies",
    "19-04-06": "(19-04-06) Asian studies",
    "19-04-07": "(19-04-07) African and modern Middle Eastern studies",
    "19-04-08": "(19-04-08) American and Australasian studies",
    "19-04-09": "(19-04-09) Others in language and area studies",
    "20-01-01": "(20-01-01) History",
    "20-01-02": "(20-01-02) History of art, architecture and design",
    "20-01-03": "(20-01-03) Archaeology",
    "20-01-04": "(20-01-04) Heritage studies",
    "20-01-05": "(20-01-05) Classics",
    "20-02-01": "(20-02-01) Philosophy",
    "20-02-02": "(20-02-02) Theology and religious studies",
    "22-01-01": "(22-01-01) Education",
    "22-01-02": "(22-01-02) Teacher training",
    "23-01-01": "(23-01-01) Combined, general or negotiated studies",
    "23-01-02": "(23-01-02) Personal development",
    "23-01-03": "(23-01-03) Humanities (non-specific)",
    "23-01-04": "(23-01-04) Liberal arts (non-specific)",
    "24-01-01": "(24-01-01) Information services",
    "24-01-02": "(24-01-02) Publicity studies",
    "24-01-03": "(24-01-03) Publishing",
    "24-01-04": "(24-01-04) Journalism",
    "24-01-05": "(24-01-05) Media studies",
    "25-01-01": "(25-01-01) Creative arts and design (non-specific)",
    "25-01-02": "(25-01-02) Art",
    "25-01-03": "(25-01-03) Design studies",
    "25-01-04": "(25-01-04) Cinematics and photography",
    "25-01-05": "(25-01-05) Others in creative arts and design",
    "25-02-01": "(25-02-01) Performing arts (non-specific)",
    "25-02-02": "(25-02-02) Music",
    "25-02-03": "(25-02-03) Drama",
    "25-02-04": "(25-02-04) Dance",
    "26-01-01": "(26-01-01) Geography (non-specific)",
    "26-01-02": "(26-01-02) Physical geographical sciences",
    "26-01-03": "(26-01-03) Human geography",
    "26-01-04": "(26-01-04) Environmental sciences",
    "26-01-05": "(26-01-05) Others in geographical studies",
    "26-01-06": "(26-01-06) Earth sciences",
};

export const heouMapping: DomainMappingType = {
    "0001 The Open University": "0001 The Open University",
    "0002 Cranfield University": "0002 Cranfield University",
    "0003 Royal College of Art": "0003 Royal College of Art",
    "0007 Bishop Grosseteste University": "0007 Bishop Grosseteste University",
    "0009 Buckinghamshire New University": "0009 Buckinghamshire New University",
    "0010 The Royal Central School of Speech and Drama": "0010 The Royal Central School of Speech and Drama",
    "0011 University of Chester": "0011 University of Chester",
    "0012 Canterbury Christ Church University": "0012 Canterbury Christ Church University",
    "0013 York St John University": "0013 York St John University",
    "0014 University of St Mark and St John": "0014 University of St Mark and St John",
    "0016 Edge Hill University": "0016 Edge Hill University",
    "0017 Falmouth University": "0017 Falmouth University",
    "0018 Harper Adams University": "0018 Harper Adams University",
    "0021 The University of Winchester": "0021 The University of Winchester",
    "0023 Liverpool Hope University": "0023 Liverpool Hope University",
    "0024 University of the Arts, London": "0024 University of the Arts, London",
    "0026 University of Bedfordshire": "0026 University of Bedfordshire",
    "0027 The University of Northampton": "0027 The University of Northampton",
    "0028 Birmingham Newman University": "0028 Birmingham Newman University",
    "0030 Ravensbourne University London": "0030 Ravensbourne University London",
    "0031 Roehampton University": "0031 Roehampton University",
    "0032 Rose Bruford College of Theatre and Performance": "0032 Rose Bruford College of Theatre and Performance",
    "0033 Royal Academy of Music": "0033 Royal Academy of Music",
    "0034 Royal College of Music": "0034 Royal College of Music",
    "0035 Royal Northern College of Music": "0035 Royal Northern College of Music",
    "0037 Solent University": "0037 Solent University",
    "0038 University of Cumbria": "0038 University of Cumbria",
    "0039 St Mary's University, Twickenham": "0039 St Mary's University, Twickenham",
    "0040 Leeds Trinity University": "0040 Leeds Trinity University",
    "0041 Trinity Laban Conservatoire of Music and Dance": "0041 Trinity Laban Conservatoire of Music and Dance",
    "0046 University of Worcester": "0046 University of Worcester",
    "0047 Anglia Ruskin University": "0047 Anglia Ruskin University",
    "0048 Bath Spa University": "0048 Bath Spa University",
    "0049 The University of Bolton": "0049 The University of Bolton",
    "0050 Bournemouth University": "0050 Bournemouth University",
    "0051 The University of Brighton": "0051 The University of Brighton",
    "0052 Birmingham City University": "0052 Birmingham City University",
    "0053 The University of Central Lancashire": "0053 The University of Central Lancashire",
    "0054 University of Gloucestershire": "0054 University of Gloucestershire",
    "0056 Coventry University": "0056 Coventry University",
    "0057 University of Derby": "0057 University of Derby",
    "0058 The University of East London": "0058 The University of East London",
    "0059 The University of Greenwich": "0059 The University of Greenwich",
    "0060 University of Hertfordshire": "0060 University of Hertfordshire",
    "0061 The University of Huddersfield": "0061 The University of Huddersfield",
    "0062 The University of Lincoln": "0062 The University of Lincoln",
    "0063 Kingston University": "0063 Kingston University",
    "0064 Leeds Beckett University": "0064 Leeds Beckett University",
    "0065 Liverpool John Moores University": "0065 Liverpool John Moores University",
    "0066 The Manchester Metropolitan University": "0066 The Manchester Metropolitan University",
    "0067 Middlesex University": "0067 Middlesex University",
    "0068 De Montfort University": "0068 De Montfort University",
    "0069 University of Northumbria at Newcastle": "0069 University of Northumbria at Newcastle",
    "0071 The Nottingham Trent University": "0071 The Nottingham Trent University",
    "0072 Oxford Brookes University": "0072 Oxford Brookes University",
    "0073 University of Plymouth": "0073 University of Plymouth",
    "0074 The University of Portsmouth": "0074 The University of Portsmouth",
    "0075 Sheffield Hallam University": "0075 Sheffield Hallam University",
    "0076 London South Bank University": "0076 London South Bank University",
    "0077 Staffordshire University": "0077 Staffordshire University",
    "0078 The University of Sunderland": "0078 The University of Sunderland",
    "0079 Teesside University": "0079 Teesside University",
    "0080 The University of West London": "0080 The University of West London",
    "0081 University of the West of England, Bristol": "0081 University of the West of England, Bristol",
    "0082 The University of Chichester": "0082 The University of Chichester",
    "0083 The University of Westminster": "0083 The University of Westminster",
    "0085 The University of Wolverhampton": "0085 The University of Wolverhampton",
    "0087 Wrexham University": "0087 Wrexham University",
    "0089 Cardiff Metropolitan University": "0089 Cardiff Metropolitan University",
    "0090 University of South Wales": "0090 University of South Wales",
    "0095 Abertay University": "0095 Abertay University",
    "0097 Glasgow School of Art": "0097 Glasgow School of Art",
    "0100 Queen Margaret University, Edinburgh": "0100 Queen Margaret University, Edinburgh",
    "0101 Royal Conservatoire of Scotland": "0101 Royal Conservatoire of Scotland",
    "0104 Robert Gordon University": "0104 Robert Gordon University",
    "0105 The University of the West of Scotland": "0105 The University of the West of Scotland",
    "0106 Glasgow Caledonian University": "0106 Glasgow Caledonian University",
    "0107 Edinburgh Napier University": "0107 Edinburgh Napier University",
    "0108 Aston University": "0108 Aston University",
    "0109 The University of Bath": "0109 The University of Bath",
    "0110 The University of Birmingham": "0110 The University of Birmingham",
    "0111 The University of Bradford": "0111 The University of Bradford",
    "0112 The University of Bristol": "0112 The University of Bristol",
    "0113 Brunel University London": "0113 Brunel University London",
    "0114 The University of Cambridge": "0114 The University of Cambridge",
    "0115 City, University of London": "0115 City, University of London",
    "0116 University of Durham": "0116 University of Durham",
    "0117 The University of East Anglia": "0117 The University of East Anglia",
    "0118 The University of Essex": "0118 The University of Essex",
    "0119 The University of Exeter": "0119 The University of Exeter",
    "0120 The University of Hull": "0120 The University of Hull",
    "0121 Keele University": "0121 Keele University",
    "0122 The University of Kent": "0122 The University of Kent",
    "0123 The University of Lancaster": "0123 The University of Lancaster",
    "0124 The University of Leeds": "0124 The University of Leeds",
    "0125 The University of Leicester": "0125 The University of Leicester",
    "0126 The University of Liverpool": "0126 The University of Liverpool",
    "0127 Birkbeck College": "0127 Birkbeck College",
    "0131 Goldsmiths College": "0131 Goldsmiths College",
    "0132 Imperial College of Science, Technology and Medicine": "0132 Imperial College of Science, Technology and Medicine",
    "0134 King's College London": "0134 King's College London",
    "0135 London Business School": "0135 London Business School",
    "0137 London School of Economics and Political Science": "0137 London School of Economics and Political Science",
    "0138 London School of Hygiene and Tropical Medicine": "0138 London School of Hygiene and Tropical Medicine",
    "0139 Queen Mary University of London": "0139 Queen Mary University of London",
    "0141 Royal Holloway and Bedford New College": "0141 Royal Holloway and Bedford New College",
    "0143 The Royal Veterinary College": "0143 The Royal Veterinary College",
    "0145 St George's, University of London": "0145 St George's, University of London",
    "0146 SOAS University of London": "0146 SOAS University of London",
    "0149 University College London": "0149 University College London",
    "0151 University of London (Institutes and activities)": "0151 University of London (Institutes and activities)",
    "0152 Loughborough University": "0152 Loughborough University",
    "0154 Newcastle University": "0154 Newcastle University",
    "0155 University of Nottingham": "0155 University of Nottingham",
    "0156 The University of Oxford": "0156 The University of Oxford",
    "0157 The University of Reading": "0157 The University of Reading",
    "0158 The University of Salford": "0158 The University of Salford",
    "0159 The University of Sheffield": "0159 The University of Sheffield",
    "0160 The University of Southampton": "0160 The University of Southampton",
    "0161 The University of Surrey": "0161 The University of Surrey",
    "0162 The University of Sussex": "0162 The University of Sussex",
    "0163 The University of Warwick": "0163 The University of Warwick",
    "0164 The University of York": "0164 The University of York",
    "0167 The University of Edinburgh": "0167 The University of Edinburgh",
    "0168 The University of Glasgow": "0168 The University of Glasgow",
    "0169 The University of Strathclyde": "0169 The University of Strathclyde",
    "0170 The University of Aberdeen": "0170 The University of Aberdeen",
    "0171 Heriot-Watt University": "0171 Heriot-Watt University",
    "0172 The University of Dundee": "0172 The University of Dundee",
    "0173 The University of St. Andrews": "0173 The University of St. Andrews",
    "0174 The University of Stirling": "0174 The University of Stirling",
    "0175 SRUC": "0175 SRUC",
    "0176 University of Wales Trinity Saint David": "0176 University of Wales Trinity Saint David",
    "0177 Aberystwyth University": "0177 Aberystwyth University",
    "0178 Bangor University": "0178 Bangor University",
    "0179 Cardiff University": "0179 Cardiff University",
    "0180 Swansea University": "0180 Swansea University",
    "0184 Queen's University Belfast": "0184 Queen's University Belfast",
    "0185 Ulster University": "0185 Ulster University",
    "0186 The University of Wales (central functions)": "0186 The University of Wales (central functions)",
    "0188 The Institute of Cancer Research": "0188 The Institute of Cancer Research",
    "0189 Writtle University College": "0189 Writtle University College",
    "0190 Norwich University of the Arts": "0190 Norwich University of the Arts",
    "0191 Northern School of Contemporary Dance": "0191 Northern School of Contemporary Dance",
    "0193 Stranmillis University College": "0193 Stranmillis University College",
    "0194 St Mary's University College": "0194 St Mary's University College",
    "0195 Royal Agricultural University": "0195 Royal Agricultural University",
    "0196 University of the Highlands and Islands": "0196 University of the Highlands and Islands",
    "0197 The Arts University Bournemouth": "0197 The Arts University Bournemouth",
    "0199 Conservatoire for Dance and Drama": "0199 Conservatoire for Dance and Drama",
    "0200 University College Birmingham": "0200 University College Birmingham",
    "0201 Courtauld Institute of Art": "0201 Courtauld Institute of Art",
    "0202 London Metropolitan University": "0202 London Metropolitan University",
    "0203 The University of Buckingham": "0203 The University of Buckingham",
    "0204 The University of Manchester": "0204 The University of Manchester",
    "0205 Heythrop College": "0205 Heythrop College",
    "0206 University for the Creative Arts": "0206 University for the Creative Arts",
    "0208 Guildhall School of Music and Drama": "0208 Guildhall School of Music and Drama",
    "0209 The Liverpool Institute for Performing Arts": "0209 The Liverpool Institute for Performing Arts",
    "0210 University of Suffolk": "0210 University of Suffolk",
    "0211 Leeds Arts University": "0211 Leeds Arts University",
    "0213 AECC University College": "0213 AECC University College",
    "0214 BPP University": "0214 BPP University",
    "0215 GSM London": "0215 GSM London",
    "0216 Regent's University London": "0216 Regent's University London",
    "0217 The London Institute of Banking & Finance": "0217 The London Institute of Banking & Finance",
    "0218 The University of Law": "0218 The University of Law",
    "0219 Richmond, The American International University in London": "0219 Richmond, The American International University in London",
    "0220 Institute of Contemporary Music Performance": "0220 Institute of Contemporary Music Performance",
    "0221 Moorlands College": "0221 Moorlands College",
    "0225 Missio Dei": "0225 Missio Dei",
    "0227 London School of Science and Technology Limited": "0227 London School of Science and Technology Limited",
    "0228 Liverpool School of Tropical Medicine": "0228 Liverpool School of Tropical Medicine",
    "0229 The National Film and Television School": "0229 The National Film and Television School",
    "0230 Arts University Plymouth": "0230 Arts University Plymouth",
    "0231 ABI College Limited": "0231 ABI College Limited",
    "0232 BIMM University": "0232 BIMM University",
    "0233 West London College": "0233 West London College",
    "0234 City of London College": "0234 City of London College",
    "0235 EThames Graduate School Limited": "0235 EThames Graduate School Limited",
    "0236 ICON College of Technology and Management": "0236 ICON College of Technology and Management",
    "0237 Kensington College of Business": "0237 Kensington College of Business",
    "0238 Bloomsbury Institute": "0238 Bloomsbury Institute",
    "0239 Mountview Academy of Theatre Arts": "0239 Mountview Academy of Theatre Arts",
    "0240 Nazarene Theological College": "0240 Nazarene Theological College",
    "0241 Stratford College London": "0241 Stratford College London",
    "0243 The London College": "0243 The London College",
    "0244 Arts Educational Schools": "0244 Arts Educational Schools",
    "0245 Grafton College": "0245 Grafton College",
    "0246 Mont Rose College of Management and Sciences": "0246 Mont Rose College of Management and Sciences",
    "0247 Norland College": "0247 Norland College",
    "0248 ForMission Ltd": "0248 ForMission Ltd",
    "0249 UK College of Business and Computing": "0249 UK College of Business and Computing",
    "0250 London Churchill College Ltd": "0250 London Churchill College Ltd",
    "0251 The Academy of Contemporary Music": "0251 The Academy of Contemporary Music",
    "0252 Empire College London Limited": "0252 Empire College London Limited",
    "0253 East End Computing and Business College Limited": "0253 East End Computing and Business College Limited",
    "0254 Fairfield School of Business Ltd": "0254 Fairfield School of Business Ltd",
    "0255 London College of Business Studies": "0255 London College of Business Studies",
    "0256 London School of Management Education": "0256 London School of Management Education",
    "0257 London School of Theology": "0257 London School of Theology",
    "0258 St Mellitus College": "0258 St Mellitus College",
    "0260 Nelson College London Ltd": "0260 Nelson College London Ltd",
    "0261 Northern College of Acupuncture": "0261 Northern College of Acupuncture",
    "0262 Escape Studios": "0262 Escape Studios",
    "0263 Royal Academy of Dance": "0263 Royal Academy of Dance",
    "0265 SAE Education Limited": "0265 SAE Education Limited",
    "0266 Slough Borough Council": "0266 Slough Borough Council",
    "0267 Tottenham Hotspur Foundation": "0267 Tottenham Hotspur Foundation",
    "0268 Access to Music Limited": "0268 Access to Music Limited",
    "0269 Academy of Live and Recorded Arts": "0269 Academy of Live and Recorded Arts",
    "0270 The College of Integrated Chinese Medicine": "0270 The College of Integrated Chinese Medicine",
    "0271 The City College": "0271 The City College",
    "0272 Met Film School Limited": "0272 Met Film School Limited",
    "0274 London Studio Centre": "0274 London Studio Centre",
    "0275 West London College of Business and Management Sciences Limited": "0275 West London College of Business and Management Sciences Limited",
    "0276 London School of Academics Ltd": "0276 London School of Academics Ltd",
    "0277 London Centre of Contemporary Music": "0277 London Centre of Contemporary Music",
    "0278 Regent College London": "0278 Regent College London",
    "0279 London School of Business and Finance (UK) Limited": "0279 London School of Business and Finance (UK) Limited",
    "0280 Brit College": "0280 Brit College",
    "0281 Futureworks": "0281 Futureworks",
    "0282 London Bridge Business Academy": "0282 London Bridge Business Academy",
    "0283 St Patrick's International College": "0283 St Patrick's International College",
    "0284 Chicken Shed Theatre Company": "0284 Chicken Shed Theatre Company",
    "0285 All Nations Christian College": "0285 All Nations Christian College",
    "0287 Bristol Baptist College": "0287 Bristol Baptist College",
    "0288 The Cambridge Theological Federation": "0288 The Cambridge Theological Federation",
    "0291 Christie's Education Limited": "0291 Christie's Education Limited",
    "0292 City and Guilds of London Art School": "0292 City and Guilds of London Art School",
    "0293 Cliff College": "0293 Cliff College",
    "0294 University College of Estate Management": "0294 University College of Estate Management",
    "0295 Waverley Abbey College": "0295 Waverley Abbey College",
    "0297 ICOM": "0297 ICOM",
    "0298 The Islamic College": "0298 The Islamic College",
    "0299 Istituto Marangoni Limited": "0299 Istituto Marangoni Limited",
    "0300 Kaplan Open Learning (Essex) Limited": "0300 Kaplan Open Learning (Essex) Limited",
    "0301 KLC School of Design": "0301 KLC School of Design",
    "0302 Kogan Academy of Dramatic Arts": "0302 Kogan Academy of Dramatic Arts",
    "0304 London Film School Limited": "0304 London Film School Limited",
    "0306 Luther King House Educational Trust": "0306 Luther King House Educational Trust",
    "0307 Open College of the Arts": "0307 Open College of the Arts",
    "0308 Oxford Business College": "0308 Oxford Business College",
    "0309 Point Blank Music School": "0309 Point Blank Music School",
    "0310 Spurgeon's College": "0310 Spurgeon's College",
    "0311 St Nicholas Montessori Training Limited": "0311 St Nicholas Montessori Training Limited",
    "0312 West Dean College": "0312 West Dean College",
    "0313 Oak Hill College": "0313 Oak Hill College",
    "0314 The Metanoia Institute": "0314 The Metanoia Institute",
    "0315 The Queen's Foundation for Ecumenical Theological Education": "0315 The Queen's Foundation for Ecumenical Theological Education",
    "0316 The Salvation Army": "0316 The Salvation Army",
    "0317 Trinity College Bristol": "0317 Trinity College Bristol",
    "0319 Nova College of Accounting and Business Ltd": "0319 Nova College of Accounting and Business Ltd",
    "0320 Matrix College of Counselling and Psychotherapy Ltd": "0320 Matrix College of Counselling and Psychotherapy Ltd",
    "0321 The Minster Centre": "0321 The Minster Centre",
    "0322 The Sherwood Institute": "0322 The Sherwood Institute",
    "0323 AA School of Architecture": "0323 AA School of Architecture",
    "0324 Ballet West": "0324 Ballet West",
    "0326 Court Theatre Training Company Ltd": "0326 Court Theatre Training Company Ltd",
    "0327 Regents Theological College": "0327 Regents Theological College",
    "0328 London School of Commerce & IT Limited": "0328 London School of Commerce & IT Limited",
    "0329 Apex College": "0329 Apex College",
    "0330 Northeastern University London": "0330 Northeastern University London",
    "0331 The Markfield Institute of Higher Education": "0331 The Markfield Institute of Higher Education",
    "0332 Cambridge Arts and Sciences Limited": "0332 Cambridge Arts and Sciences Limited",
    "0333 Arden University": "0333 Arden University",
    "0334 Grŵp Llandrillo Menai": "0334 Grŵp Llandrillo Menai",
    "0335 Grŵp NPTC Group": "0335 Grŵp NPTC Group",
    "0336 Gower College Swansea": "0336 Gower College Swansea",
    "0337 The University College of Osteopathy": "0337 The University College of Osteopathy",
    "0338 London College of Business Sciences": "0338 London College of Business Sciences",
    "0339 Millennium Performing Arts Ltd": "0339 Millennium Performing Arts Ltd",
    "0340 London College of Creative Media Limited": "0340 London College of Creative Media Limited",
    "0341 International Business College Manchester Limited": "0341 International Business College Manchester Limited",
    "0342 Christ the Redeemer College": "0342 Christ the Redeemer College",
    "0343 Waltham International College Limited": "0343 Waltham International College Limited",
    "0351 Leeds Conservatoire": "0351 Leeds Conservatoire",
    "0352 Hartpury University": "0352 Hartpury University",
    "0353 Amity Global Education": "0353 Amity Global Education",
    "0354 The London School of Architecture": "0354 The London School of Architecture",
    "0355 London College of International Business Studies Ltd": "0355 London College of International Business Studies Ltd",
    "0356 LCCM AU UK Limited": "0356 LCCM AU UK Limited",
    "0357 ACM Guildford Limited": "0357 ACM Guildford Limited",
    "0359 The Film Education Training Trust Limited": "0359 The Film Education Training Trust Limited",
    "0361 Newbold College": "0361 Newbold College",
    "0363 Global Banking School Limited": "0363 Global Banking School Limited",
    "0401 LAMDA Limited": "0401 LAMDA Limited",
    "0402 Royal Academy of Dramatic Art": "0402 Royal Academy of Dramatic Art",
    "0405 Institute of Art - London Limited": "0405 Institute of Art - London Limited",
    "0407 The Sherwood Psychotherapy Training Institute Limited": "0407 The Sherwood Psychotherapy Training Institute Limited",
    "0408 University Centre Peterborough": "0408 University Centre Peterborough",
    "0409 Central Film School London": "0409 Central Film School London",
    "0410 ESCP Europe Business School": "0410 ESCP Europe Business School",
    "0412 The Council of the Inns of Court": "0412 The Council of the Inns of Court",
    "0416 Results Consortium Limited": "0416 Results Consortium Limited",
    "0418 University Centre Quayside Limited": "0418 University Centre Quayside Limited",
    "0419 David Game College Ltd": "0419 David Game College Ltd",
    "0420 Dartington Hall Trust (The)": "0420 Dartington Hall Trust (The)",
    "0421 New Model Institute for Technology and Engineering (NMITE)": "0421 New Model Institute for Technology and Engineering (NMITE)",
    "0422 Kaplan International Colleges U.K. Limited": "0422 Kaplan International Colleges U.K. Limited",
    "0423 INTO University Partnerships Limited": "0423 INTO University Partnerships Limited",
    "0424 The College of Osteopaths": "0424 The College of Osteopaths",
    "0425 Regent's University London Limited": "0425 Regent's University London Limited",
    "0426 Academy of Live Technology Ltd": "0426 Academy of Live Technology Ltd",
    "0427 British Academy of Jewellery Limited": "0427 British Academy of Jewellery Limited",
    "0429 Raindance Educational Services Limited": "0429 Raindance Educational Services Limited",
    "0430 The College of Health Ltd": "0430 The College of Health Ltd",
    "0431 CEG UFP Limited": "0431 CEG UFP Limited",
    "0432 Applied Business Academy Limited": "0432 Applied Business Academy Limited",
    "0435 The Engineering and Design Institute London": "0435 The Engineering and Design Institute London",
    "0437 ThinkSpace Education Limited": "0437 ThinkSpace Education Limited",
    "0441 Paris Dauphine International": "0441 Paris Dauphine International",
    "0443 Rambert School": "0443 Rambert School",
    "0445 National Centre for Circus Arts": "0445 National Centre for Circus Arts",
    "0446 Central School of Ballet": "0446 Central School of Ballet",
    "0001": "0001 The Open University",
    "0002": "0002 Cranfield University",
    "0003": "0003 Royal College of Art",
    "0007": "0007 Bishop Grosseteste University",
    "0009": "0009 Buckinghamshire New University",
    "0010": "0010 The Royal Central School of Speech and Drama",
    "0011": "0011 University of Chester",
    "0012": "0012 Canterbury Christ Church University",
    "0013": "0013 York St John University",
    "0014": "0014 University of St Mark and St John",
    "0016": "0016 Edge Hill University",
    "0017": "0017 Falmouth University",
    "0018": "0018 Harper Adams University",
    "0021": "0021 The University of Winchester",
    "0023": "0023 Liverpool Hope University",
    "0024": "0024 University of the Arts, London",
    "0026": "0026 University of Bedfordshire",
    "0027": "0027 The University of Northampton",
    "0028": "0028 Birmingham Newman University",
    "0030": "0030 Ravensbourne University London",
    "0031": "0031 Roehampton University",
    "0032": "0032 Rose Bruford College of Theatre and Performance",
    "0033": "0033 Royal Academy of Music",
    "0034": "0034 Royal College of Music",
    "0035": "0035 Royal Northern College of Music",
    "0037": "0037 Solent University",
    "0038": "0038 University of Cumbria",
    "0039": "0039 St Mary's University, Twickenham",
    "0040": "0040 Leeds Trinity University",
    "0041": "0041 Trinity Laban Conservatoire of Music and Dance",
    "0046": "0046 University of Worcester",
    "0047": "0047 Anglia Ruskin University",
    "0048": "0048 Bath Spa University",
    "0049": "0049 The University of Bolton",
    "0050": "0050 Bournemouth University",
    "0051": "0051 The University of Brighton",
    "0052": "0052 Birmingham City University",
    "0053": "0053 The University of Central Lancashire",
    "0054": "0054 University of Gloucestershire",
    "0056": "0056 Coventry University",
    "0057": "0057 University of Derby",
    "0058": "0058 The University of East London",
    "0059": "0059 The University of Greenwich",
    "0060": "0060 University of Hertfordshire",
    "0061": "0061 The University of Huddersfield",
    "0062": "0062 The University of Lincoln",
    "0063": "0063 Kingston University",
    "0064": "0064 Leeds Beckett University",
    "0065": "0065 Liverpool John Moores University",
    "0066": "0066 The Manchester Metropolitan University",
    "0067": "0067 Middlesex University",
    "0068": "0068 De Montfort University",
    "0069": "0069 University of Northumbria at Newcastle",
    "0071": "0071 The Nottingham Trent University",
    "0072": "0072 Oxford Brookes University",
    "0073": "0073 University of Plymouth",
    "0074": "0074 The University of Portsmouth",
    "0075": "0075 Sheffield Hallam University",
    "0076": "0076 London South Bank University",
    "0077": "0077 Staffordshire University",
    "0078": "0078 The University of Sunderland",
    "0079": "0079 Teesside University",
    "0080": "0080 The University of West London",
    "0081": "0081 University of the West of England, Bristol",
    "0082": "0082 The University of Chichester",
    "0083": "0083 The University of Westminster",
    "0085": "0085 The University of Wolverhampton",
    "0087": "0087 Wrexham University",
    "0089": "0089 Cardiff Metropolitan University",
    "0090": "0090 University of South Wales",
    "0095": "0095 Abertay University",
    "0097": "0097 Glasgow School of Art",
    "0100": "0100 Queen Margaret University, Edinburgh",
    "0101": "0101 Royal Conservatoire of Scotland",
    "0104": "0104 Robert Gordon University",
    "0105": "0105 The University of the West of Scotland",
    "0106": "0106 Glasgow Caledonian University",
    "0107": "0107 Edinburgh Napier University",
    "0108": "0108 Aston University",
    "0109": "0109 The University of Bath",
    "0110": "0110 The University of Birmingham",
    "0111": "0111 The University of Bradford",
    "0112": "0112 The University of Bristol",
    "0113": "0113 Brunel University London",
    "0114": "0114 The University of Cambridge",
    "0115": "0115 City, University of London",
    "0116": "0116 University of Durham",
    "0117": "0117 The University of East Anglia",
    "0118": "0118 The University of Essex",
    "0119": "0119 The University of Exeter",
    "0120": "0120 The University of Hull",
    "0121": "0121 Keele University",
    "0122": "0122 The University of Kent",
    "0123": "0123 The University of Lancaster",
    "0124": "0124 The University of Leeds",
    "0125": "0125 The University of Leicester",
    "0126": "0126 The University of Liverpool",
    "0127": "0127 Birkbeck College",
    "0131": "0131 Goldsmiths College",
    "0132": "0132 Imperial College of Science, Technology and Medicine",
    "0134": "0134 King's College London",
    "0135": "0135 London Business School",
    "0137": "0137 London School of Economics and Political Science",
    "0138": "0138 London School of Hygiene and Tropical Medicine",
    "0139": "0139 Queen Mary University of London",
    "0141": "0141 Royal Holloway and Bedford New College",
    "0143": "0143 The Royal Veterinary College",
    "0145": "0145 St George's, University of London",
    "0146": "0146 SOAS University of London",
    "0149": "0149 University College London",
    "0151": "0151 University of London (Institutes and activities)",
    "0152": "0152 Loughborough University",
    "0154": "0154 Newcastle University",
    "0155": "0155 University of Nottingham",
    "0156": "0156 The University of Oxford",
    "0157": "0157 The University of Reading",
    "0158": "0158 The University of Salford",
    "0159": "0159 The University of Sheffield",
    "0160": "0160 The University of Southampton",
    "0161": "0161 The University of Surrey",
    "0162": "0162 The University of Sussex",
    "0163": "0163 The University of Warwick",
    "0164": "0164 The University of York",
    "0167": "0167 The University of Edinburgh",
    "0168": "0168 The University of Glasgow",
    "0169": "0169 The University of Strathclyde",
    "0170": "0170 The University of Aberdeen",
    "0171": "0171 Heriot-Watt University",
    "0172": "0172 The University of Dundee",
    "0173": "0173 The University of St. Andrews",
    "0174": "0174 The University of Stirling",
    "0175": "0175 SRUC",
    "0176": "0176 University of Wales Trinity Saint David",
    "0177": "0177 Aberystwyth University",
    "0178": "0178 Bangor University",
    "0179": "0179 Cardiff University",
    "0180": "0180 Swansea University",
    "0184": "0184 Queen's University Belfast",
    "0185": "0185 Ulster University",
    "0186": "0186 The University of Wales (central functions)",
    "0188": "0188 The Institute of Cancer Research",
    "0189": "0189 Writtle University College",
    "0190": "0190 Norwich University of the Arts",
    "0191": "0191 Northern School of Contemporary Dance",
    "0193": "0193 Stranmillis University College",
    "0194": "0194 St Mary's University College",
    "0195": "0195 Royal Agricultural University",
    "0196": "0196 University of the Highlands and Islands",
    "0197": "0197 The Arts University Bournemouth",
    "0199": "0199 Conservatoire for Dance and Drama",
    "0200": "0200 University College Birmingham",
    "0201": "0201 Courtauld Institute of Art",
    "0202": "0202 London Metropolitan University",
    "0203": "0203 The University of Buckingham",
    "0204": "0204 The University of Manchester",
    "0205": "0205 Heythrop College",
    "0206": "0206 University for the Creative Arts",
    "0208": "0208 Guildhall School of Music and Drama",
    "0209": "0209 The Liverpool Institute for Performing Arts",
    "0210": "0210 University of Suffolk",
    "0211": "0211 Leeds Arts University",
    "0213": "0213 AECC University College",
    "0214": "0214 BPP University",
    "0215": "0215 GSM London",
    "0216": "0216 Regent's University London",
    "0217": "0217 The London Institute of Banking & Finance",
    "0218": "0218 The University of Law",
    "0219": "0219 Richmond, The American International University in London",
    "0220": "0220 Institute of Contemporary Music Performance",
    "0221": "0221 Moorlands College",
    "0225": "0225 Missio Dei",
    "0227": "0227 London School of Science and Technology Limited",
    "0228": "0228 Liverpool School of Tropical Medicine",
    "0229": "0229 The National Film and Television School",
    "0230": "0230 Arts University Plymouth",
    "0231": "0231 ABI College Limited",
    "0232": "0232 BIMM University",
    "0233": "0233 West London College",
    "0234": "0234 City of London College",
    "0235": "0235 EThames Graduate School Limited",
    "0236": "0236 ICON College of Technology and Management",
    "0237": "0237 Kensington College of Business",
    "0238": "0238 Bloomsbury Institute",
    "0239": "0239 Mountview Academy of Theatre Arts",
    "0240": "0240 Nazarene Theological College",
    "0241": "0241 Stratford College London",
    "0243": "0243 The London College",
    "0244": "0244 Arts Educational Schools",
    "0245": "0245 Grafton College",
    "0246": "0246 Mont Rose College of Management and Sciences",
    "0247": "0247 Norland College",
    "0248": "0248 ForMission Ltd",
    "0249": "0249 UK College of Business and Computing",
    "0250": "0250 London Churchill College Ltd",
    "0251": "0251 The Academy of Contemporary Music",
    "0252": "0252 Empire College London Limited",
    "0253": "0253 East End Computing and Business College Limited",
    "0254": "0254 Fairfield School of Business Ltd",
    "0255": "0255 London College of Business Studies",
    "0256": "0256 London School of Management Education",
    "0257": "0257 London School of Theology",
    "0258": "0258 St Mellitus College",
    "0260": "0260 Nelson College London Ltd",
    "0261": "0261 Northern College of Acupuncture",
    "0262": "0262 Escape Studios",
    "0263": "0263 Royal Academy of Dance",
    "0265": "0265 SAE Education Limited",
    "0266": "0266 Slough Borough Council",
    "0267": "0267 Tottenham Hotspur Foundation",
    "0268": "0268 Access to Music Limited",
    "0269": "0269 Academy of Live and Recorded Arts",
    "0270": "0270 The College of Integrated Chinese Medicine",
    "0271": "0271 The City College",
    "0272": "0272 Met Film School Limited",
    "0274": "0274 London Studio Centre",
    "0275": "0275 West London College of Business and Management Sciences Limited",
    "0276": "0276 London School of Academics Ltd",
    "0277": "0277 London Centre of Contemporary Music",
    "0278": "0278 Regent College London",
    "0279": "0279 London School of Business and Finance (UK) Limited",
    "0280": "0280 Brit College",
    "0281": "0281 Futureworks",
    "0282": "0282 London Bridge Business Academy",
    "0283": "0283 St Patrick's International College",
    "0284": "0284 Chicken Shed Theatre Company",
    "0285": "0285 All Nations Christian College",
    "0287": "0287 Bristol Baptist College",
    "0288": "0288 The Cambridge Theological Federation",
    "0291": "0291 Christie's Education Limited",
    "0292": "0292 City and Guilds of London Art School",
    "0293": "0293 Cliff College",
    "0294": "0294 University College of Estate Management",
    "0295": "0295 Waverley Abbey College",
    "0297": "0297 ICOM",
    "0298": "0298 The Islamic College",
    "0299": "0299 Istituto Marangoni Limited",
    "0300": "0300 Kaplan Open Learning (Essex) Limited",
    "0301": "0301 KLC School of Design",
    "0302": "0302 Kogan Academy of Dramatic Arts",
    "0304": "0304 London Film School Limited",
    "0306": "0306 Luther King House Educational Trust",
    "0307": "0307 Open College of the Arts",
    "0308": "0308 Oxford Business College",
    "0309": "0309 Point Blank Music School",
    "0310": "0310 Spurgeon's College",
    "0311": "0311 St Nicholas Montessori Training Limited",
    "0312": "0312 West Dean College",
    "0313": "0313 Oak Hill College",
    "0314": "0314 The Metanoia Institute",
    "0315": "0315 The Queen's Foundation for Ecumenical Theological Education",
    "0316": "0316 The Salvation Army",
    "0317": "0317 Trinity College Bristol",
    "0319": "0319 Nova College of Accounting and Business Ltd",
    "0320": "0320 Matrix College of Counselling and Psychotherapy Ltd",
    "0321": "0321 The Minster Centre",
    "0322": "0322 The Sherwood Institute",
    "0323": "0323 AA School of Architecture",
    "0324": "0324 Ballet West",
    "0326": "0326 Court Theatre Training Company Ltd",
    "0327": "0327 Regents Theological College",
    "0328": "0328 London School of Commerce & IT Limited",
    "0329": "0329 Apex College",
    "0330": "0330 Northeastern University London",
    "0331": "0331 The Markfield Institute of Higher Education",
    "0332": "0332 Cambridge Arts and Sciences Limited",
    "0333": "0333 Arden University",
    "0334": "0334 Grŵp Llandrillo Menai",
    "0335": "0335 Grŵp NPTC Group",
    "0336": "0336 Gower College Swansea",
    "0337": "0337 The University College of Osteopathy",
    "0338": "0338 London College of Business Sciences",
    "0339": "0339 Millennium Performing Arts Ltd",
    "0340": "0340 London College of Creative Media Limited",
    "0341": "0341 International Business College Manchester Limited",
    "0342": "0342 Christ the Redeemer College",
    "0343": "0343 Waltham International College Limited",
    "0351": "0351 Leeds Conservatoire",
    "0352": "0352 Hartpury University",
    "0353": "0353 Amity Global Education",
    "0354": "0354 The London School of Architecture",
    "0355": "0355 London College of International Business Studies Ltd",
    "0356": "0356 LCCM AU UK Limited",
    "0357": "0357 ACM Guildford Limited",
    "0359": "0359 The Film Education Training Trust Limited",
    "0361": "0361 Newbold College",
    "0363": "0363 Global Banking School Limited",
    "0401": "0401 LAMDA Limited",
    "0402": "0402 Royal Academy of Dramatic Art",
    "0405": "0405 Institute of Art - London Limited",
    "0407": "0407 The Sherwood Psychotherapy Training Institute Limited",
    "0408": "0408 University Centre Peterborough",
    "0409": "0409 Central Film School London",
    "0410": "0410 ESCP Europe Business School",
    "0412": "0412 The Council of the Inns of Court",
    "0416": "0416 Results Consortium Limited",
    "0418": "0418 University Centre Quayside Limited",
    "0419": "0419 David Game College Ltd",
    "0420": "0420 Dartington Hall Trust (The)",
    "0421": "0421 New Model Institute for Technology and Engineering (NMITE)",
    "0422": "0422 Kaplan International Colleges U.K. Limited",
    "0423": "0423 INTO University Partnerships Limited",
    "0424": "0424 The College of Osteopaths",
    "0425": "0425 Regent's University London Limited",
    "0426": "0426 Academy of Live Technology Ltd",
    "0427": "0427 British Academy of Jewellery Limited",
    "0429": "0429 Raindance Educational Services Limited",
    "0430": "0430 The College of Health Ltd",
    "0431": "0431 CEG UFP Limited",
    "0432": "0432 Applied Business Academy Limited",
    "0435": "0435 The Engineering and Design Institute London",
    "0437": "0437 ThinkSpace Education Limited",
    "0441": "0441 Paris Dauphine International",
    "0443": "0443 Rambert School",
    "0445": "0445 National Centre for Circus Arts",
    "0446": "0446 Central School of Ballet",
};

export const henoouMapping: DomainMappingType =
{
    "0002 Cranfield University": "0002 Cranfield University",
    "0003 Royal College of Art": "0003 Royal College of Art",
    "0007 Bishop Grosseteste University": "0007 Bishop Grosseteste University",
    "0009 Buckinghamshire New University": "0009 Buckinghamshire New University",
    "0010 The Royal Central School of Speech and Drama": "0010 The Royal Central School of Speech and Drama",
    "0011 University of Chester": "0011 University of Chester",
    "0012 Canterbury Christ Church University": "0012 Canterbury Christ Church University",
    "0013 York St John University": "0013 York St John University",
    "0014 University of St Mark and St John": "0014 University of St Mark and St John",
    "0016 Edge Hill University": "0016 Edge Hill University",
    "0017 Falmouth University": "0017 Falmouth University",
    "0018 Harper Adams University": "0018 Harper Adams University",
    "0021 The University of Winchester": "0021 The University of Winchester",
    "0023 Liverpool Hope University": "0023 Liverpool Hope University",
    "0024 University of the Arts, London": "0024 University of the Arts, London",
    "0026 University of Bedfordshire": "0026 University of Bedfordshire",
    "0027 The University of Northampton": "0027 The University of Northampton",
    "0028 Birmingham Newman University": "0028 Birmingham Newman University",
    "0030 Ravensbourne University London": "0030 Ravensbourne University London",
    "0031 Roehampton University": "0031 Roehampton University",
    "0032 Rose Bruford College of Theatre and Performance": "0032 Rose Bruford College of Theatre and Performance",
    "0033 Royal Academy of Music": "0033 Royal Academy of Music",
    "0034 Royal College of Music": "0034 Royal College of Music",
    "0035 Royal Northern College of Music": "0035 Royal Northern College of Music",
    "0037 Solent University": "0037 Solent University",
    "0038 University of Cumbria": "0038 University of Cumbria",
    "0039 St Mary's University, Twickenham": "0039 St Mary's University, Twickenham",
    "0040 Leeds Trinity University": "0040 Leeds Trinity University",
    "0041 Trinity Laban Conservatoire of Music and Dance": "0041 Trinity Laban Conservatoire of Music and Dance",
    "0046 University of Worcester": "0046 University of Worcester",
    "0047 Anglia Ruskin University": "0047 Anglia Ruskin University",
    "0048 Bath Spa University": "0048 Bath Spa University",
    "0049 The University of Bolton": "0049 The University of Bolton",
    "0050 Bournemouth University": "0050 Bournemouth University",
    "0051 The University of Brighton": "0051 The University of Brighton",
    "0052 Birmingham City University": "0052 Birmingham City University",
    "0053 The University of Central Lancashire": "0053 The University of Central Lancashire",
    "0054 University of Gloucestershire": "0054 University of Gloucestershire",
    "0056 Coventry University": "0056 Coventry University",
    "0057 University of Derby": "0057 University of Derby",
    "0058 The University of East London": "0058 The University of East London",
    "0059 The University of Greenwich": "0059 The University of Greenwich",
    "0060 University of Hertfordshire": "0060 University of Hertfordshire",
    "0061 The University of Huddersfield": "0061 The University of Huddersfield",
    "0062 The University of Lincoln": "0062 The University of Lincoln",
    "0063 Kingston University": "0063 Kingston University",
    "0064 Leeds Beckett University": "0064 Leeds Beckett University",
    "0065 Liverpool John Moores University": "0065 Liverpool John Moores University",
    "0066 The Manchester Metropolitan University": "0066 The Manchester Metropolitan University",
    "0067 Middlesex University": "0067 Middlesex University",
    "0068 De Montfort University": "0068 De Montfort University",
    "0069 University of Northumbria at Newcastle": "0069 University of Northumbria at Newcastle",
    "0071 The Nottingham Trent University": "0071 The Nottingham Trent University",
    "0072 Oxford Brookes University": "0072 Oxford Brookes University",
    "0073 University of Plymouth": "0073 University of Plymouth",
    "0074 The University of Portsmouth": "0074 The University of Portsmouth",
    "0075 Sheffield Hallam University": "0075 Sheffield Hallam University",
    "0076 London South Bank University": "0076 London South Bank University",
    "0077 Staffordshire University": "0077 Staffordshire University",
    "0078 The University of Sunderland": "0078 The University of Sunderland",
    "0079 Teesside University": "0079 Teesside University",
    "0080 The University of West London": "0080 The University of West London",
    "0081 University of the West of England, Bristol": "0081 University of the West of England, Bristol",
    "0082 The University of Chichester": "0082 The University of Chichester",
    "0083 The University of Westminster": "0083 The University of Westminster",
    "0085 The University of Wolverhampton": "0085 The University of Wolverhampton",
    "0087 Wrexham University": "0087 Wrexham University",
    "0089 Cardiff Metropolitan University": "0089 Cardiff Metropolitan University",
    "0090 University of South Wales": "0090 University of South Wales",
    "0095 Abertay University": "0095 Abertay University",
    "0097 Glasgow School of Art": "0097 Glasgow School of Art",
    "0100 Queen Margaret University, Edinburgh": "0100 Queen Margaret University, Edinburgh",
    "0101 Royal Conservatoire of Scotland": "0101 Royal Conservatoire of Scotland",
    "0104 Robert Gordon University": "0104 Robert Gordon University",
    "0105 The University of the West of Scotland": "0105 The University of the West of Scotland",
    "0106 Glasgow Caledonian University": "0106 Glasgow Caledonian University",
    "0107 Edinburgh Napier University": "0107 Edinburgh Napier University",
    "0108 Aston University": "0108 Aston University",
    "0109 The University of Bath": "0109 The University of Bath",
    "0110 The University of Birmingham": "0110 The University of Birmingham",
    "0111 The University of Bradford": "0111 The University of Bradford",
    "0112 The University of Bristol": "0112 The University of Bristol",
    "0113 Brunel University London": "0113 Brunel University London",
    "0114 The University of Cambridge": "0114 The University of Cambridge",
    "0115 City, University of London": "0115 City, University of London",
    "0116 University of Durham": "0116 University of Durham",
    "0117 The University of East Anglia": "0117 The University of East Anglia",
    "0118 The University of Essex": "0118 The University of Essex",
    "0119 The University of Exeter": "0119 The University of Exeter",
    "0120 The University of Hull": "0120 The University of Hull",
    "0121 Keele University": "0121 Keele University",
    "0122 The University of Kent": "0122 The University of Kent",
    "0123 The University of Lancaster": "0123 The University of Lancaster",
    "0124 The University of Leeds": "0124 The University of Leeds",
    "0125 The University of Leicester": "0125 The University of Leicester",
    "0126 The University of Liverpool": "0126 The University of Liverpool",
    "0127 Birkbeck College": "0127 Birkbeck College",
    "0131 Goldsmiths College": "0131 Goldsmiths College",
    "0132 Imperial College of Science, Technology and Medicine": "0132 Imperial College of Science, Technology and Medicine",
    "0134 King's College London": "0134 King's College London",
    "0135 London Business School": "0135 London Business School",
    "0137 London School of Economics and Political Science": "0137 London School of Economics and Political Science",
    "0138 London School of Hygiene and Tropical Medicine": "0138 London School of Hygiene and Tropical Medicine",
    "0139 Queen Mary University of London": "0139 Queen Mary University of London",
    "0141 Royal Holloway and Bedford New College": "0141 Royal Holloway and Bedford New College",
    "0143 The Royal Veterinary College": "0143 The Royal Veterinary College",
    "0145 St George's, University of London": "0145 St George's, University of London",
    "0146 SOAS University of London": "0146 SOAS University of London",
    "0149 University College London": "0149 University College London",
    "0151 University of London (Institutes and activities)": "0151 University of London (Institutes and activities)",
    "0152 Loughborough University": "0152 Loughborough University",
    "0154 Newcastle University": "0154 Newcastle University",
    "0155 University of Nottingham": "0155 University of Nottingham",
    "0156 The University of Oxford": "0156 The University of Oxford",
    "0157 The University of Reading": "0157 The University of Reading",
    "0158 The University of Salford": "0158 The University of Salford",
    "0159 The University of Sheffield": "0159 The University of Sheffield",
    "0160 The University of Southampton": "0160 The University of Southampton",
    "0161 The University of Surrey": "0161 The University of Surrey",
    "0162 The University of Sussex": "0162 The University of Sussex",
    "0163 The University of Warwick": "0163 The University of Warwick",
    "0164 The University of York": "0164 The University of York",
    "0167 The University of Edinburgh": "0167 The University of Edinburgh",
    "0168 The University of Glasgow": "0168 The University of Glasgow",
    "0169 The University of Strathclyde": "0169 The University of Strathclyde",
    "0170 The University of Aberdeen": "0170 The University of Aberdeen",
    "0171 Heriot-Watt University": "0171 Heriot-Watt University",
    "0172 The University of Dundee": "0172 The University of Dundee",
    "0173 The University of St. Andrews": "0173 The University of St. Andrews",
    "0174 The University of Stirling": "0174 The University of Stirling",
    "0175 SRUC": "0175 SRUC",
    "0176 University of Wales Trinity Saint David": "0176 University of Wales Trinity Saint David",
    "0177 Aberystwyth University": "0177 Aberystwyth University",
    "0178 Bangor University": "0178 Bangor University",
    "0179 Cardiff University": "0179 Cardiff University",
    "0180 Swansea University": "0180 Swansea University",
    "0184 Queen's University Belfast": "0184 Queen's University Belfast",
    "0185 Ulster University": "0185 Ulster University",
    "0186 The University of Wales (central functions)": "0186 The University of Wales (central functions)",
    "0188 The Institute of Cancer Research": "0188 The Institute of Cancer Research",
    "0189 Writtle University College": "0189 Writtle University College",
    "0190 Norwich University of the Arts": "0190 Norwich University of the Arts",
    "0191 Northern School of Contemporary Dance": "0191 Northern School of Contemporary Dance",
    "0193 Stranmillis University College": "0193 Stranmillis University College",
    "0194 St Mary's University College": "0194 St Mary's University College",
    "0195 Royal Agricultural University": "0195 Royal Agricultural University",
    "0196 University of the Highlands and Islands": "0196 University of the Highlands and Islands",
    "0197 The Arts University Bournemouth": "0197 The Arts University Bournemouth",
    "0199 Conservatoire for Dance and Drama": "0199 Conservatoire for Dance and Drama",
    "0200 University College Birmingham": "0200 University College Birmingham",
    "0201 Courtauld Institute of Art": "0201 Courtauld Institute of Art",
    "0202 London Metropolitan University": "0202 London Metropolitan University",
    "0203 The University of Buckingham": "0203 The University of Buckingham",
    "0204 The University of Manchester": "0204 The University of Manchester",
    "0205 Heythrop College": "0205 Heythrop College",
    "0206 University for the Creative Arts": "0206 University for the Creative Arts",
    "0208 Guildhall School of Music and Drama": "0208 Guildhall School of Music and Drama",
    "0209 The Liverpool Institute for Performing Arts": "0209 The Liverpool Institute for Performing Arts",
    "0210 University of Suffolk": "0210 University of Suffolk",
    "0211 Leeds Arts University": "0211 Leeds Arts University",
    "0213 AECC University College": "0213 AECC University College",
    "0214 BPP University": "0214 BPP University",
    "0215 GSM London": "0215 GSM London",
    "0216 Regent's University London": "0216 Regent's University London",
    "0217 The London Institute of Banking & Finance": "0217 The London Institute of Banking & Finance",
    "0218 The University of Law": "0218 The University of Law",
    "0219 Richmond, The American International University in London": "0219 Richmond, The American International University in London",
    "0220 Institute of Contemporary Music Performance": "0220 Institute of Contemporary Music Performance",
    "0221 Moorlands College": "0221 Moorlands College",
    "0225 Missio Dei": "0225 Missio Dei",
    "0227 London School of Science and Technology Limited": "0227 London School of Science and Technology Limited",
    "0228 Liverpool School of Tropical Medicine": "0228 Liverpool School of Tropical Medicine",
    "0229 The National Film and Television School": "0229 The National Film and Television School",
    "0230 Arts University Plymouth": "0230 Arts University Plymouth",
    "0231 ABI College Limited": "0231 ABI College Limited",
    "0232 BIMM University": "0232 BIMM University",
    "0233 West London College": "0233 West London College",
    "0234 City of London College": "0234 City of London College",
    "0235 EThames Graduate School Limited": "0235 EThames Graduate School Limited",
    "0236 ICON College of Technology and Management": "0236 ICON College of Technology and Management",
    "0237 Kensington College of Business": "0237 Kensington College of Business",
    "0238 Bloomsbury Institute": "0238 Bloomsbury Institute",
    "0239 Mountview Academy of Theatre Arts": "0239 Mountview Academy of Theatre Arts",
    "0240 Nazarene Theological College": "0240 Nazarene Theological College",
    "0241 Stratford College London": "0241 Stratford College London",
    "0243 The London College": "0243 The London College",
    "0244 Arts Educational Schools": "0244 Arts Educational Schools",
    "0245 Grafton College": "0245 Grafton College",
    "0246 Mont Rose College of Management and Sciences": "0246 Mont Rose College of Management and Sciences",
    "0247 Norland College": "0247 Norland College",
    "0248 ForMission Ltd": "0248 ForMission Ltd",
    "0249 UK College of Business and Computing": "0249 UK College of Business and Computing",
    "0250 London Churchill College Ltd": "0250 London Churchill College Ltd",
    "0251 The Academy of Contemporary Music": "0251 The Academy of Contemporary Music",
    "0252 Empire College London Limited": "0252 Empire College London Limited",
    "0253 East End Computing and Business College Limited": "0253 East End Computing and Business College Limited",
    "0254 Fairfield School of Business Ltd": "0254 Fairfield School of Business Ltd",
    "0255 London College of Business Studies": "0255 London College of Business Studies",
    "0256 London School of Management Education": "0256 London School of Management Education",
    "0257 London School of Theology": "0257 London School of Theology",
    "0258 St Mellitus College": "0258 St Mellitus College",
    "0260 Nelson College London Ltd": "0260 Nelson College London Ltd",
    "0261 Northern College of Acupuncture": "0261 Northern College of Acupuncture",
    "0262 Escape Studios": "0262 Escape Studios",
    "0263 Royal Academy of Dance": "0263 Royal Academy of Dance",
    "0265 SAE Education Limited": "0265 SAE Education Limited",
    "0266 Slough Borough Council": "0266 Slough Borough Council",
    "0267 Tottenham Hotspur Foundation": "0267 Tottenham Hotspur Foundation",
    "0268 Access to Music Limited": "0268 Access to Music Limited",
    "0269 Academy of Live and Recorded Arts": "0269 Academy of Live and Recorded Arts",
    "0270 The College of Integrated Chinese Medicine": "0270 The College of Integrated Chinese Medicine",
    "0271 The City College": "0271 The City College",
    "0272 Met Film School Limited": "0272 Met Film School Limited",
    "0274 London Studio Centre": "0274 London Studio Centre",
    "0275 West London College of Business and Management Sciences Limited": "0275 West London College of Business and Management Sciences Limited",
    "0276 London School of Academics Ltd": "0276 London School of Academics Ltd",
    "0277 London Centre of Contemporary Music": "0277 London Centre of Contemporary Music",
    "0278 Regent College London": "0278 Regent College London",
    "0279 London School of Business and Finance (UK) Limited": "0279 London School of Business and Finance (UK) Limited",
    "0280 Brit College": "0280 Brit College",
    "0281 Futureworks": "0281 Futureworks",
    "0282 London Bridge Business Academy": "0282 London Bridge Business Academy",
    "0283 St Patrick's International College": "0283 St Patrick's International College",
    "0284 Chicken Shed Theatre Company": "0284 Chicken Shed Theatre Company",
    "0285 All Nations Christian College": "0285 All Nations Christian College",
    "0287 Bristol Baptist College": "0287 Bristol Baptist College",
    "0288 The Cambridge Theological Federation": "0288 The Cambridge Theological Federation",
    "0291 Christie's Education Limited": "0291 Christie's Education Limited",
    "0292 City and Guilds of London Art School": "0292 City and Guilds of London Art School",
    "0293 Cliff College": "0293 Cliff College",
    "0294 University College of Estate Management": "0294 University College of Estate Management",
    "0295 Waverley Abbey College": "0295 Waverley Abbey College",
    "0297 ICOM": "0297 ICOM",
    "0298 The Islamic College": "0298 The Islamic College",
    "0299 Istituto Marangoni Limited": "0299 Istituto Marangoni Limited",
    "0300 Kaplan Open Learning (Essex) Limited": "0300 Kaplan Open Learning (Essex) Limited",
    "0301 KLC School of Design": "0301 KLC School of Design",
    "0302 Kogan Academy of Dramatic Arts": "0302 Kogan Academy of Dramatic Arts",
    "0304 London Film School Limited": "0304 London Film School Limited",
    "0306 Luther King House Educational Trust": "0306 Luther King House Educational Trust",
    "0307 Open College of the Arts": "0307 Open College of the Arts",
    "0308 Oxford Business College": "0308 Oxford Business College",
    "0309 Point Blank Music School": "0309 Point Blank Music School",
    "0310 Spurgeon's College": "0310 Spurgeon's College",
    "0311 St Nicholas Montessori Training Limited": "0311 St Nicholas Montessori Training Limited",
    "0312 West Dean College": "0312 West Dean College",
    "0313 Oak Hill College": "0313 Oak Hill College",
    "0314 The Metanoia Institute": "0314 The Metanoia Institute",
    "0315 The Queen's Foundation for Ecumenical Theological Education": "0315 The Queen's Foundation for Ecumenical Theological Education",
    "0316 The Salvation Army": "0316 The Salvation Army",
    "0317 Trinity College Bristol": "0317 Trinity College Bristol",
    "0319 Nova College of Accounting and Business Ltd": "0319 Nova College of Accounting and Business Ltd",
    "0320 Matrix College of Counselling and Psychotherapy Ltd": "0320 Matrix College of Counselling and Psychotherapy Ltd",
    "0321 The Minster Centre": "0321 The Minster Centre",
    "0322 The Sherwood Institute": "0322 The Sherwood Institute",
    "0323 AA School of Architecture": "0323 AA School of Architecture",
    "0324 Ballet West": "0324 Ballet West",
    "0326 Court Theatre Training Company Ltd": "0326 Court Theatre Training Company Ltd",
    "0327 Regents Theological College": "0327 Regents Theological College",
    "0328 London School of Commerce & IT Limited": "0328 London School of Commerce & IT Limited",
    "0329 Apex College": "0329 Apex College",
    "0330 Northeastern University London": "0330 Northeastern University London",
    "0331 The Markfield Institute of Higher Education": "0331 The Markfield Institute of Higher Education",
    "0332 Cambridge Arts and Sciences Limited": "0332 Cambridge Arts and Sciences Limited",
    "0333 Arden University": "0333 Arden University",
    "0334 Grŵp Llandrillo Menai": "0334 Grŵp Llandrillo Menai",
    "0335 Grŵp NPTC Group": "0335 Grŵp NPTC Group",
    "0336 Gower College Swansea": "0336 Gower College Swansea",
    "0337 The University College of Osteopathy": "0337 The University College of Osteopathy",
    "0338 London College of Business Sciences": "0338 London College of Business Sciences",
    "0339 Millennium Performing Arts Ltd": "0339 Millennium Performing Arts Ltd",
    "0340 London College of Creative Media Limited": "0340 London College of Creative Media Limited",
    "0341 International Business College Manchester Limited": "0341 International Business College Manchester Limited",
    "0342 Christ the Redeemer College": "0342 Christ the Redeemer College",
    "0343 Waltham International College Limited": "0343 Waltham International College Limited",
    "0351 Leeds Conservatoire": "0351 Leeds Conservatoire",
    "0352 Hartpury University": "0352 Hartpury University",
    "0353 Amity Global Education": "0353 Amity Global Education",
    "0354 The London School of Architecture": "0354 The London School of Architecture",
    "0355 London College of International Business Studies Ltd": "0355 London College of International Business Studies Ltd",
    "0356 LCCM AU UK Limited": "0356 LCCM AU UK Limited",
    "0357 ACM Guildford Limited": "0357 ACM Guildford Limited",
    "0359 The Film Education Training Trust Limited": "0359 The Film Education Training Trust Limited",
    "0361 Newbold College": "0361 Newbold College",
    "0363 Global Banking School Limited": "0363 Global Banking School Limited",
    "0364 The Open University in England": "0364 The Open University in England",
    "0365 The Open University in Northern Ireland": "0365 The Open University in Northern Ireland",
    "0366 The Open University in Scotland": "0366 The Open University in Scotland",
    "0367 The Open University in Wales": "0367 The Open University in Wales",
    "0401 LAMDA Limited": "0401 LAMDA Limited",
    "0402 Royal Academy of Dramatic Art": "0402 Royal Academy of Dramatic Art",
    "0405 Institute of Art - London Limited": "0405 Institute of Art - London Limited",
    "0407 The Sherwood Psychotherapy Training Institute Limited": "0407 The Sherwood Psychotherapy Training Institute Limited",
    "0408 University Centre Peterborough": "0408 University Centre Peterborough",
    "0409 Central Film School London": "0409 Central Film School London",
    "0410 ESCP Europe Business School": "0410 ESCP Europe Business School",
    "0412 The Council of the Inns of Court": "0412 The Council of the Inns of Court",
    "0416 Results Consortium Limited": "0416 Results Consortium Limited",
    "0418 University Centre Quayside Limited": "0418 University Centre Quayside Limited",
    "0419 David Game College Ltd": "0419 David Game College Ltd",
    "0420 Dartington Hall Trust (The)": "0420 Dartington Hall Trust (The)",
    "0421 New Model Institute for Technology and Engineering (NMITE)": "0421 New Model Institute for Technology and Engineering (NMITE)",
    "0422 Kaplan International Colleges U.K. Limited": "0422 Kaplan International Colleges U.K. Limited",
    "0423 INTO University Partnerships Limited": "0423 INTO University Partnerships Limited",
    "0424 The College of Osteopaths": "0424 The College of Osteopaths",
    "0425 Regent's University London Limited": "0425 Regent's University London Limited",
    "0426 Academy of Live Technology Ltd": "0426 Academy of Live Technology Ltd",
    "0427 British Academy of Jewellery Limited": "0427 British Academy of Jewellery Limited",
    "0429 Raindance Educational Services Limited": "0429 Raindance Educational Services Limited",
    "0430 The College of Health Ltd": "0430 The College of Health Ltd",
    "0431 CEG UFP Limited": "0431 CEG UFP Limited",
    "0432 Applied Business Academy Limited": "0432 Applied Business Academy Limited",
    "0435 The Engineering and Design Institute London": "0435 The Engineering and Design Institute London",
    "0437 ThinkSpace Education Limited": "0437 ThinkSpace Education Limited",
    "0441 Paris Dauphine International": "0441 Paris Dauphine International",
    "0443 Rambert School": "0443 Rambert School",
    "0445 National Centre for Circus Arts": "0445 National Centre for Circus Arts",
    "0446 Central School of Ballet": "0446 Central School of Ballet",
    "0002": "0002 Cranfield University",
    "0003": "0003 Royal College of Art",
    "0007": "0007 Bishop Grosseteste University",
    "0009": "0009 Buckinghamshire New University",
    "0010": "0010 The Royal Central School of Speech and Drama",
    "0011": "0011 University of Chester",
    "0012": "0012 Canterbury Christ Church University",
    "0013": "0013 York St John University",
    "0014": "0014 University of St Mark and St John",
    "0016": "0016 Edge Hill University",
    "0017": "0017 Falmouth University",
    "0018": "0018 Harper Adams University",
    "0021": "0021 The University of Winchester",
    "0023": "0023 Liverpool Hope University",
    "0024": "0024 University of the Arts, London",
    "0026": "0026 University of Bedfordshire",
    "0027": "0027 The University of Northampton",
    "0028": "0028 Birmingham Newman University",
    "0030": "0030 Ravensbourne University London",
    "0031": "0031 Roehampton University",
    "0032": "0032 Rose Bruford College of Theatre and Performance",
    "0033": "0033 Royal Academy of Music",
    "0034": "0034 Royal College of Music",
    "0035": "0035 Royal Northern College of Music",
    "0037": "0037 Solent University",
    "0038": "0038 University of Cumbria",
    "0039": "0039 St Mary's University, Twickenham",
    "0040": "0040 Leeds Trinity University",
    "0041": "0041 Trinity Laban Conservatoire of Music and Dance",
    "0046": "0046 University of Worcester",
    "0047": "0047 Anglia Ruskin University",
    "0048": "0048 Bath Spa University",
    "0049": "0049 The University of Bolton",
    "0050": "0050 Bournemouth University",
    "0051": "0051 The University of Brighton",
    "0052": "0052 Birmingham City University",
    "0053": "0053 The University of Central Lancashire",
    "0054": "0054 University of Gloucestershire",
    "0056": "0056 Coventry University",
    "0057": "0057 University of Derby",
    "0058": "0058 The University of East London",
    "0059": "0059 The University of Greenwich",
    "0060": "0060 University of Hertfordshire",
    "0061": "0061 The University of Huddersfield",
    "0062": "0062 The University of Lincoln",
    "0063": "0063 Kingston University",
    "0064": "0064 Leeds Beckett University",
    "0065": "0065 Liverpool John Moores University",
    "0066": "0066 The Manchester Metropolitan University",
    "0067": "0067 Middlesex University",
    "0068": "0068 De Montfort University",
    "0069": "0069 University of Northumbria at Newcastle",
    "0071": "0071 The Nottingham Trent University",
    "0072": "0072 Oxford Brookes University",
    "0073": "0073 University of Plymouth",
    "0074": "0074 The University of Portsmouth",
    "0075": "0075 Sheffield Hallam University",
    "0076": "0076 London South Bank University",
    "0077": "0077 Staffordshire University",
    "0078": "0078 The University of Sunderland",
    "0079": "0079 Teesside University",
    "0080": "0080 The University of West London",
    "0081": "0081 University of the West of England, Bristol",
    "0082": "0082 The University of Chichester",
    "0083": "0083 The University of Westminster",
    "0085": "0085 The University of Wolverhampton",
    "0087": "0087 Wrexham University",
    "0089": "0089 Cardiff Metropolitan University",
    "0090": "0090 University of South Wales",
    "0095": "0095 Abertay University",
    "0097": "0097 Glasgow School of Art",
    "0100": "0100 Queen Margaret University, Edinburgh",
    "0101": "0101 Royal Conservatoire of Scotland",
    "0104": "0104 Robert Gordon University",
    "0105": "0105 The University of the West of Scotland",
    "0106": "0106 Glasgow Caledonian University",
    "0107": "0107 Edinburgh Napier University",
    "0108": "0108 Aston University",
    "0109": "0109 The University of Bath",
    "0110": "0110 The University of Birmingham",
    "0111": "0111 The University of Bradford",
    "0112": "0112 The University of Bristol",
    "0113": "0113 Brunel University London",
    "0114": "0114 The University of Cambridge",
    "0115": "0115 City, University of London",
    "0116": "0116 University of Durham",
    "0117": "0117 The University of East Anglia",
    "0118": "0118 The University of Essex",
    "0119": "0119 The University of Exeter",
    "0120": "0120 The University of Hull",
    "0121": "0121 Keele University",
    "0122": "0122 The University of Kent",
    "0123": "0123 The University of Lancaster",
    "0124": "0124 The University of Leeds",
    "0125": "0125 The University of Leicester",
    "0126": "0126 The University of Liverpool",
    "0127": "0127 Birkbeck College",
    "0131": "0131 Goldsmiths College",
    "0132": "0132 Imperial College of Science, Technology and Medicine",
    "0134": "0134 King's College London",
    "0135": "0135 London Business School",
    "0137": "0137 London School of Economics and Political Science",
    "0138": "0138 London School of Hygiene and Tropical Medicine",
    "0139": "0139 Queen Mary University of London",
    "0141": "0141 Royal Holloway and Bedford New College",
    "0143": "0143 The Royal Veterinary College",
    "0145": "0145 St George's, University of London",
    "0146": "0146 SOAS University of London",
    "0149": "0149 University College London",
    "0151": "0151 University of London (Institutes and activities)",
    "0152": "0152 Loughborough University",
    "0154": "0154 Newcastle University",
    "0155": "0155 University of Nottingham",
    "0156": "0156 The University of Oxford",
    "0157": "0157 The University of Reading",
    "0158": "0158 The University of Salford",
    "0159": "0159 The University of Sheffield",
    "0160": "0160 The University of Southampton",
    "0161": "0161 The University of Surrey",
    "0162": "0162 The University of Sussex",
    "0163": "0163 The University of Warwick",
    "0164": "0164 The University of York",
    "0167": "0167 The University of Edinburgh",
    "0168": "0168 The University of Glasgow",
    "0169": "0169 The University of Strathclyde",
    "0170": "0170 The University of Aberdeen",
    "0171": "0171 Heriot-Watt University",
    "0172": "0172 The University of Dundee",
    "0173": "0173 The University of St. Andrews",
    "0174": "0174 The University of Stirling",
    "0175": "0175 SRUC",
    "0176": "0176 University of Wales Trinity Saint David",
    "0177": "0177 Aberystwyth University",
    "0178": "0178 Bangor University",
    "0179": "0179 Cardiff University",
    "0180": "0180 Swansea University",
    "0184": "0184 Queen's University Belfast",
    "0185": "0185 Ulster University",
    "0186": "0186 The University of Wales (central functions)",
    "0188": "0188 The Institute of Cancer Research",
    "0189": "0189 Writtle University College",
    "0190": "0190 Norwich University of the Arts",
    "0191": "0191 Northern School of Contemporary Dance",
    "0193": "0193 Stranmillis University College",
    "0194": "0194 St Mary's University College",
    "0195": "0195 Royal Agricultural University",
    "0196": "0196 University of the Highlands and Islands",
    "0197": "0197 The Arts University Bournemouth",
    "0199": "0199 Conservatoire for Dance and Drama",
    "0200": "0200 University College Birmingham",
    "0201": "0201 Courtauld Institute of Art",
    "0202": "0202 London Metropolitan University",
    "0203": "0203 The University of Buckingham",
    "0204": "0204 The University of Manchester",
    "0205": "0205 Heythrop College",
    "0206": "0206 University for the Creative Arts",
    "0208": "0208 Guildhall School of Music and Drama",
    "0209": "0209 The Liverpool Institute for Performing Arts",
    "0210": "0210 University of Suffolk",
    "0211": "0211 Leeds Arts University",
    "0213": "0213 AECC University College",
    "0214": "0214 BPP University",
    "0215": "0215 GSM London",
    "0216": "0216 Regent's University London",
    "0217": "0217 The London Institute of Banking & Finance",
    "0218": "0218 The University of Law",
    "0219": "0219 Richmond, The American International University in London",
    "0220": "0220 Institute of Contemporary Music Performance",
    "0221": "0221 Moorlands College",
    "0225": "0225 Missio Dei",
    "0227": "0227 London School of Science and Technology Limited",
    "0228": "0228 Liverpool School of Tropical Medicine",
    "0229": "0229 The National Film and Television School",
    "0230": "0230 Arts University Plymouth",
    "0231": "0231 ABI College Limited",
    "0232": "0232 BIMM University",
    "0233": "0233 West London College",
    "0234": "0234 City of London College",
    "0235": "0235 EThames Graduate School Limited",
    "0236": "0236 ICON College of Technology and Management",
    "0237": "0237 Kensington College of Business",
    "0238": "0238 Bloomsbury Institute",
    "0239": "0239 Mountview Academy of Theatre Arts",
    "0240": "0240 Nazarene Theological College",
    "0241": "0241 Stratford College London",
    "0243": "0243 The London College",
    "0244": "0244 Arts Educational Schools",
    "0245": "0245 Grafton College",
    "0246": "0246 Mont Rose College of Management and Sciences",
    "0247": "0247 Norland College",
    "0248": "0248 ForMission Ltd",
    "0249": "0249 UK College of Business and Computing",
    "0250": "0250 London Churchill College Ltd",
    "0251": "0251 The Academy of Contemporary Music",
    "0252": "0252 Empire College London Limited",
    "0253": "0253 East End Computing and Business College Limited",
    "0254": "0254 Fairfield School of Business Ltd",
    "0255": "0255 London College of Business Studies",
    "0256": "0256 London School of Management Education",
    "0257": "0257 London School of Theology",
    "0258": "0258 St Mellitus College",
    "0260": "0260 Nelson College London Ltd",
    "0261": "0261 Northern College of Acupuncture",
    "0262": "0262 Escape Studios",
    "0263": "0263 Royal Academy of Dance",
    "0265": "0265 SAE Education Limited",
    "0266": "0266 Slough Borough Council",
    "0267": "0267 Tottenham Hotspur Foundation",
    "0268": "0268 Access to Music Limited",
    "0269": "0269 Academy of Live and Recorded Arts",
    "0270": "0270 The College of Integrated Chinese Medicine",
    "0271": "0271 The City College",
    "0272": "0272 Met Film School Limited",
    "0274": "0274 London Studio Centre",
    "0275": "0275 West London College of Business and Management Sciences Limited",
    "0276": "0276 London School of Academics Ltd",
    "0277": "0277 London Centre of Contemporary Music",
    "0278": "0278 Regent College London",
    "0279": "0279 London School of Business and Finance (UK) Limited",
    "0280": "0280 Brit College",
    "0281": "0281 Futureworks",
    "0282": "0282 London Bridge Business Academy",
    "0283": "0283 St Patrick's International College",
    "0284": "0284 Chicken Shed Theatre Company",
    "0285": "0285 All Nations Christian College",
    "0287": "0287 Bristol Baptist College",
    "0288": "0288 The Cambridge Theological Federation",
    "0291": "0291 Christie's Education Limited",
    "0292": "0292 City and Guilds of London Art School",
    "0293": "0293 Cliff College",
    "0294": "0294 University College of Estate Management",
    "0295": "0295 Waverley Abbey College",
    "0297": "0297 ICOM",
    "0298": "0298 The Islamic College",
    "0299": "0299 Istituto Marangoni Limited",
    "0300": "0300 Kaplan Open Learning (Essex) Limited",
    "0301": "0301 KLC School of Design",
    "0302": "0302 Kogan Academy of Dramatic Arts",
    "0304": "0304 London Film School Limited",
    "0306": "0306 Luther King House Educational Trust",
    "0307": "0307 Open College of the Arts",
    "0308": "0308 Oxford Business College",
    "0309": "0309 Point Blank Music School",
    "0310": "0310 Spurgeon's College",
    "0311": "0311 St Nicholas Montessori Training Limited",
    "0312": "0312 West Dean College",
    "0313": "0313 Oak Hill College",
    "0314": "0314 The Metanoia Institute",
    "0315": "0315 The Queen's Foundation for Ecumenical Theological Education",
    "0316": "0316 The Salvation Army",
    "0317": "0317 Trinity College Bristol",
    "0319": "0319 Nova College of Accounting and Business Ltd",
    "0320": "0320 Matrix College of Counselling and Psychotherapy Ltd",
    "0321": "0321 The Minster Centre",
    "0322": "0322 The Sherwood Institute",
    "0323": "0323 AA School of Architecture",
    "0324": "0324 Ballet West",
    "0326": "0326 Court Theatre Training Company Ltd",
    "0327": "0327 Regents Theological College",
    "0328": "0328 London School of Commerce & IT Limited",
    "0329": "0329 Apex College",
    "0330": "0330 Northeastern University London",
    "0331": "0331 The Markfield Institute of Higher Education",
    "0332": "0332 Cambridge Arts and Sciences Limited",
    "0333": "0333 Arden University",
    "0334": "0334 Grŵp Llandrillo Menai",
    "0335": "0335 Grŵp NPTC Group",
    "0336": "0336 Gower College Swansea",
    "0337": "0337 The University College of Osteopathy",
    "0338": "0338 London College of Business Sciences",
    "0339": "0339 Millennium Performing Arts Ltd",
    "0340": "0340 London College of Creative Media Limited",
    "0341": "0341 International Business College Manchester Limited",
    "0342": "0342 Christ the Redeemer College",
    "0343": "0343 Waltham International College Limited",
    "0351": "0351 Leeds Conservatoire",
    "0352": "0352 Hartpury University",
    "0353": "0353 Amity Global Education",
    "0354": "0354 The London School of Architecture",
    "0355": "0355 London College of International Business Studies Ltd",
    "0356": "0356 LCCM AU UK Limited",
    "0357": "0357 ACM Guildford Limited",
    "0359": "0359 The Film Education Training Trust Limited",
    "0361": "0361 Newbold College",
    "0363": "0363 Global Banking School Limited",
    "0364": "0364 The Open University in England",
    "0365": "0365 The Open University in Northern Ireland",
    "0366": "0366 The Open University in Scotland",
    "0367": "0367 The Open University in Wales",
    "0401": "0401 LAMDA Limited",
    "0402": "0402 Royal Academy of Dramatic Art",
    "0405": "0405 Institute of Art - London Limited",
    "0407": "0407 The Sherwood Psychotherapy Training Institute Limited",
    "0408": "0408 University Centre Peterborough",
    "0409": "0409 Central Film School London",
    "0410": "0410 ESCP Europe Business School",
    "0412": "0412 The Council of the Inns of Court",
    "0416": "0416 Results Consortium Limited",
    "0418": "0418 University Centre Quayside Limited",
    "0419": "0419 David Game College Ltd",
    "0420": "0420 Dartington Hall Trust (The)",
    "0421": "0421 New Model Institute for Technology and Engineering (NMITE)",
    "0422": "0422 Kaplan International Colleges U.K. Limited",
    "0423": "0423 INTO University Partnerships Limited",
    "0424": "0424 The College of Osteopaths",
    "0425": "0425 Regent's University London Limited",
    "0426": "0426 Academy of Live Technology Ltd",
    "0427": "0427 British Academy of Jewellery Limited",
    "0429": "0429 Raindance Educational Services Limited",
    "0430": "0430 The College of Health Ltd",
    "0431": "0431 CEG UFP Limited",
    "0432": "0432 Applied Business Academy Limited",
    "0435": "0435 The Engineering and Design Institute London",
    "0437": "0437 ThinkSpace Education Limited",
    "0441": "0441 Paris Dauphine International",
    "0443": "0443 Rambert School",
    "0445": "0445 National Centre for Circus Arts",
    "0446": "0446 Central School of Ballet",
};