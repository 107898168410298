import { useContext } from 'react';
import { ConfirmContext, HIDE_CONFIRM, SHOW_CONFIRM } from '../contexts/confirm';

let resolveCallback: any;

function useConfirm() {
    // @ts-ignore
    const [confirmState, dispatch] = useContext(ConfirmContext);
    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };
    // @ts-ignore
    const confirm = text => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text
            }
        });
        return new Promise((resolve, reject) => {
            resolveCallback = resolve;
        });
    };

    const closeConfirm = () => {
        dispatch({
            type: HIDE_CONFIRM
        });
    };

    return { confirm, onConfirm, onCancel, confirmState };
}

export default useConfirm;