import {useContext, useState} from "react";
import {API} from "aws-amplify";
import {useProfile} from "./useProfile";
import {Providers} from "@microsoft/mgt-element";
import {CommonDispatchContext, showAlert} from "../contexts/common";
import {CartDispatchContext, clearCart} from "../contexts/cart";
import {useNavigate} from "react-router-dom";

/**
 * Hook for sending requests to the AWS SQS queue
 */
function useQueue() {
    const [profile] = useProfile()
    const provider = Providers.globalProvider;
    const [queue] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [isSending, setIsSending] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const dispatch = useContext(CommonDispatchContext)
    const cartDispatch = useContext(CartDispatchContext)
    const navigate = useNavigate();


    const save = async (order:any) => {
        // @ts-ignore
        order['client'] = profile.mail
        setIsSaving(true)
        setError(false)
        const apiName = 'queue';
        const path = '/queue';

        await provider.getAccessToken().then((response: any) => {
            const init = {
                headers: {
                    // @ts-ignore
                    Authorization: response
                },
                queryStringParameters: {
                    'action': 'add_to_saved'
                },
                body: order,
            };

            API
                .post(apiName, path, init)
                .then(() => {
                    setIsSaving(false)
                    // @ts-ignore
                    setError(false)
                    showAlert(dispatch, {
                        variation: "success",
                        message: "Order saved"
                    })
                })
                .catch(error => {
                    setError(true)
                    setIsSaving(false)
                    console.error(error);
                    showAlert(dispatch, {
                        variation: "error",
                        message: "Order could not be saved. Try again or contact the development team."
                    })
                });
        })
    }

    const add = async (order:any) => {
        // @ts-ignore
        order['client'] = profile.mail
        setIsSending(true)
        setError(false)
        const apiName = 'queue';
        const path = '/queue';

        await provider.getAccessToken().then((response: any) => {

            const init = {
                headers: {
                    // @ts-ignore
                    Authorization: response
                },
                body: order,
            };

            API
                .post(apiName, path, init)
                .then(() => {
                    setIsSending(false)
                    // @ts-ignore
                    setError(false)
                    showAlert(dispatch, {
                        variation: "success",
                        message: "Order successfully added to queue"
                    })
                    clearCart(cartDispatch, null, null)
                    navigate('/')
                })
                .catch(error => {
                    setError(true)
                    setIsSending(false)
                    console.error(error);
                    showAlert(dispatch, {
                        variation: "error",
                        message: "Order could not be added to the queue. Try again or contact the development team."
                    })
                });
        })
    }

    return {
        queue, isLoading, setIsLoading, error, setError, isSending, setIsSending, isSaving, setIsSaving, add, save
    }
}

export default useQueue;