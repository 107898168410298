/**
 * OrderPage Overview:
 *
 * The OrderPage serves as the final step for users to review and confirm their data order
 * before placing it. It provides detailed information about the order, including source, references,
 * data specifications, and the total price. Key features include:
 *
 * 1. A comprehensive review of the selected data fields and any restrictions.
 * 2. Dynamic rendering of the 'Total to be provided' based on certain conditions in the field list: FPE or Count.
 * 3. A warnings section where users can see any system-generated alerts about their order.
 * 4. A checkbox to verify and confirm the correctness of the order details.
 * 5. Action buttons to either finalize the order or cancel and return without placing it.
 *
 * Dependencies:
 * This page relies on multiple contexts such as CartStateContext and DatasourceStateContext
 * for accessing order details and the current loading state, respectively. It also uses custom
 * hooks like useQueue to manage the order queue.
 */


import React, {useContext, useState} from "react";
import {CartStateContext} from "../contexts/cart";
import {Placeholder, View, Card, CheckboxField, Divider, Flex, Heading, Text} from "@aws-amplify/ui-react";
import {RuleNotices} from "../components/RuleNotices";
import {Header} from "../components/Header";
import {useNavigate} from "react-router-dom";
import useQueue from "../hooks/useQueue";
import {DatasourceStateContext} from "../contexts/datasource";
import {CancelButton, ShareButton} from "../components/Buttons";
import {Main} from "../components/Main";
import {getDataSourceLabel} from '../helpers/dataSourceLabels';


const OrderPage = () => {
    const navigate = useNavigate();

    // @ts-ignore
    const {details, items, price} = useContext(CartStateContext)
    // @ts-ignore
    const {isLoading} = useContext(DatasourceStateContext)

    // @ts-ignore
    const [enableSubmission, setEnableSubmission] = useState(false)

    const verifyChecked = (event: any) => {
        // @ts-ignore
        setEnableSubmission(event.target.checked)
    }

    const {add} = useQueue()

    const fields = items.filter((item: any) => {
        return !item.allowedValues
    })

    const limits = items.filter((item: any) => {
        return item.allowedValues
    })

    // @ts-ignore
    const placeOrder = () => {
        setEnableSubmission(false)
        add({
            ...details,
            items,
            price
        }).then()
    }

    const cancelOrder = () => {
        navigate('/order');
    }

    if (isLoading) {
        return <Placeholder/>
    }


    // list of field names to trigger "FPE" label
    const FPEFields = ["Subject of study (CAH1)", "Subject of study (HECOS)", "Subject of study (CAH2)", "Subject of study (CAH3)"];
    const showFPE = fields.some((field: any) => FPEFields.includes(field.fieldName));


    return (
        <View>
            <Header/>
            <Main>
                <Card width={"100%"}>
                    <Heading>Order Information</Heading>
                    <p/>
                    <Text>Data source: {getDataSourceLabel(details.datasource)}</Text>
                    <Text>Order reference: {details.orderRef}</Text>
                    <Text>Customer reference: {details.customerRef}</Text>
                    <Text>Item Number: {details.itemNo}</Text>
                    <Text>Customer type: {details.customerType}</Text>
                    <Text>Data use: {details.dataUse}</Text>
                    <Text>Population Coverage Level: {details.populationcoverageLevel}</Text>
                    <Text>Population Coverage Other: {details.populationcoverageOther}</Text>
                    <Text>Sharing raw data (for 3rd party gain): {details.sharingrawData}</Text>

                    <p/>
                    <Divider/>

                    <p/>
                    <Heading>Data Specification</Heading>
                    <p/>

                    <Heading>Data Format and rounding requirements:</Heading>
                    <Text>CSV file format - Unrounded</Text>
                    <Text>Excel pivot table - Unrounded</Text>
                    <p/>

                    <Heading>Dataset:</Heading>
                    <Text>HESA Student Record</Text>
                    {/* <Text>{getDataSourceLabel(details.datasource)}</Text> */}
                    <p/>
                    <Heading>Population restricted to:</Heading>
                    <Text>Academic years: {details.years.join(", ")}</Text>
                    <Text>Onward use category: {details.onwardUseCategory}</Text>
                    {limits.map((field: any) => {
                        return (
                            <Text key={field.id}>{field.tdFieldname}: {field.allowedValues.join(", ")}</Text>
                        )
                    })}


                    <p/>
                    <Heading>Total to be provided:</Heading>
                    {/*<Text>{details.measure}</Text>*/}
                    <Text>{showFPE ? 'FPE' : 'Count'}</Text>



                    <p/>
                    <Heading>Field list:</Heading>
                    {fields.map((field: any) => {
                        return (
                            <Text key={field.id}>{field.tdFieldname}</Text>
                        )
                    })}
                    <p/>

                    <Divider/>

                    <p/>
                    <Heading>Warnings</Heading>
                    <RuleNotices/>
                    <p/>
                    <Divider/>

                    <p/>
                    <Heading>Total price ex VAT</Heading>
                    <Text>£ {price}</Text>
                    <CheckboxField
                        name={"verify"}
                        value={"verify"}
                        label={"I have reviewed the order details, including any warning notices, and have confirmed everything is correct."}
                        defaultChecked={false}
                        onChange={verifyChecked}
                    />
                    <p/>
                    <Flex>
                        <ShareButton action={ ()=>placeOrder() } enabled={!enableSubmission} label={'Place order'}/>
                        <CancelButton action={ ()=>cancelOrder() } label="Close and return without placing order"/>
                    </Flex>
                </Card>
            </Main>
        </View>
    );
}

export default OrderPage