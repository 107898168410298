import React, { useReducer, createContext, useEffect } from "react";
import useMetadata from "../hooks/useMetadata";

const defaultDataSource = "Student full-person equivalent (FPE)"

const initialState = {
    metadata: [],
    datasource: [],
    isLoading: false,
    isLoaded: false,
    selectedDataSource: defaultDataSource
};

// @ts-ignore
export const DatasourceStateContext = createContext();
// @ts-ignore
export const DatasourceDispatchContext = createContext();

// @ts-ignore
const reducer = (state, action) => {
    switch (action.type) {
        case "GET_PRODUCTS_REQUEST":
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case "LOAD_METADATA_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                metadata: action.payload.data
            }
        case "SELECT_DATA_SOURCE":
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                datasource: state.metadata.filter( (datasource:any) =>
                    {
                        return datasource.name === action.payload.datasource
                    }
                )[0]
            }
        case "GET_PRODUCTS_FAILURE":
            return {
                ...state,
                products: null,
                isLoading: false,
                isLoaded: false
            };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

// @ts-ignore
const ProductsProvider = ({children}) => {
    // @ts-ignore
    const { metadata, loaded } = useMetadata()
    const [state, dispatch] = useReducer(reducer, initialState);

    // @ts-ignore
    useEffect(() => {
        if(loaded) {
            getDataSource(dispatch, metadata);
        }
    }, [loaded]);

    return (
        // @ts-ignore
        <DatasourceDispatchContext.Provider value={dispatch}>
            <DatasourceStateContext.Provider value={state}>
                {children}
            </DatasourceStateContext.Provider>
        </DatasourceDispatchContext.Provider>
    );
};

// @ts-ignore
export const getDataSource = (dispatch, metadata) => {

    dispatch({
        type: "GET_PRODUCTS_REQUEST"
    });

    const dataSources = metadata.map(function(obj:any) {
        return obj.datasource;
    });

    dispatch({
        type: "LOAD_METADATA_SUCCESS",
        payload: {
            data: dataSources
        }
    });

    // Default
    dispatch({
        type: "SELECT_DATA_SOURCE",
        payload: {
            datasource: defaultDataSource
        }
    });
};

// @ts-ignore
export const selectDataSource = (dispatch, datasource) => {
    dispatch({
        type: "SELECT_DATA_SOURCE",
        payload: {
            datasource: datasource
        }
    })
}


export default ProductsProvider;