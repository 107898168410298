import {Flex, Text, Icon, Badge} from "@aws-amplify/ui-react";
import {MdReport, MdShoppingBag} from "react-icons/md";
import Tooltip from "rc-tooltip"
import React, {useContext} from "react";
import {CartStateContext} from "../contexts/cart";

export const ItemBadge = (props:any) => {
    return (
    <Tooltip placement={"bottom"} overlay={props.tooltip}>
        <Badge size="small" variation={props.variation}>{props.label}</Badge>
    </Tooltip>
    )
}


export const Indicator = (icon: any, value: number, tooltip: string) => {
    return (
        <Tooltip placement={"bottom"} overlay={tooltip}>
        <Flex
            direction={"column"}
            alignItems={"center"}
            gap={"normal"}
        >
                <Icon as={icon} ariaLabel={tooltip}/>
            <Text
                variation={"secondary"}
                fontSize={"0.6rem"}
            >{value}</Text>
        </Flex>
        </Tooltip>
    )
}

export const OrderItemsTotalIndicator = () => {
    // @ts-ignore
    const { items } = useContext(CartStateContext);
    let total = 0
    if (items && items.length > 0) {
        // @ts-ignore
        total = items.map(item => !item.allowedValues + 0 || 0).reduce((prev:any, next:any) => prev + next);
    }
    return Indicator(MdShoppingBag, total, "Total number of fields is " + total)

}

export const SensitivityIndicator = () => {
    // @ts-ignore
    const { items } = useContext(CartStateContext);
    let total = 0
    if (items && items.length > 0) {
        // @ts-ignore
        total = items.map(item => item.sensitivity || 0).reduce((prev:any, next:any) => prev + next);
    }
    return Indicator(MdReport, total, "Total sensitivity score of selected items is " + total)
}

export default Indicator