import React, { useContext } from "react";
import { DatasourceStateContext } from "../contexts/datasource";
import { Placeholder } from "@aws-amplify/ui-react";
import { CommonStateContext } from "../contexts/common";
import useQueue from "../hooks/useQueue";
import { CartStateContext } from "../contexts/cart";
import {SaveButton} from "./Buttons";

export const SaveOrderButton = () => {
    // @ts-ignore
    const {details, items, price} = useContext(CartStateContext)
    // @ts-ignore
    const { messages } = useContext(CommonStateContext)
    // @ts-ignore
    const {isLoaded} = useContext(DatasourceStateContext);
    // @ts-ignore
    const {save, isSaving} = useQueue()

    const saveOrder = () => {
        // @ts-ignore
        save({
            ...details,
            items,
            price
        }).then()
    }


    if (isLoaded) {
        // Disable submit if any ERROR notices
        let enabled = messages.filter((notice: any) => notice.severity === 'Failure').length > 0
        return (
            <SaveButton enabled={enabled} loading={isSaving} action={() => saveOrder()} label={"Save order"}/>
        )
    } else {
        return (
            <Placeholder/>
        )
    }
}