import { Alert, View } from '@aws-amplify/ui-react';
import { CommonDispatchContext, CommonStateContext, removeAlert } from "../contexts/common";
import { useContext } from "react";

export const Alerts = () => {

    // @ts-ignore
    const { alert } = useContext(CommonStateContext)
    const dispatch = useContext(CommonDispatchContext)

    const dismissAlert = () => {
        removeAlert(dispatch)
    }

    return (
        <View>
            {alert &&
            <Alert variation={alert.variation} isDismissible={true} onDismiss={() => {
                dismissAlert()
            }}>{alert.message}</Alert>
            }
        </View>
    )
}