import {useState} from "react";
import {API} from "aws-amplify";
import {useProfile} from "./useProfile";
import {Providers} from "@microsoft/mgt-element";

/**
 * Hook for calling the rules and pricing API
 */
function useRules() {
    const [profile] = useProfile()
    const provider = Providers.globalProvider;
    const [notices, setNotices] = useState([])
    const [price, setPrice] = useState(0.0)
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const processRules = async (order: any) => {

        if (!order.details['datasource']) {
            return false
        }
        // @ts-ignore
        order['client'] = profile.mail
        setError(false);
        setLoaded(false);
        const apiName = 'queue';
        const path = '/queue';

        const payload = {
            ...order['details'],
            'items': order.items
        }

        await provider.getAccessToken().then((response: any) => {

            const init = {
                headers: {
                    // @ts-ignore
                    Authorization: response
                },
                queryStringParameters: {
                    'action': 'check_rules_and_pricing'
                },
                body: payload
            };

            API
                .post(apiName, path, init)
                .then(response => {

                    // @ts-ignore
                    setNotices(response['notices'])

                    //Round the price to the nearest £1 and set it
                    const roundedPrice = Math.round(response['price']);
                    setPrice(roundedPrice);
                    //setPrice(response['price']);


                    setError(false)
                    setLoaded(true)
                })
                .catch(error => {
                    setError(true)
                    console.error(error);
                });
        })
    }

    return {
        notices, price, loaded, error, processRules
    }
}

export default useRules;