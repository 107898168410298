import { CommonDispatchContext, setSearchKeyword } from "../contexts/common";
import React, { useContext } from "react";
import { SearchField } from "@aws-amplify/ui-react";

export const Search = () => {
    const commonDispatch = useContext(CommonDispatchContext);

    const handleSearchInput = (event:any) => {
        return setSearchKeyword(commonDispatch, event);
    };

    const clearSearch = () => {
        return setSearchKeyword(commonDispatch, "")
    }

    return (
        <>
            <SearchField id="search" label="search" size={"small"} onSubmit={handleSearchInput} onClear={clearSearch}/>
        </>
    );
}