import {useState} from "react";
import {API} from "aws-amplify";
import {useProfile} from "./useProfile";
import {Providers} from "@microsoft/mgt-element";

/**
 * Hook for retrieving storage objects
 */
function useS3() {
    const [profile] = useProfile()
    const provider = Providers.globalProvider;
    const [isLoading, setIsLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)
    const [orders, setOrders] = useState(false)

    const loadPreviousOrders = async (all = false, type = 'orderHistory') => {
        setError(false)
        setIsLoading(true)
        const apiName = 'queue';
        const path = '/queue';

        await provider.getAccessToken().then((response: any) => {
                let user = ''
                if (!all) {
                    user = profile.mail
                }
                let action = 'get_orders'
                if (type === 'savedOrders') {
                    action = 'get_saved_orders'
                }

                const init = {
                    headers: {
                        //@ts-ignore
                        Authorization: response
                    },
                    queryStringParameters: {
                        // @ts-ignore
                        'user': user,
                        'action': action
                    }
                };

                API
                    .get(apiName, path, init)
                    .then(response => {
                        // @ts-ignore
                        setError(false)
                        setIsLoading(false)
                        setLoaded(true)
                        setOrders(response)
                    })
                    .catch(error => {
                        setIsLoading(false)
                        setError(true)
                        console.error(error);
                    });
            }
        )
    }
    return {
        orders, isLoading, setIsLoading, loaded, error, setError, loadPreviousOrders
    }
}

export default useS3;