import {useEffect, useState} from "react";
import {Providers} from "@microsoft/mgt-element";
import {useIsSignedIn} from "./useIsSignedIn";
// @ts-ignore
import JWTDecode from 'jwt-decode';

/**
 * Profile data model
 */
class Profile {
    mail:string = ''
}
const initialState = new Profile()

/**
 * Hook for obtaining user profile information. This just takes
 * minimal information from the email access token as we don't
 * really need anything else.
 */
export function useProfile() {
    const [profile, setProfile] = useState(initialState);
    const [isSignedIn] = useIsSignedIn()

    const getUserInfo = async () => {
        await Providers.globalProvider.getAccessToken({scopes: ['email']})
            .then(async (token) => {
                // @ts-ignore
                setProfile({mail: JWTDecode(token)['upn']})
            })

    }

    useEffect( () => {
        if (isSignedIn) {
            getUserInfo()
        }
    }, [isSignedIn])


    return [profile];
}