import React, { useReducer, createContext } from "react";

const initialState = {
    isSearchBarOpen: false,
    compactView: false,
    searchKeyword: "",
    alert: null,
    messages:[]
};

// @ts-ignore
export const CommonStateContext = createContext();
// @ts-ignore
export const CommonDispatchContext = createContext();

const reducer = (state:any, action:any) => {
    switch (action.type) {
        case "TOGGLE_SEARCH_BAR":
            return {
                ...state,
                isSearchBarOpen: !state.isSearchBarOpen
            };
        case "SET_SEARCH_KEYWORD":
            return {
                ...state,
                searchKeyword: action.payload.searchKeyword
            };
        case "TOGGLE_COMPACT_VIEW":
            return {
                ...state,
                compactView: !state.compactView
            };
        case "SHOW_ALERT":
            return {
                ...state,
                alert: action.payload.alert
            }
        case "REMOVE_ALERT":
            return {
                ...state,
                alert: null
            }
        case "SET_MESSAGES":
            return {
                ...state,
                messages: action.payload.messages
            }
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

// @ts-ignore
const CommonProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        // @ts-ignore
        <CommonDispatchContext.Provider value={dispatch}>
            <CommonStateContext.Provider value={state}>
                {children}
            </CommonStateContext.Provider>
        </CommonDispatchContext.Provider>
    );
};

export const toggleSearchBar = (dispatch:any) => {
    return dispatch({
        type: "TOGGLE_SEARCH_BAR"
    });
};

export const toggleCompactView = (dispatch:any) => {
    return dispatch({
        type: "TOGGLE_COMPACT_VIEW"
    });
};


export const setSearchKeyword = (dispatch:any, searchKeyword:any) => {
    return dispatch({
        type: "SET_SEARCH_KEYWORD",
        payload: {
            searchKeyword: searchKeyword
        }
    });
};

export const showAlert = (dispatch: any, alert: any) => {
    return dispatch({
            type: "SHOW_ALERT",
            payload: {
                alert: alert
            }
        })
}

export const removeAlert = (dispatch: any) => {
    return dispatch({
        type: "REMOVE_ALERT",
        payload: {
        }
    })
}

export const setMessages = (dispatch: any, messages: any) => {
    return dispatch({
        type: "SET_MESSAGES",
        payload: {
            messages: messages
        }
    })
}

export default CommonProvider;