import {Button, Flex, Text} from "@aws-amplify/ui-react";
import {MdEdit, MdSave, MdFileCopy, MdAddChart, MdNavigateNext, MdCancel, MdAddBox} from "react-icons/md";
import React from "react";


export const EditButton = (props:any) => {
    return (
        <Button variation={'primary'} onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"1.2em"} color={"white"}><MdEdit/> </Text>
                <Text fontSize={"1em"} color={"white"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}

export const CopyButton = (props:any) => {
    return (
        <Button onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"1.2em"}><MdFileCopy/> </Text>
                <Text fontSize={"1em"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}

export const CancelButton = (props:any) => {
    return (
        <Button onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"1.2em"}><MdCancel/> </Text>
                <Text fontSize={"1em"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}


export const SaveButton = (props:any) => {
    return (
        <Button isDisabled={props.enabled} variation={'primary'} onClick={props.action} size={"small"}
        isLoading={props.loading} loadingText={'Saving...'}>
            <Flex>
                <Text fontSize={"1.2em"} color={"white"}><MdSave/> </Text>
                <Text fontSize={"1em"} color={"white"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}

export const NewButton = (props:any) => {
    return (
        <Button isDisabled={props.enabled} variation={'primary'} onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"1.2em"} color={"white"}><MdAddChart/> </Text>
                <Text fontSize={"1em"} color={"white"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}

export const ShareButton = (props:any) => {
    return (
        <Button isDisabled={props.enabled} variation={'primary'} onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"1.2em"} color={"white"}><MdNavigateNext/> </Text>
                <Text fontSize={"1em"} color={"white"}> {props.label}</Text>
            </Flex>
        </Button>
    )
}

export const AddButton = (props:any) => {
    return (
        <Button
            isDisabled={props.enabled}
            variation ={'primary'}
            onClick={props.action}
            size={"small"}
            gap="0.1rem"
            style={{
                whiteSpace: 'nowrap',
                backgroundColor: props.enabled ? 'lightgrey' : undefined
            }}
        >
            <MdAddBox />
            <Text fontSize={"0.8em"} color={"white"}> {props.label}</Text>
        </Button>
    )
}

export const AddButtonSmall = (props:any) => {
    return (
        <Button
            isDisabled={props.enabled}
            variation={'primary'}
            onClick={props.action}
            size={"small"}
            gap="0.1rem"
            style={{ whiteSpace: 'nowrap' }}
        >
            <MdAddBox />
            <Text fontSize={"0.8em"} color={"white"}>{props.label || 'add field to order'}</Text>
        </Button>
    )
}


export const RemoveButtonSmall = (props:any) => {
    return (
        <Button isDisabled={props.enabled} variation={'link'} onClick={props.action} size={"small"}>
            <Flex>
                <Text fontSize={"0.8em"}>{props.label || 'remove'}</Text>
            </Flex>
        </Button>
    )
}