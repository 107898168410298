import {Flex, Text, useTheme} from "@aws-amplify/ui-react";
import {MdWarning} from "react-icons/md";
import {MdInfo} from "react-icons/md";
import React from "react";

// @ts-ignore
export const Notice = ({level, children}) => {
    const { tokens } = useTheme();

    if (level === "info"){
        return (
            <Flex direction={"row"} alignItems={"center"}>
                <Text fontSize={"2em"}
                    // @ts-ignore
                      color={tokens.colors.background[level].value}><MdInfo/></Text>
                <Text fontSize={"0.8em"}>
                    {children}
                </Text>
            </Flex>
        );
    }
    else
    {
        return (
            <Flex direction={"row"} alignItems={"center"}>
                <Text fontSize={"2em"}
                    // @ts-ignore
                      color={tokens.colors.background[level].value}><MdWarning/></Text>
                <Text fontSize={"0.8em"}>
                    {children}
                </Text>
            </Flex>
        );
    }

}
