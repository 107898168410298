import {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {useProfile} from "./useProfile";
import {Providers} from "@microsoft/mgt-element";
import {useIsSignedIn} from "./useIsSignedIn";

/**
 * Metadata API Hook.
 * Calls AWS Lambda via API gateway using access token
 */
function useMetadata() {
    const [profile]  = useProfile()
    const [isSignedIn] = useIsSignedIn()
    const provider = Providers.globalProvider;
    const [metadata, setMetadata] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const loadMetadata = async () => {

        setError(false);
        setLoaded(false);
        const apiName = 'queue';
        const path = '/queue';

        await provider.getAccessToken().then((response: any) => {
                const init = {
                    headers: {
                        // @ts-ignore
                        Authorization: response
                    },
                    queryStringParameters: {
                        // @ts-ignore
                        'user': profile.mail,
                        'action': 'get_metadata'
                    }
                };

                API
                    .get(apiName, path, init)
                    .then(response => {
                        // @ts-ignore
                        setMetadata(response)
                        setError(false)
                        setLoaded(true)
                    })
                    .catch(error => {
                        setError(true)
                        console.error(error);
                    });
            }
        )}

    useEffect( () => {
        if (isSignedIn && ! loaded) {
            loadMetadata()
        }
    }, [isSignedIn])

    return {
        metadata, loaded, error, loadMetadata
    }
}

export default useMetadata;