import React, { useContext } from "react";
import { CartStateContext, CartDispatchContext, removeFromCart } from "../contexts/cart";
import { CommonStateContext } from "../contexts/common";
import { Collection, View, useTheme } from '@aws-amplify/ui-react';
import {GenericFieldCard, CompactGenericFieldCard} from "./FieldCard";
import {RemoveButtonSmall} from "./Buttons";

export const Order = () => {
    const { tokens } = useTheme();

    // @ts-ignore
    const { items } = useContext(CartStateContext);

    const fields = items.filter( (item:any) => {
        return !item.allowedValues
    })
    const constraints = items.filter( (item:any) => {
        return item.allowedValues
    })

    return (
            <View
                backgroundColor={ tokens.colors.neutral["20"] }
                padding={ tokens.space.medium }
            >
                <FieldCollection fields={constraints}/>
                <p/>
                <FieldCollection fields={fields}/>

            </View>
        );
}

// @ts-ignore
const FieldCollection = ({fields}) => {
    const { tokens } = useTheme();
    // @ts-ignore
    const { compactView } = useContext(CommonStateContext);
    let FieldCard = GenericFieldCard
    let padding:any = null;
    if (compactView){
        FieldCard = CompactGenericFieldCard
        padding = "0px"
    } else {
        padding = tokens.space.medium
    }

    const dispatch = useContext(CartDispatchContext)
    const handleRemove = (productId:any) => {
        removeFromCart(dispatch, productId);
    };

    return (
        <Collection type="list" items={fields} gap={padding}>
            {(item) => (
                <FieldCard
                    field={item}
                    // @ts-ignore
                    key={item.id}
                >
                    <RemoveButtonSmall
                        // @ts-ignore
                        action={() => handleRemove(item.id)}
                        label={'remove'}
                    />
                </FieldCard>
            )
            }
        </Collection>
    )
}