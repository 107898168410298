export const filteredFieldList = (datasource:any, items:any, searchKeyword:any) => {
    const filteredFields = datasource.fields && datasource.fields.filter((field: any) => {
        return (
            // If the field has an inactive status e.g. bug or discrepency then it won't be shown for users to add
            field.status === "active"
            // If there is a search in place, filter by search
            && (
                field.searchName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                !searchKeyword
            )
            // Exclude fields already in order (but not constraints)
            && (
                items.filter((item: any) => {
                    return (field.id === item.id && !item.allowedValues)
                }).length === 0
            )
            && (
                // @ts-ignore
                !items.some(({id}) => id === field.id)
            )
        )
    });

    // Sort items based on their fieldName property
    filteredFields.sort((a: any, b: any) => {
        if (a.fieldName < b.fieldName) {
            return -1;
        }
        if (a.fieldName > b.fieldName) {
            return 1;
        }
        return 0;
    });

    return filteredFields;
};
