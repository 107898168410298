import {Divider, Heading, Text} from "@aws-amplify/ui-react";
import React from "react";

export const OrderItem = (props: any) => {

    const order = props['order']


    const niceDate = (value:string) => {
        let date = new Date(value)
        return date.toLocaleString()
    }

    const fields = (items: any) =>
        items.filter((item: any) => {
            return !item.allowedValues
        })

    const limits = (items: any) =>
        items.filter((item: any) => {
            return item.allowedValues
        })

    return (
        <>
            <Heading>Order details</Heading>
            <Text>Data source: {
                // @ts-ignore
                order['datasource']}</Text>
            <Text>Measure: {
                // @ts-ignore
                order['measure']}</Text>
            <Text>Academic years: {
                // @ts-ignore
                order['years'].join(", ")
            }</Text>
            <Text>Order reference: {
                // @ts-ignore
                order['orderRef']}</Text>
            <Text>Customer reference: {
                // @ts-ignore
                order['customerRef']}</Text>
            <Text>Onward use category: {
                // @ts-ignore
                order['onwardUseCategory']}</Text>
            <Divider/>
            <p/>
            <Heading>Fields</Heading>
            {
                // @ts-ignore
                fields(order['items']).map((field: any) => {
                    return (
                        <Text key={field.id}>{field.fieldName}</Text>
                    )
                })}
            <Divider/>
            <p/>
            <Heading>Filters</Heading>
            {
                // @ts-ignore
                limits(order['items']).map((field: any) => {
                    return (
                        <Text key={field.id}>{field.fieldName}</Text>
                    )
                })}
            <Divider/>
            <p/>
            <Heading>Total price ex VAT</Heading>
            <Text>£ {
                // @ts-ignore
                order['price']}</Text>
            <p/>

            {
                'jobSubmitted' in order &&
                <>
                    <Divider/>
                    <Text fontSize={"small"}>Order placed {
                        // @ts-ignore
                        niceDate(order['jobSubmitted'])}</Text>
                    <Text fontSize={"small"}>Order completed {
                        // @ts-ignore
                        niceDate(order['jobCompleted'])}</Text>
                    <p/>
                </>
            }

        </>
    )

}