import {View, Flex, useTheme, Text, Image} from '@aws-amplify/ui-react';
import { Link } from "react-router-dom";
import { Alerts } from "./Alerts";
import React from "react";
import {AccountMenu} from "./AccountMenu";

export const Header = () => {
    const { tokens } = useTheme();

    return (
            <View>
                <Flex direction={"row"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      backgroundColor={tokens.colors.white}
                      alignItems={"center"}
                      minHeight={"80px"}
                      maxHeight={"80px"}
                >
                    <Flex padding={tokens.space.medium} alignItems={"center"}>
                        <Link to={'/'}><Image alt={"Jisc logo: click to go to home"} src={"./jisc_logo.png"} height={"80px"} width={"80px"}/></Link>
                        <Link to={'/'} style={{ textDecoration: 'none' }}><Text fontSize="2em">Tailored datasets</Text>
                        </Link>
                    </Flex>
                    <Flex alignItems={"center"}>
                        <AccountMenu/>
                    </Flex>

                </Flex>
                <Alerts/>
            </View>
    )
}