import React, { useReducer, createContext, useEffect, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useRules from "../hooks/useRules";
import {CommonDispatchContext, setMessages} from "./common";

const initialState = {
    items: [],
    constraints: [],
    orderStarted: false,
    price: 0.0,
    details: {
        client: "demo",
        datasource: "",
        orderRef: "",
        customerRef: "",
        itemNo: "",
        measure: "",
        years:[],
        onwardUseCategory: "1",
        dataUse:"Internal",
        customerType:"Not for profit",
        populationcoverageLevel: "Complete",
        populationcoverageOther: "Complete",
        sharingrawData: "No"
    },
};


// @ts-ignore
export const CartStateContext = createContext();
// @ts-ignore
export const CartDispatchContext = createContext();

// @ts-ignore
const reducer = (state, action) => {
    let cartItems = null;
    switch (action.type) {
        case "ADD_TO_CART":
            const id = action.payload.cartItem.id;
            const isOld = state.items.map((item:any) => item.id).includes(id);
            if (isOld) {
                //
                // Replace the existing item with the updated item
                //
                const items = state.items.map((item:any) => {
                    if (item.id === id) {
                        return action.payload.cartItem;
                    } else {
                        return item;
                    }
                });
                cartItems = [...items];
            } else {
                cartItems = [...state.items, action.payload.cartItem];
            }
            return {
                ...state,
                orderStarted: true,
                items: cartItems
            };
        case "REMOVE_FROM_CART":
            cartItems = state.items.filter(
                (item:any) => item.id !== action.payload.cartItemId
            )
            return {
                ...state,
                orderStarted: true,
                items: cartItems
            };
        case "CLEAR_CART":
            return {
                ...initialState,
                details: action.payload.details,
                price: 0.0
            };
        case "REPLACE_CART":
            return {
                ...initialState,
                details: action.payload.details,
                price: 0.0,
                items: action.payload.items,
                orderStarted: true
            }
        case "UPDATE_ORDER_DETAILS":
            return {
                ...state,
                orderStarted: true,
                details: action.payload.details
            };
        case "UPDATE_PRICE":
            return {
                ...state,
                price: action.payload.price
            }
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

export const addToCart = (dispatch:any, cartItem:any) => {
    return dispatch({
        type: "ADD_TO_CART",
        payload: {
            cartItem: cartItem
        }
    });
};

export const removeFromCart = (dispatch:any, cartItemId:any) => {
    return dispatch({
        type: "REMOVE_FROM_CART",
        payload: {
            cartItemId: cartItemId
        }
    });
};

export const replaceCart = (dispatch:any, order:any) => {
    return dispatch({
        type: "REPLACE_CART",
        payload: {
            details: {
                ...initialState.details,
                ...order.details
            },
            items: order.items
        }
    })
}

export const clearCart = (dispatch:any, datasource: any, measure:any) => {
    return dispatch({
        type: "CLEAR_CART",
        payload: {
            details: {
                ...initialState.details,
                datasource: datasource,
                measure: measure
            }
        }
    });
};

export const updateOrderDetails = (dispatch:any, details:any) => {
    return dispatch({
        type: "UPDATE_ORDER_DETAILS",
        payload: {
            details: details
        }
    })
}


export const updatePrice = (dispatch:any, price:any) => {
    return dispatch({
        type: "UPDATE_PRICE",
        payload: {
            price: price
        }
    })
}

// @ts-ignore
const CartProvider = ({ children }) => {

    // @ts-ignore
    const commonDispatch = useContext(CommonDispatchContext)

    const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
        "cartItems",
        []
    );

    const [persistedDetails, setPersistedDetails] = useLocalStorage(
        "details",
        []
    );

    const [persistedOrderStarted, setPersistedOrderStarted] = useLocalStorage(
        "orderStarted",
        false
    );

    const persistedCartState = {
        orderStarted: persistedOrderStarted || false,
        details: persistedDetails || [],
        items: persistedCartItems || []
    };

    const [state, dispatch] = useReducer(reducer, persistedCartState);

    useEffect(() => {
        setPersistedCartItems(state.items);
    }, [JSON.stringify(state.items)]);

    useEffect(() => {
        setPersistedDetails(state.details)
    }, [JSON.stringify(state.details)]);

    useEffect(() => {
        setPersistedOrderStarted(state.orderStarted)
    }, [JSON.stringify(state.orderStarted)]);

    //
    // Run after update
    //
    const {price, notices, loaded, processRules} = useRules()
    const orderRefChangeState = state.details.orderRef && state.details.orderRef.length > 0
    useEffect( () => {
            processRules(state)
        },[state.items, state.details.years, state.details.customerType, state.details.dataUse, state.details.populationcoverageLevel, state.details.populationcoverageOther, state.details.sharingrawData, orderRefChangeState]
    )
    //
    // After rules processing completes
    //
    useEffect(() => {
        setMessages(commonDispatch, notices)
        updatePrice(dispatch, price)
    }, [loaded]
)

    return (
        <CartDispatchContext.Provider value={dispatch}>
            <CartStateContext.Provider value={state}>
                {children}
            </CartStateContext.Provider>
        </CartDispatchContext.Provider>
    );
};

export default CartProvider;