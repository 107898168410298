import React, {useContext} from "react";
import { Card, Radio, RadioGroupField } from "@aws-amplify/ui-react";
import {CartDispatchContext, CartStateContext, updateOrderDetails} from "../contexts/cart";

export const OnwardUseControl = () => {

    // @ts-ignore
    const { details } = useContext(CartStateContext)
    const dispatch = useContext(CartDispatchContext)

    const options = [
        {key:"1", label: "Category 1"},
        {key:"2", label: "Category 2"},
        {key:"3", label: "Category 3"}
    ]

    const updateDetails = (orderDetails:any) => {
        updateOrderDetails(dispatch, orderDetails)
    }

    const setValue = (event:any) => {
        updateDetails({
            ...details,
            onwardUseCategory: event
        })
    }

    return (
        <Card>
            <RadioGroupField
                label="Onward use category"
                name="onward use category"
                size="small"
                value={ details.onwardUseCategory || "1" }
                onChange={(e) => setValue(e.target.value)}
            >
                {options.map((option) => (
                    <Radio key={option.key} value={option.key}>
                        {option.label}
                    </Radio>
                ))}
            </RadioGroupField>
        </Card>
    )
}